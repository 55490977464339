import {
    Crop,
    PackingHouse,
    Record,
    RecordFilter,
    RecordFilterOptions,
} from '@citrus-tracker/types';
import React, { Component, ReactElement, RefObject } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Select,
    DropdownMenu,
    DynamicIcon,
    DynamicOutlineIcon,
} from '@citrus-tracker/ui';

import './filter.scss';

/* eslint-disable-next-line */
export interface FilterProps {
    activeRecord?: Record;
    filter: RecordFilter;
    filterOptions: RecordFilterOptions;
    nextPage?(): void;
    openBulkEdit?(): void;
    openImport?(): void;
    prevPage?(): void;
    updateFilter(newOptions: RecordFilter): void;
    columns?: {
        property: keyof Record;
        title: string;
    }[];
    toggleColumnVisibility?(property: keyof Record): unknown;
    isAdmin?: boolean;
    visibleColumns?: {
        [key: string]: boolean;
    };
    Api?: any;
}

export class Filter extends Component<FilterProps> {
    columnDropdownRef: RefObject<HTMLDivElement>;
    columnDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<FilterProps>;

    constructor(props: FilterProps) {
        super(props);

        this.columnDropdownRef = React.createRef();
        this.columnDropdownTriggerRef = React.createRef();
    }

    clearFilters = (): void => {
        const { updateFilter } = this.props;
        updateFilter({});
        
        localStorage.setItem('recordPage', JSON.stringify(1));
    };

    setFilterOption = (
        property: string,
        value: string | number | Crop | PackingHouse | object | undefined,
    ): void => {
        const { filter, updateFilter } = this.props;

        updateFilter({
            ...filter,
            [property]: value,
        });
    };

    render(): ReactElement {
        const {
            activeRecord,
            Api,
            filter,
            filterOptions,
            nextPage,
            openBulkEdit,
            openImport,
            prevPage,
            columns,
            toggleColumnVisibility,
            isAdmin,
            visibleColumns,
        } = this.props;

        var missingData = {};
        if (Number.isInteger(filter.missingData)) {
            filterOptions?.missingData?.map(function (option) {
                if (option.value == filter.missingData) {
                    missingData = option;
                }
            });
        }
        return (
            <div className="grid grid-cols-3 gap-4 mb-4 items-end">
                <div className="col-span-2">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-1">
                            <div>
                                <Select
                                    label="Missing Data Filter:"
                                    id="missingDatafilter"
                                    onChange={(e): void =>
                                        this.setFilterOption(
                                            'missingData',
                                            e.value,
                                        )
                                    }
                                    name="missingDataFilter"
                                    value={missingData}
                                    options={filterOptions?.missingData}
                                    showAll={true}
                                />
                            </div>
                        </div>

                        {activeRecord && (
                            <div className="col-span-1">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="districtFilter"
                                >
                                    District Filter:
                                </label>{' '}
                                <select
                                    className="mt-1 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="districtFilter"
                                    name="districtFilter"
                                    onChange={(e): void =>
                                        this.setFilterOption(
                                            'district',
                                            e.target.value,
                                        )
                                    }
                                    value={filter.district}
                                >
                                    <option value="">All</option>
                                    {filterOptions?.district?.map(function (
                                        missingData,
                                        index,
                                    ) {
                                        return (
                                            <option
                                                key={index}
                                                value={missingData.value}
                                            >
                                                {missingData.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}

                        {activeRecord && (
                            <div className="col-span-1">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="cropFilter"
                                >
                                    Crop Filter:
                                </label>{' '}
                                <select
                                    className="mt-1 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    id="cropFilter"
                                    name="cropFilter"
                                    onChange={(e): void =>
                                        this.setFilterOption(
                                            'crop',
                                            e.target.value
                                                ? {
                                                      id: parseInt(
                                                          e.target.value,
                                                      ),
                                                  }
                                                : '',
                                        )
                                    }
                                    value={filter.crop?.id}
                                >
                                    <option value="">All</option>
                                    {filterOptions?.crop?.map(function (
                                        crop,
                                        index,
                                    ) {
                                        return (
                                            <option
                                                key={index}
                                                value={crop.value.id}
                                            >
                                                {crop.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}

                        {activeRecord && (
                            <div className="col-span-1">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="name"
                                >
                                    Grower Provided:
                                </label>
                                <div className="relative w-full bg-white py-2 text-left cursor-default sm:text-sm">
                                    {activeRecord.grower_provided}
                                </div>
                            </div>
                        )}

                        {activeRecord && (
                            <div className="col-span-1">
                                <label
                                    className="block text-sm font-medium text-gray-700"
                                    htmlFor="name"
                                >
                                    Block Description Provided:
                                </label>
                                <div className="relative w-full bg-white py-2 text-left cursor-default sm:text-sm">
                                    {activeRecord.block_description_provided}
                                </div>
                            </div>
                        )}

                        {activeRecord && isAdmin && (
                            <div className="col-span-1">
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700"
                                        htmlFor="filter"
                                    >
                                        Packing House Filter:
                                    </label>{' '}
                                    <select
                                        className="mt-1 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        id="packingHouseIdFilter"
                                        name="packingHouseIdFilter"
                                        onChange={(e): void =>
                                            this.setFilterOption(
                                                'packingHouse',
                                                e.target.value
                                                    ? {
                                                          id: parseInt(
                                                              e.target.value,
                                                          ),
                                                      }
                                                    : '',
                                            )
                                        }
                                        value={filter.packingHouse?.id}
                                    >
                                        <option value="">All</option>
                                        {filterOptions?.packingHouse?.map(
                                            function (packingHouse, index) {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={
                                                            packingHouse.value
                                                                .id
                                                        }
                                                    >
                                                        {packingHouse.label}
                                                    </option>
                                                );
                                            },
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-span-1 text-right">
                    {toggleColumnVisibility && (
                        <div className="relative float-right">
                            <DropdownMenu
                                icon="CogIcon"
                                items={columns?.map((column) => (
                                    <label
                                        key={column.property}
                                        htmlFor={`${column.property}Visible`}
                                        className="pr-4 block whitespace-nowrap"
                                    >
                                        <input
                                            type="checkbox"
                                            id={`${column.property}Visible`}
                                            checked={
                                                visibleColumns &&
                                                visibleColumns[column.property]
                                            }
                                            onChange={(): unknown =>
                                                toggleColumnVisibility(
                                                    column.property,
                                                )
                                            }
                                        />{' '}
                                        {column.title}
                                    </label>
                                ))}
                                showArrow={false}
                            />
                        </div>
                    )}

                    {isAdmin && (
                        <div className="relative float-right ml-4">
                            <DropdownMenu
                                label="Options"
                                items={Array(
                                    <a
                                        onClick={openImport}
                                        className="cursor-pointer"
                                    >
                                        Import CSV
                                    </a>,
                                    <a
                                        onClick={(): void => {
                                            Api.records.export();
                                            this.setState({
                                                downloadDropdown: false,
                                            });
                                        }}
                                        className="cursor-pointer"
                                    >
                                        {' '}
                                        Download
                                    </a>,
                                    <a
                                        onClick={(): void => {
                                            Api.records.export(true);
                                            this.setState({
                                                downloadDropdown: false,
                                            });
                                        }}
                                        className="cursor-pointer"
                                    >
                                        {' '}
                                        Download with Deleted
                                    </a>,
                                )}
                                showArrow={true}
                            />
                        </div>
                    )}

                    {activeRecord && (
                        <div className="float-right form-group mr-4">
                            <Link
                                to={`/records`}
                                className="btn btn-alt-primary btn-block"
                            >
                                Table View
                            </Link>
                        </div>
                    )}

                    {openBulkEdit && (
                        <Button
                            className="float-right ml-4"
                            onClick={openBulkEdit}
                        >
                            Bulk Edit
                        </Button>
                    )}
                    {(Object.keys(filter).length > 0 || ( localStorage.getItem('recordPage') !== undefined && parseInt(localStorage.getItem('recordPage')?.toString() ?? '0') > 1 )) && (
                        <Button
                            className="float-right ml-4"
                            type="submit"
                            onClick={this.clearFilters}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

/* eslint-disable-next-line */
export interface FilterColumnProps {
    column: { property: string };
    filter: RecordFilter;
    filterOptions: RecordFilterOptions;
    updateFilter(newOptions: RecordFilter): void;
}

export class FilterColumn extends Component<FilterColumnProps> {
    columnDropdownRef: RefObject<HTMLDivElement>;
    columnDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<FilterColumnProps>;

    state: {
        filterOpen: boolean;
    } = {
        filterOpen: false,
    };

    constructor(props: FilterColumnProps) {
        super(props);

        this.columnDropdownRef = React.createRef();
        this.columnDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    openFilter = () => {
        this.setState({ filterOpen: true });
    };
    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.columnDropdownRef &&
            !this.columnDropdownRef.current?.contains(e.target as Node) &&
            this.columnDropdownTriggerRef &&
            !this.columnDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ filterOpen: false });
        }
    };

    setFilterOption = (
        property: string,
        value: string | number | Crop | PackingHouse | object | undefined,
    ): void => {
        const { filter, updateFilter } = this.props;

        this.setState({ filterOpen: false });

        updateFilter({
            ...filter,
            [property]: value,
        });
    };

    render(): ReactElement {
        const { column, filter, filterOptions } = this.props;
        const { filterOpen } = this.state;

        return (
            <div
                className={
                    column.property === 'district'
                        ? filter[column.property]
                            ? 'filtered inline-block w-5 h-5 align-middle'
                            : 'inline-block w-5 h-5 align-middle'
                        : // @ts-ignore
                        filter[column.property]?.id
                        ? 'filtered inline-block w-5 h-5 align-middle'
                        : 'inline-block w-5 h-5 align-middle'
                }
            >
                <label
                    htmlFor={column.property + 'Filter'}
                    onClick={this.openFilter}
                    className="w-5 h-5"
                >
                    {(column.property === 'district' &&
                        filter[column.property]) ||
                    // @ts-ignore
                    filter[column.property]?.id ? (
                        <DynamicIcon icon="FilterIcon" />
                    ) : (
                        <DynamicOutlineIcon icon="FilterIcon" />
                    )}
                </label>
                {filterOpen && (
                    <div className="filterWrapper relative">
                        <select
                            // @ts-ignore
                            size={filterOptions[column.property]?.length + 1}
                            // @ts-ignore
                            ref={this.columnDropdownRef}
                            className="form-control"
                            style={{ maxHeight: 600 }}
                            id={column.property + 'Filter'}
                            name={column.property + 'Filter'}
                            onChange={(e): void => {
                                if (column.property === 'district') {
                                    this.setFilterOption(
                                        column.property,
                                        e.target.value,
                                    );
                                } else {
                                    this.setFilterOption(
                                        column.property,
                                        e.target.value
                                            ? {
                                                  id: parseInt(e.target.value),
                                              }
                                            : '',
                                    );
                                }
                            }}
                            value={
                                column.property === 'district'
                                    ? filter[column.property]
                                    : // @ts-ignore
                                      filter[column.property]?.id
                            }
                        >
                            <option value="">All</option>
                            {
                                // @ts-ignore
                                filterOptions[column.property]?.map(function (
                                    // @ts-ignore
                                    key,
                                    // @ts-ignore
                                    index,
                                ) {
                                    return (
                                        <option
                                            key={index}
                                            value={
                                                column.property === 'district'
                                                    ? key.value
                                                    : key.value.id
                                            }
                                        >
                                            {key.label}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>
                )}
            </div>
        );
    }
}

export default Filter;

import { ListRequest } from './listRequest';
import { GrowerFilter } from './filter';
import { Grower } from './grower';

export interface GrowerAccount {
    id: number;

    name: string;

    primaryNumber: string;

    secondaryNumber?: string;

    email: string;

    address: string;

    address2?: string;

    city: string;

    state: string;

    zipCode: string;

    secondaryContactName?: string;

    secondaryContactNumber?: string;

    secondaryContactEmail?: string;

    secondaryContactAddress?: string;

    secondaryContactAddress2?: string;

    secondaryContactCity?: string;

    secondaryContactState?: string;

    secondaryContactZipCode?: string;

    growerMemberSince?: Date;

    growers: Grower[];

    last_updated?: Date;

    date_created?: Date;

    deleted: boolean;
}

export interface GrowerAccountListRequest extends ListRequest {
    filter?: GrowerFilter;
    growerAccountId?: string;
    sort?: string;
    sortDirection?: string;
}

export function getFlatGrowerAccountValue(
    growerAccount?: GrowerAccount | null,
    property?: keyof GrowerAccount,
): string | number {
    if (growerAccount && property) {
        const value = growerAccount[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        }
    }

    return '';
}
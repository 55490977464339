import React, {
    Component,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { Api } from '@citrus-tracker/api';
import {
    Agreement,
    AgreementFilter,
    AgreementFilterOptions,
    AgreementOptions,
    User,
} from '@citrus-tracker/types';
import Filter, { FilterColumn } from './filter';
import { Table as UITable } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentAgreementIndex: number;
    filter: AgreementFilter;
    filterOptions: AgreementFilterOptions;
    agreements: Agreement[];
    agreementOptions: AgreementOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalAgreements: number;
    loadAgreements(page: number, AgreementsPerPage: number): Promise<void>;
    updateFilter(filter: AgreementFilter): void;
    agreement?: Agreement;
    agreementId?: string;
    user?: User;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        page: number;
        agreementsPerPage: number;
        agreementVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        page: 1,
        agreementsPerPage: 15,
        agreementVisibleColumns: {
            name: true,
            ein: true,
            packingHouseName: true,
            lastUpdate: true,
            status: true,
            updatedBy: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentAgreement();

        const columnString = localStorage.getItem('AgreementVisibleColumns');
        if (columnString) {
            this.setState({ AgreementVisibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
        
        const page = localStorage.getItem('agreementsPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentAgreementIndex } = this.props;

        if (
            currentAgreementIndex &&
            (!oldProps.agreements ||
                currentAgreementIndex !== oldProps.currentAgreementIndex)
        ) {
            this.goToCurrentAgreement();
        }
    }

    goToCurrentAgreement = (): void => {
        const { currentAgreementIndex, agreements: Agreements } = this.props;

        if (Agreements) {
            if (currentAgreementIndex) {
                this.goToAgreement(currentAgreementIndex);
            } else {
                this.goToAgreement(1);
            }
        }
    };

    goToPage = (newPage: number): void => {
        const { loadAgreements } = this.props;
        const { agreementsPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadAgreements(newPage, agreementsPerPage);

        //Store page in session so we can persist across page reloads
        localStorage.setItem('agreementsPage', JSON.stringify(newPage));
    };

    goToAgreement = (AgreementIndex: number): void => {
        const { agreementsPerPage: AgreementsPerPage } = this.state;

        if (AgreementsPerPage) {
            const page = localStorage.getItem('agreementsPage') ?? Math.ceil(AgreementIndex / AgreementsPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    openEdit = (agreementIndex?: number): void => {
        const { agreementId } = this.props;
        if (agreementIndex) {
            this.props.history.push( `/agreements/details/${agreementIndex ?? agreementId}`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    toggleColumnVisibility = (property: keyof Agreement): void => {
        const agreementVisibleColumns = {
            ...this.state.agreementVisibleColumns,
            [property]: !this.state.agreementVisibleColumns[property],
        };

        this.setState({
            agreementVisibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('agreementVisibleColumns', JSON.stringify(agreementVisibleColumns));
    };

    renderForDisplay = (
        agreement: Agreement,
        property: keyof Agreement,
    ): ReactElement => {
        switch (property) {
            case 'name' as keyof Agreement:
                return <span>{agreement?.grower?.name}</span>
            case 'lastUpdate' as keyof Agreement:
                return <span>{new Date(agreement?.last_updated ?? '').toLocaleString()}</span>    
            case 'updatedBy' as keyof Agreement:
                return <span>{!agreement.updated_by || agreement.updated_by?.includes('null') ? '' : agreement.updated_by}</span>    
            case 'packingHouseName' as keyof Agreement:
                return (
                    <span title={agreement?.packingHouse?.packing_house_name}>
                        {agreement?.packingHouse?.packing_house_name}
                    </span>
                );
            default:
                return <span>{agreement[property]}</span>;
        }
    };

    render(): ReactElement {
        const {
            agreements,
            totalAgreements,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            agreement: Agreement,
        } = this.props;
        const {
            page,
            agreementsPerPage,
            agreementVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalAgreements / agreementsPerPage);

        const indexBase = (page - 1) * agreementsPerPage;

        const allColumns: {
            property: keyof Agreement;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'name' as keyof Agreement,
                title: 'Grower',
                filter: true,
                sort: 'grower.name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'packingHouseName' as keyof Agreement,
                title: 'Packing House',
                filter: true,
                sort: 'packingHouse.packing_house_name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'status' as keyof Agreement,
                title: 'Status',
                filter: true,
                sort: 'agreement.status',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'lastUpdate' as keyof Agreement,
                title: 'Last Update',
                filter: true,
                sort: 'agreement.last_updated',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'updatedBy' as keyof Agreement,
                title: 'Updated By',
                filter: true,
                sort: 'agreement.updated_by',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
        ];

        const columns = allColumns.filter(
            (column) => agreementVisibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        const start = (page * agreementsPerPage) - (agreementsPerPage - 1);
        const end = Math.min(start + agreementsPerPage - 1, totalAgreements);

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={Agreement?.isAdmin()}
                        visibleColumns={agreementVisibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={agreements}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalAgreements} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Table);

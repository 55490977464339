import React, {
    Component,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { Api } from '@citrus-tracker/api';
import {
    Grower,
    GrowerFilter,
    GrowerFilterOptions,
    GrowerOptions,
    User,
} from '@citrus-tracker/types';
import Filter, { FilterColumn } from './filter';
import { Table as UITable } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentGrowerIndex: number;
    filter: GrowerFilter;
    filterOptions: GrowerFilterOptions;
    growers: Grower[];
    growerOptions: GrowerOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalGrowers: number;
    loadGrowers(growerId: string, page: number, growersPerPage: number): Promise<void>;
    updateFilter(filter: GrowerFilter): void;
    grower?: Grower;
    growerId?: string;
    user?: User;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        page: number;
        growersPerPage: number;
        growerVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        page: 1,
        growersPerPage: 15,
        growerVisibleColumns: {
            name: true,
            ein: true,
            primaryNumber: true,
            city: true,
            state: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentGrower();

        const columnString = localStorage.getItem('growerVisibleColumns');
        if (columnString) {
            this.setState({ growerVisibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );

        const page = localStorage.getItem('growersPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentGrowerIndex } = this.props;

        if (
            currentGrowerIndex &&
            (!oldProps.growers ||
                currentGrowerIndex !== oldProps.currentGrowerIndex)
        ) {
            this.goToCurrentGrower();
        }
    }

    goToCurrentGrower = (): void => {
        const { currentGrowerIndex, growers } = this.props;

        if (growers) {
            if (currentGrowerIndex) {
                this.goToGrower(currentGrowerIndex);
            } else {
                this.goToGrower(1);
            }
        }
    };

    goToPage = (newPage: number): void => {
        const { loadGrowers, growerId } = this.props;
        const { growersPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadGrowers(growerId ?? '', newPage, growersPerPage);

        //Store page in session so we can persist across page reloads
        localStorage.setItem('growersPage', JSON.stringify(newPage));
    };

    goToGrower = (growerIndex: number): void => {
        const { growersPerPage } = this.state;

        if (growersPerPage) {
            const page = localStorage.getItem('growersPage') ?? Math.ceil(growerIndex / growersPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    openEdit = (growerIndex?: number): void => {
        const { user, growerId } = this.props;
        if (growerIndex) {
            this.props.history.push( `/growers/${
                growerId !== ''
                    ? growerId
                    : user
                          ?.id
            }/edit/${growerIndex}`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    toggleColumnVisibility = (property: keyof Grower): void => {
        const growerVisibleColumns = {
            ...this.state.growerVisibleColumns,
            [property]: !this.state.growerVisibleColumns[property],
        };

        this.setState({
            growerVisibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('growerVisibleColumns', JSON.stringify(growerVisibleColumns));
    };

    renderForDisplay = (
        grower: Grower,
        property: keyof Grower,
    ): ReactElement => {
        switch (property) {
            default:
                return <span>{grower[property]}</span>;
        }
    };

    render(): ReactElement {
        const {
            growers: Growers,
            totalGrowers,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            grower,
            user,
        } = this.props;
        const {
            page,
            growersPerPage,
            growerVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalGrowers / growersPerPage);

        const indexBase = (page - 1) * growersPerPage;

        const allColumns: {
            property: keyof Grower;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'name' as keyof Grower,
                title: 'Name',
                filter: true,
                sort: 'grower.name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'ein' as keyof Grower,
                title: 'EIN',
                filter: true,
                sort: 'grower.ein',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'primaryNumber' as keyof Grower,
                title: 'Phone Number',
                filter: true,
                sort: 'grower.primaryNumber',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'city' as keyof Grower,
                title: 'City',
                filter: true,
                sort: 'grower.city',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'state' as keyof Grower,
                title: 'State',
                filter: true,
                sort: 'grower.state',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
        ];

        const columns = allColumns.filter(
            (column) => growerVisibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        const start = (page * growersPerPage) - (growersPerPage - 1);
        const end = Math.min(start + growersPerPage - 1, totalGrowers);

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={grower?.isAdmin()}
                        visibleColumns={growerVisibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={Growers}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                        currentUser={user}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalGrowers} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Table);

import { AgreementBlocks } from '@citrus-tracker/types';
import { Fragment } from 'react';
import { IAgreementDetail } from '../IAgreementDetail';

type Row = {
    leftColumn: string;
    middleColumn: string;
    updateLink: boolean;
    onclick?: (e: any) => any;
};

export const OverviewDetail = ({ agreement }: IAgreementDetail) => {
    const rows = (): Row[] => {
        if (agreement)
            return [
                {
                    leftColumn: 'Grower',
                    middleColumn: agreement?.grower?.name + '',
                    updateLink: false,
                    onclick: (): void => {
                        window.location.href = `/growers/${agreement?.grower?.id}/edit/${agreement?.grower?.id}`;
                    },
                },
                {
                    leftColumn: 'Agreement Number',
                    middleColumn: agreement.id + '',
                    updateLink: false,
                },
                {
                    leftColumn: 'Status',
                    middleColumn: agreement.status + '',
                    updateLink: false,
                },
                {
                    leftColumn: 'Association',
                    middleColumn: agreement.association + '',
                    updateLink: false,
                },
                {
                    leftColumn: 'Joined',
                    middleColumn: ('' + agreement.date_created).split('T')[0],
                    updateLink: false,
                },
            ];
        return [];
    };

    return (
        <Fragment>
            <div className="bg-white border rounded-lg border-gray-200">
                <div className="flex items-start justify-center flex-col border-b p-4">
                    <div className="text-lg mb-1 text-gray-900 font-medium ">
                        Agreement Details
                    </div>
                    <div className="text-gray-500 text-sm">
                        {agreement?.details}
                    </div>
                </div>

                {rows().map((el: Row, i) => {
                    return (
                        <div
                            key={'overview' + i}
                            className={
                                'px-4 py-3 flex justify-between border-gray-200 font-medium ' +
                                (i < rows().length - 1 ? 'border-b ' : '') +
                                (el.onclick ? 'cursor-pointer' : '')
                            }
                            onClick={el.onclick}
                        >
                            <div className="flex flex-1 items-center text-gray-500">
                                {el.leftColumn}
                            </div>
                            <div className="flex flex-1 items-center text-gray-900">
                                {el.middleColumn}
                            </div>
                            <div className="flex items-center text-indigo-600 font-medium w-14">
                                {el.updateLink ? 'Update' : ''}
                            </div>
                        </div>
                    );
                })}
            </div>
            <br />
            {agreement?.agreementBlocks &&
                agreement.agreementBlocks.length > 0 && (
                    <div className="bg-white border rounded-lg border-gray-200">
                        <div className="flex items-start justify-center flex-col border-b p-4">
                            <div className="text-lg mb-1 text-gray-900 font-medium ">
                                Blocks
                            </div>
                        </div>

                        {agreement?.agreementBlocks?.map(
                            (el: AgreementBlocks, i) => {
                                return (
                                    <div
                                        key={'block' + i}
                                        className={
                                            'px-4 py-3 flex justify-between border-gray-200 font-medium cursor-pointer ' +
                                            (i < rows().length - 1
                                                ? 'border-b'
                                                : '')
                                        }
                                        onClick={(): void => {
                                            window.location.href = `/blocks/edit/${el.block?.id}`;
                                        }}
                                    >
                                        <div className="flex flex-1 items-center text-gray-500">
                                            {el.block?.block}(
                                            {el.block?.block_description}){' '}
                                            {el.block?.crop?.crop}-
                                            {el.block?.variety?.variety}
                                        </div>
                                        <div className="flex flex-1 items-center text-gray-900">
                                            Total Acres Allocated:{' '}
                                            {el.blocksAllocated
                                                ? parseFloat(
                                                      el.blocksAllocated.toString(),
                                                  ).toFixed(2)
                                                : 0.0}
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    </div>
                )}
        </Fragment>
    );
};

import React, { Component, ReactElement, Fragment } from 'react';

import './form.module.scss';

import { Form, Input, Select } from '@citrus-tracker/layout/form';
import { getFlatBlockValue, Block, BlockOptions, User } from '@citrus-tracker/types';

import { Button, Modal } from '@citrus-tracker/ui';
import { Api } from '@citrus-tracker/api';

/* eslint-disable-next-line */
export interface FormProps {
    disabledFields?: (keyof Block)[];
    onCancel?(): unknown;
    openDelete?(): void;
    block: Block;
    blockOptions: BlockOptions;
    saveChanges(): Promise<unknown>;
    setUpdates?(updates: Block): void;
    user?: User;
}

export class BlockForm extends Component<FormProps> {
    props: Readonly<FormProps>;

    state: {
        unknownYear: boolean;
        updates: Block;
        isAPNModalOpen: boolean;
        tempAPNList: string[];
        tempAPN: string;
        showRanch: boolean;
    } = {
        unknownYear: false,
        updates: {} as Block,
        isAPNModalOpen: false,
        tempAPNList: [],
        tempAPN: '',
        showRanch: false,
    };

    componentDidMount(): void {
        if (this.props.block.id) {
            this.setUpdates(this.props.block);
            if (!this.props.block.plant_year) {
                this.setState({
                    unknownYear: true,
                });
            }
            if (
                (!this.props.blockOptions.ranches ||
                    this.props.blockOptions.ranches.length === 0) &&
                this.props.block.grower
            ) {
                Api.blocks
                    .getRanchesByGrower(this.props.block.grower.id)
                    .then((ranches) => {
                        this.props.blockOptions.ranches = ranches;
                    });
            }
        }
    }

    componentDidUpdate(oldProps: FormProps): void {
        if (this.props.block.id !== oldProps.block.id) {
            this.setUpdates({} as Block);
        }
    }

    setUpdates = (updates: Block): void => {
        const { setUpdates } = this.props;

        if (
            !!updates.total_acres &&
            !!updates.trees_per_acre &&
            !isNaN(updates.trees_per_acre)
        ) {
            updates.trees = Math.round(
                parseFloat(updates.total_acres) * updates.trees_per_acre,
            );
        } else if (
            !!updates.total_acres &&
            !!updates.trees &&
            !isNaN(updates.trees)
        ) {
            updates.trees_per_acre = Math.round(
                updates.trees / parseFloat(updates.total_acres),
            );
        } else {
            updates.trees = 0;
        }

        if (
            !!updates.plant_year &&
            parseInt(updates.plant_year) > 1900 &&
            parseInt(updates.plant_year) <= new Date().getFullYear()
        ) {
            updates.tree_age =
                new Date().getFullYear() - parseInt(updates.plant_year);
        } else {
            updates.tree_age = undefined;
        }

        this.setState({
            updates,
        });

        if (setUpdates) {
            setUpdates(updates);
        }
    };

    setValue = (property: keyof Block, value: unknown): void => {
        const { block } = this.props;

        const updates = {
            ...this.state.updates,
        };

        if (property === 'trees') {
            updates.trees_per_acre = undefined;
        }

        if (property === 'trees_per_acre') {
            updates.trees = undefined;
        }

        // These properties are related objects
        if (
            property === 'crop' ||
            property === 'plantAge' ||
            property === 'rootstock' ||
            property === 'subVariety' ||
            property === 'variety'
        ) {
            if (typeof value === 'string') {
                value = parseInt(value);
            }

            // For dependent fields, a change to a parent should unset children
            if (
                property === 'crop' &&
                value !== block.crop?.id &&
                value !== updates.crop?.id
            ) {
                updates.variety = undefined;
                updates.subVariety = undefined;
            }
            if (
                property === 'variety' &&
                value !== block.variety?.id &&
                value !== updates.variety?.id
            ) {
                updates.subVariety = undefined;
            }

            value = {
                id: value,
            };
        }

        if (property === 'total_acres' && typeof value === 'number') {
            value = value.toFixed(2);
        }

        const newUpdates = {
            ...updates,
            [property]: value,
        };

        this.setUpdates(newUpdates);
    };

    getValue = (property: keyof Block): string | number => {
        const { block } = this.props;
        const { updates } = this.state;

        return updates[property] !== undefined
            ? getFlatBlockValue(updates, property)
            : getFlatBlockValue(block, property);
    };

    renderInput = (
        property: keyof Block,
        label: string,
        additionalProps: { [key: string]: unknown } = {},
    ): ReactElement => {
        const disabledFields = this.props.disabledFields || [];

        let value = this.getValue(property);

        if (typeof value === 'number' && property === 'total_acres') {
            value = value.toFixed(2);
        } else if (typeof value === 'number') {
            value = value.toString();
        }

        if (typeof value !== 'string') {
            value = '';
        }

        if (property == 'trees') {
            value = new Intl.NumberFormat()
                .format(Math.round(parseInt(value)))
                .toString();
        }

        return (
            <div className="form-group">
                <Input
                    className="placeholder-gray-700 appearance-none block bg-gray-200 w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={property}
                    label={label}
                    onChange={(newValue: string): void =>
                        this.setValue(property, newValue)
                    }
                    value={value}
                    {...additionalProps}
                    disabled={
                        disabledFields.indexOf(property) >= 0 ||
                        additionalProps.disabled
                    }
                />
            </div>
        );
    };

    renderSelect = (
        property: keyof Block,
        label: string,
        options: {
            label: string;
            value: string;
        }[],
        additionalProps: { [key: string]: unknown } = {},
    ): ReactElement => {
        const disabledFields = this.props.disabledFields || [];

        const value = this.getValue(property);

        return (
            <div className="form-group">
                <Select
                    id={property}
                    label={label}
                    onChange={(newValue: string | number): void =>
                        this.setValue(property, newValue)
                    }
                    options={options}
                    value={value.toString()}
                    {...additionalProps}
                    disabled={
                        disabledFields.indexOf(property) >= 0 ||
                        additionalProps.disabled
                    }
                />
            </div>
        );
    };

    saveChanges = async (e?: { preventDefault(): void }): Promise<unknown> => {
        const { saveChanges } = this.props;

        if (e) {
            e.preventDefault();
        }

        return saveChanges();
    };

    openAPNModal = (): void => {
        const apns = this.getValue('apn');
        this.setState({
            isAPNModalOpen: true,
            tempAPNList:
                apns.toString().length > 0 ? apns.toString().split(',') : [],
        });
    };

    addToApn = (): void => {
        const apnList = [...this.state.tempAPNList];

        apnList.push(this.state.tempAPN);

        this.setState({ tempAPNList: apnList, tempAPN: '' });
    };

    removeFromApn = (index: number): void => {
        const apnList = [...this.state.tempAPNList];

        apnList.splice(index, 1);

        this.setState({ tempAPNList: apnList });
    };

    saveAPN = (): void => {
        const apnList = [...this.state.tempAPNList];

        if (this.state.tempAPN !== '') {
            apnList.push(this.state.tempAPN);
        }

        const newApn = apnList.join(',');
        if (newApn !== '') {
            this.setValue('apn', newApn);

            this.setState({
                isAPNModalOpen: false,
                tempAPN: '',
            });
        }
    };

    onEnter = (e: any): void => {
        if (e.charCode === 13 && this.state.tempAPN.toString().length > 2) {
            this.addToApn();
        }
    };

    render(): ReactElement {
        const { onCancel, openDelete, block, blockOptions } = this.props;
        const {
            unknownYear,
            isAPNModalOpen,
            tempAPNList,
            tempAPN,
            showRanch,
        } = this.state;
        const disabledFields = this.props.disabledFields || [];

        if (block.id && block.plant_year && !block.tree_age && !unknownYear) {
            block.tree_age =
                new Date().getFullYear() - parseInt(block.plant_year);
        }

        const selectedCropId = this.getValue('crop');
        const selectedVarietyId = this.getValue('variety');
        if (selectedCropId) {
            for (const crop of blockOptions.crops) {
                if (crop.id === selectedCropId) {
                    blockOptions.selectedCrop = crop;

                    if (
                        selectedVarietyId &&
                        blockOptions.selectedCrop.varieties
                    ) {
                        for (const variety of blockOptions.selectedCrop
                            .varieties) {
                            if (variety.id === selectedVarietyId) {
                                blockOptions.selectedVariety = variety;
                            }
                        }
                    }
                }
            }
        }

        const plantYear = this.getValue('plant_year');

        return (
            <Form
                className="w-full"
                id="wizardForm"
                onSubmit={this.saveChanges}
            >
                <div className="flex flex-wrap bg-white p-2 mb-1 rounded">
                    <div className="w-1/3">
                        {this.renderInput('total_acres', 'Planted Acres', {
                            placeholder: 'Enter Acres',
                            type: 'number',
                            step: '0.01',
                        })}
                    </div>

                    <div className="w-1/3">
                        {this.renderInput('block', 'Block ID', {
                            placeholder: 'Enter Block ID',
                        })}
                    </div>

                    <div className="w-1/3">
                        {this.renderInput(
                            'block_description',
                            'Block Description',
                            {
                                placeholder: 'Enter Block Description',
                            },
                        )}
                    </div>

                    <div className="w-1/3">
                        { this.props.user?.isPackingHouseUser() && 
                            <div className='px-3'>
                                <div className="form-check form-check-inline -mt-1 ml-5 float-right">
                                    <label
                                        className="form-check-label"
                                        htmlFor="showRanch"
                                    >
                                        <small>Current Ranch: </small>
                                    </label>{' '}
                                    <input
                                        className="form-checkbox float-right w-4 inline-flex mt-2"
                                        type="checkbox"
                                        name="showRanch"
                                        id="showRanch"
                                        checked={showRanch}
                                        onChange={(): void => {
                                            this.setState({
                                                showRanch: !showRanch,
                                            });
                                        }}
                                    />
                                </div>
                                { !showRanch && <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="Ranch"
                                    >
                                        Ranch:
                                    </label>
                                }
                            </div>                            
                        }
                        {(showRanch || !this.props.user?.isPackingHouseUser()) && this.renderSelect(
                            'ranch',
                            'Ranch',
                            blockOptions.getSelectOptions('ranch'),
                        )}
                    </div>

                    <div className="w-1/3">
                        {this.renderSelect(
                            'district',
                            'District',
                            blockOptions.getSelectOptions('district'),
                        )}
                    </div>

                    <div className="w-1/3">
                        {this.renderSelect(
                            'crop',
                            'Crop',
                            blockOptions.getSelectOptions('crop'),
                        )}
                    </div>

                    <div className="w-1/3">
                        {this.renderSelect(
                            'variety',
                            'Sunkist Marketing Variety',
                            blockOptions.getSelectOptions('variety'),
                        )}
                    </div>

                    <div className="w-1/3">
                        {blockOptions.getSelectOptions('subVariety').length >
                        0 ? (
                            this.renderSelect(
                                'subVariety',
                                'Sub-Variety',
                                blockOptions.getSelectOptions('subVariety'),
                            )
                        ) : (
                            <div className="text-center mt-6">
                                <label>No Sub-Varieties Available</label>
                            </div>
                        )}
                    </div>

                    <div className="w-1/3">
                        {this.renderSelect(
                            'organic',
                            'Organic',
                            blockOptions.getSelectOptions('organic'),
                        )}
                    </div>

                    <div className="w-1/3 px-3">
                        <div className="form-check form-check-inline -mt-1 ml-5 float-right">
                            <label
                                className="form-check-label"
                                htmlFor="unknownYear"
                            >
                                <small>Unknown: </small>
                            </label>{' '}
                            <input
                                className="form-checkbox float-right w-4 inline-flex mt-2"
                                disabled={
                                    disabledFields.indexOf('plant_year') >= 0
                                }
                                type="checkbox"
                                name="unknownYear"
                                id="unknownYear"
                                checked={unknownYear}
                                onChange={(): void => {
                                    if (!unknownYear) {
                                        this.setValue('plant_year', null);
                                    }

                                    this.setState({
                                        unknownYear: !unknownYear,
                                    });
                                }}
                            />
                        </div>
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="plantYear"
                        >
                            Plant Year:
                        </label>

                        <input
                            id="plant_year"
                            className="placeholder-gray-700 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            disabled={
                                unknownYear ||
                                disabledFields.indexOf('plant_year') >= 0
                            }
                            type="number"
                            min="1900"
                            max={new Date().getFullYear()}
                            onChange={(e): void =>
                                this.setValue('plant_year', e.target.value)
                            }
                            value={plantYear}
                            placeholder="Enter Plant Year (YYYY)"
                        />
                        {plantYear &&
                            typeof plantYear === 'string' &&
                            (parseInt(plantYear) < 1900 ||
                                parseInt(plantYear) >
                                    new Date().getFullYear()) && (
                                <div
                                    id="plantYearValidation"
                                    className="invalid-feedback"
                                >
                                    Plant Year must be between 1900 to{' '}
                                    {new Date().getFullYear()}
                                </div>
                            )}
                    </div>

                    <div className="w-1/3">
                        {!unknownYear &&
                            this.renderInput('tree_age', 'Age', {
                                placeholder: 'Age of Trees',
                                disabled: true,
                            })}
                        {unknownYear &&
                            this.renderSelect(
                                'plantAge',
                                'Age Range',
                                blockOptions.getSelectOptions('plantAge'),
                            )}
                    </div>

                    <div className="w-1/3">
                        {this.renderSelect(
                            'rootstock',
                            'Rootstock',
                            blockOptions.getSelectOptions('rootstock'),
                        )}
                    </div>

                    <div className="w-1/3">
                        {this.renderInput('trees_per_acre', 'Trees/Acre', {
                            placeholder: 'Enter Trees/Acre',
                            type: 'number',
                            step: '0.01',
                        })}
                    </div>

                    <div className="w-1/3">
                        {this.renderInput('trees', 'Trees', {
                            placeholder: 'Enter Trees',
                        })}
                    </div>

                    <div className="w-1/3">
                        <div className="form-group">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                APNs:
                            </label>
                            <button
                                className="w-full overflow-ellipsis overflow-hidden"
                                type="button"
                                onClick={this.openAPNModal}
                            >
                                {this.getValue('apn') ||
                                    'Click here to enter all APNs associated with the block.'}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 text-right mt-1.5">
                    {onCancel && (
                        <Button
                            type="button"
                            onClick={onCancel}
                            data-dismiss="modal"
                            style={{ marginRight: 10 }}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                    )}
                    <Button className="ml-2 float-right" type="submit">
                        Save Changes
                    </Button>

                    {openDelete && (
                        <Button
                            className="ml-2 float-right"
                            onClick={openDelete}
                            type="button"
                            variant="delete"
                        >
                            Delete
                        </Button>
                    )}
                </div>
                <Modal
                    title="APN List"
                    show={isAPNModalOpen}
                    close={(): void =>
                        this.setState({
                            openAPNModal: false,
                        })
                    }
                    content={
                        <Fragment>
                            {' '}
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-100 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    value={tempAPN}
                                    placeholder="Enter an APN"
                                    onChange={(e): void =>
                                        this.setState({
                                            tempAPN: e.target.value,
                                        })
                                    }
                                    onKeyPress={this.onEnter}
                                />
                                <ul className="border p-2">
                                    {tempAPNList.length > 0 &&
                                        tempAPNList.map((el, i) => (
                                            <li key={i}>
                                                {el}
                                                <button
                                                    style={{ paddingBottom: 4 }}
                                                    className="float-right items-center h-5 mt-0.5 rounded-md focus:outline-none text-white bg-red-600 hover:bg-red-700 leading-3 px-1"
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                `Are you sure you want to remove ${el}?`,
                                                            )
                                                        )
                                                            this.removeFromApn(
                                                                i,
                                                            );
                                                    }}
                                                >
                                                    -
                                                </button>
                                            </li>
                                        ))}
                                    {(!tempAPNList ||
                                        tempAPNList.length === 0) && (
                                        <li>Press enter to add APN(s)</li>
                                    )}
                                </ul>
                            </div>
                            <Button
                                onClick={(): void => {
                                    this.saveAPN();
                                }}
                                type="button"
                                data-dismiss="modal"
                                className="float-right mt-4"
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                onClick={(): void =>
                                    this.setState({
                                        isAPNModalOpen: false,
                                    })
                                }
                                data-dismiss="modal"
                                variant="secondary"
                                className="mr-4 float-right mt-4"
                            >
                                Cancel
                            </Button>
                        </Fragment>
                    }
                />
            </Form>
        );
    }
}

export default BlockForm;

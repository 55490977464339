import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BlocksDetails } from './blocksDetail/blocksDetail';
import { OverviewDetail } from './overviewDetail/overviewDetail';
import './agreementDetail.scss';
import { Api } from '@citrus-tracker/api';
import { Agreement, User } from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import RanchComponent from '../../ranch/ranches';

interface PathParams {
    id: string;
}

enum Options {
    overview,
    blocks,
    ranches,
}
export interface AgreementDetailProps extends RouteComponentProps<PathParams> {
    user?: User | null;
}

export class AgreementDetail extends Component<AgreementDetailProps> {
    props: Readonly<AgreementDetailProps>;

    state: {
        id: string;
        selectedOption: Options;
        agreement: Agreement;
    } = {
        id: '',
        selectedOption: Options.overview,
        agreement: {} as Agreement,
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;
        this.loadAgreement(id);
        this.setState({ id: id });
    }

    loadAgreement = async (id: string): Promise<void> => {
        const agreement = await Api.agreements.get(+id);

        this.setState({
            agreement: agreement,
        });
    };

    setSelected = (isSelected: boolean): string => {
        if (isSelected) return 'detailButton bg-gray-200';
        return 'detailButton';
    };

    setSelectedOption = (option: Options): void => {
        this.setState({
            selectedOption: option,
        });
    };

    updateStatus = async (status: string): Promise<void> => {
        if (this.state.agreement) {
            this.state.agreement.status = status;
            this.state.agreement.updated_by = `${this.props.user?.firstName} ${this.props.user?.lastName}`
            await Api.agreements.save(this.state.agreement);
            this.setState({
                agreement: this.state.agreement,
            });
        }
    };

    delete = async (): Promise<void> => {
        if (
            this.state.agreement &&
            this.state.agreement.id &&
            window.confirm(
                `Are you sure you want to delete ${this.state.agreement.details}`,
            ) === true
        ) {
            await Api.agreements.delete(this.state.agreement.id);
            this.props.history.goBack();
        }
    };

    render(): ReactElement {
        const { user } = this.props;
        const { agreement, selectedOption } = this.state;
        return (
            <div className="px-8">
                <div className="sm:py-4 items-center flex-col sm:flex-row">
                    <Link to={'/agreements'}>
                        <div className="float-right font-medium text-sm flex items-center justify-center cursor-pointer px-4 py-2 bg-white border-gray border rounded-md">
                            Close
                        </div>
                    </Link>
                    <Button
                        className="mx-2 float-right"
                        onClick={this.delete}
                        type="button"
                        variant="delete"
                    >
                        Delete
                    </Button>
                    <div className="text-3xl font-bold text-gray-900">
                        {agreement?.grower?.name}
                    </div>
                </div>
                <div className="flex justify-center items-center sm:justify-start">
                    <div
                        className={this.setSelected(
                            selectedOption === Options.overview,
                        )}
                        onClick={(): void =>
                            this.setSelectedOption(Options.overview)
                        }
                    >
                        Overview
                    </div>
                    <div
                        className={this.setSelected(
                            selectedOption === Options.blocks,
                        )}
                        onClick={(): void =>
                            this.setSelectedOption(Options.blocks)
                        }
                    >
                        Blocks
                    </div>
                    <div
                        className={this.setSelected(
                            selectedOption === Options.ranches,
                        )}
                        onClick={(): void =>
                            this.setSelectedOption(Options.ranches)
                        }
                    >
                        Ranches
                    </div>
                </div>
                <div>
                    {selectedOption === Options.overview && (
                        <OverviewDetail agreement={agreement} />
                    )}
                    {selectedOption === Options.blocks && (
                        <BlocksDetails agreement={agreement} />
                    )}
                    {selectedOption === Options.ranches && (
                        <RanchComponent user={user} agreement={agreement}/>
                    )}
                </div>
                {user && agreement && agreement.agreementBlocks && (
                    <div className="col-md-12 text-right pt-1 mt-4">
                        {user.isPackingHouseUser() &&
                            agreement?.agreementBlocks?.length > 0 &&
                            agreement.status === 'Open' && (
                                <Button
                                    type="button"
                                    variant="exit-next"
                                    onClick={async (): Promise<void> =>
                                        await this.updateStatus(
                                            'Submitted',
                                        )
                                    }
                                > 
                                    Approve
                                </Button>
                            )}
                        {agreement?.agreementBlocks?.length > 0 && 
                         agreement.status !== 'Denied' &&
                         agreement.status !== 'Active' && (
                                <Button
                                    className="mx-1"
                                    type="button"
                                    variant="delete"
                                    onClick={async (): Promise<void> =>
                                        await this.updateStatus('Denied')
                                    }
                                >
                                    Deny
                                </Button>
                            )}
                        {user.isAdmin() &&
                            agreement?.agreementBlocks?.length > 0 &&
                            agreement.status === 'Submitted' && (
                                <Button
                                    type="button"
                                    variant="exit-next"
                                    onClick={async (): Promise<void> =>
                                        await this.updateStatus('Approved')
                                    }
                                >
                                    Approve
                                </Button>
                            )}

                        {user.isGrower() &&
                            agreement?.agreementBlocks?.length > 0 &&
                            agreement.status === 'Approved' &&  (
                                <Button
                                    type="button"
                                    variant="exit-next"
                                    onClick={async (): Promise<void> =>
                                        await this.updateStatus(
                                            'Grower Submitted',
                                        )
                                    }
                                >
                                    Approve
                                </Button>
                            )}
                        {user.isAdmin() &&
                            agreement?.agreementBlocks?.length > 0 &&
                            agreement.status === 'Approved' && (
                                <Button
                                    type="button"
                                    variant="exit-next"
                                    onClick={async (): Promise<void> =>
                                        await this.updateStatus('Active')
                                    }
                                >
                                    Activate
                                </Button>
                            )}
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(AgreementDetail);

import { Block, Grower } from '..';
import { RanchFilter } from './filter';
import { ListRequest } from './listRequest';

export interface Ranch {
    id: number;

    grower?: Grower;

    ranchName: string;

    county: string;

    wellWater: boolean;

    districtWater: boolean;

    districtName?: string;

    windMachine: boolean;

    windMachineCount?: string;

    blocks?: Block[];

    packingHouseId?: number;

    packingHouseUserId?: number;
}

export interface RanchListRequest extends ListRequest {
    filter?: RanchFilter;
    growerId?: string;
    sort?: string;
    sortDirection?: string;
}

export function getFlatRanchValue(
    ranch?: Ranch | null,
    property?: keyof Ranch,
): string | number {
    if (ranch && property) {
        const value = ranch[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        }
    }

    return '';
}

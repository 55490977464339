import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './estimate-history-table.scss';

import { FieldEstimate, Record } from '@citrus-tracker/types';
import { Table as UITable } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    record: Record;
}

export class EstimateHistoryTable extends Component<TableProps> {
    props: Readonly<TableProps>;

    state: {
        editingRecord: Record;
    } = {
        editingRecord: {},
    };

    componentDidMount(): void {
        this.setState({
            editingRecord: this.props.record,
        });
    }

    componentDidUpdate(oldProps: TableProps): void {
        if (this.props.record !== oldProps.record) {
            this.setState({
                editingRecord: this.props.record,
            });
        }
    }

    renderForDisplay = (
        fieldEstimate: FieldEstimate,
        property: keyof FieldEstimate,
    ): ReactElement => {
        switch (property) {
            default:
                return <span>{fieldEstimate[property]}</span>;
        }
    };

    render(): ReactElement {
        const { record } = this.props;

        const allColumns: {
            property: keyof FieldEstimate;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'harvest_phase' as keyof FieldEstimate,
                title: 'Season',
                sort: 'harvest_phase',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'estimate_date' as keyof FieldEstimate,
                title: 'Estimate Date',
                sort: 'estimate_date',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'reason' as keyof FieldEstimate,
                title: 'Reason',
                sort: 'reason',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'picked_to_date' as keyof FieldEstimate,
                title: 'Bins Picked to Date',
                sort: 'picked_to_date',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            // {
            //     // property: 'fieldEstimate' as keyof FieldEstimate,
            //     title: 'Bins Season Estimate',
            //     // sort: 'bins_season_estimate',
            //     tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'trees_per_bin' as keyof FieldEstimate,
                title: 'Trees Per Bin',
                sort: 'trees_per_bin',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'cartons_per_tree' as keyof FieldEstimate,
                title: 'Raw Cartons Per Tree',
                sort: 'cartons_per_tree',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            // {
            //     // property: 'fieldEstimate' as keyof FieldEstimate,
            //     title: 'Cartons Per Bin',
            //     // sort: 'cartons_per_bin',
            //     tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'export_percent' as keyof FieldEstimate,
                title: 'Export %',
                sort: 'export_percent',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fancy_percent' as keyof FieldEstimate,
                title: 'Fancy %',
                sort: 'fancy_percent',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_1' as keyof FieldEstimate,
                title: 'Fruit Size 1',
                sort: 'fruit_size_1',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_2' as keyof FieldEstimate,
                title: 'Fruit Size 2',
                sort: 'fruit_size_2',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_3' as keyof FieldEstimate,
                title: 'Fruit Size 3',
                sort: 'fruit_size_3',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_4' as keyof FieldEstimate,
                title: 'Fruit Size 4',
                sort: 'fruit_size_4',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_5' as keyof FieldEstimate,
                title: 'Fruit Size 5',
                sort: 'fruit_size_5',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_6' as keyof FieldEstimate,
                title: 'Fruit Size 6',
                sort: 'fruit_size_6',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_7' as keyof FieldEstimate,
                title: 'Fruit Size 7',
                sort: 'fruit_size_7',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_8' as keyof FieldEstimate,
                title: 'Fruit Size 8',
                sort: 'fruit_size_8',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_9' as keyof FieldEstimate,
                title: 'Fruit Size 9',
                sort: 'fruit_size_9',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fruit_size_10' as keyof FieldEstimate,
                title: 'Fruit Size 10',
                sort: 'fruit_size_10',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'reason' as keyof FieldEstimate,
                title: 'Estimate Reason',
                sort: 'reason',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            // {
            //     property: 'fieldEstimate' as keyof FieldEstimate,
            //     title: 'Market % Export',
            //     sort: 'market',
            //     tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            // },
            // {
            //     property: 'fieldEstimate' as keyof FieldEstimate,
            //     title: 'Grade % Choice',
            //     sort: 'choice_percent',
            //     tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'status' as keyof FieldEstimate,
                title: 'Field Notes / Status',
                sort: 'status',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'block_cleaned' as keyof FieldEstimate,
                title: 'Block Cleaned',
                sort: 'block_cleaned',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
            {
                property: 'updated_by' as keyof FieldEstimate,
                title: 'Updated By',
                sort: 'updated_by',
                tdClass: 'px-6 py-4 whitespace-nowrap font-medium text-gray-900',
            },
        ];

        return (
            <div className="block">
                <div className="block-content">
                    <UITable
                        columns={allColumns}
                        rows={record?.fieldEstimate?.fieldEstimateHistory}
                        renderForDisplay={this.renderForDisplay}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(EstimateHistoryTable);

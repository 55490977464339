import { Api } from '@citrus-tracker/api';
import { Grower, User } from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';
import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

interface PathParams {
    userId: string;
    id: string;
}
export interface GrowerProps extends RouteComponentProps<PathParams> {
    user?: User | null;
}

export class GrowerEditComponent extends Component<GrowerProps> {
    props: Readonly<GrowerProps>;

    state: {
        userId: string;
        growerId: string;
        grower: Grower;
        formatSSN: boolean;
        users: User[];
        userOptions: [];
        selectedUser: any;
        showUser: boolean;
    } = {
        userId: '',
        growerId: '',
        grower: {} as Grower,
        formatSSN: false,
        users: [],
        userOptions: [],
        selectedUser: null,
        showUser: false,
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;
        const userId = this.props.match.params.userId;

        this.loadUsers();
        this.setState({ userId });
        if (id !== 'new') {
            this.setState({ growerId: id });
            setTimeout( () => this.loadGrower(id.toString()), 100);
        }
    }

    loadUsers = async (): Promise<void> => {
        const users = await Api.users.listGrowers();
        const userOptions = [];

        for (const user of users) {
            userOptions.push({id: user.id, label: `${user.firstName} ${user.lastName}`});
        }

        this.setState({
            users: users,
            userOptions: userOptions,
        });
    };

    loadGrower = async (growerId: string): Promise<void> => {
        const grower = await Api.growers.get(growerId);

        // @ts-ignore
        const selectedOption = this.state.userOptions.find((user) => user.id === grower.user?.id);

        this.setState({
            formatSSN: grower.ein.split('-').length > 2 ? true : false,
            grower: grower,
            selectedUser: selectedOption ? [selectedOption] : null,
        });
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    //Added an override for the race condition on the radio buttons. The state is not set before this is called.
    setValue = (
        property: keyof Grower,
        value: unknown,
        formatSSNOverride?: boolean,
    ): void => {
        const grower = {
            ...this.state.grower,
        };

        if (property === 'secondaryNumber' || property === 'primaryNumber') {
            value = this.phoneMask(value);
        }

        if (property === 'ein') {
            if (formatSSNOverride ?? this.state.formatSSN) {
                value = this.ssnMask(value);
            } else {
                value = this.einMask(value);
            }
        }

        if (property === 'user') {
            this.setState({ selectedUser: value });
            // @ts-ignore
            if(value[0] && value[0].id){
                // @ts-ignore
                value = this.state.users.find((user) => user.id === value[0].id)?.id ?? null;
            }
        }

        const newGrower = {
            ...grower,
            [property]: value,
        };

        this.setGrower(newGrower);
    };

    setGrower = (grower: Grower): void => {
        this.setState({
            grower,
        });
    };

    delete = async (): Promise<void> => {
        if (
            window.confirm(
                `Are you sure you want to delete ${this.state.grower.name}`,
            ) === true
        ) {
            await Api.growers.delete(this.state.grower.id);
            this.props.history.goBack();
        }
    };

    saveChanges = async (): Promise<void> => {
        const grower = {
            ...this.state.grower,
        };

        if(this.props.user?.isPackingHouseUser()){
            grower.packingHouseId = this.props.user?.packingHouse?.id;
            grower.packingHouseUserId = this.props.user?.id;
        }
        
        this.setGrower(grower);

        await Api.growers.save(grower);
        this.props.history.goBack();
    };

    phoneMask = (value: any): string => {
        if (!value) return value;

        const phone = value.replace(/[^\d]/g, '');

        if (phone.length < 4) return phone;
        if (phone.length < 7) {
            return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
        }
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
            6,
            10,
        )}`;
    };

    ssnMask = (value: any): string => {
        if (!value) return value;

        const ssn = value.replace(/[^\d]/g, '');

        if (ssn.length < 4) return ssn;
        if (ssn.length < 5) {
            return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
        }
        return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
    };

    einMask = (value: any): string => {
        if (!value) return value;

        const ein = value.replace(/[^\d]/g, '');

        if (ein.length < 3) return ein;

        return `${ein.slice(0, 2)}-${ein.slice(2, 9)}`;
    };

    render(): ReactElement {
        const { grower, growerId, formatSSN, userId, selectedUser, showUser } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <div className="text-4xl font-bold text-black">Grower Entity</div>
                </div>
                <div>
                    <form
                        className="w-full max-w-xlg rounded bg-white p-8"
                        id="grower_form"
                    >
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="name"
                                >
                                    Name
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="name"
                                    type="text"
                                    placeholder="Grower Entity Name"
                                    value={grower.name}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'name',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {grower.growerAccounts && grower.growerAccounts.length > 0 &&
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="accounts"
                                    >
                                        Grower Accounts
                                    </label>

                                    {grower.growerAccounts.map((x) => (
                                        <a href={`growerAccounts/${x.id}`} className="block appearance-none w-full text-gray-700 py-1 px-4 pr-8 rounded leading-tight hover:underline">
                                            {x.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        }
                        { this.state.userOptions.length > 0 && this.props.user &&
                            userId === this.props.user.id?.toString() && (
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className={"w-full px-3"}>
                                        { this.props.user.isPackingHouseUser() &&
                                            <div className="float-right inline-flex">
                                                <label className="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox"
                                                        name="showUser"
                                                        checked={showUser === true}
                                                        onClick={(): void => {
                                                            this.setState({
                                                                showUser: !this.state.showUser,
                                                            });
                                                        }}
                                                    />
                                                    <span className="ml-2">Select a current user</span>
                                                </label>
                                            </div>
                                        }
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="user"
                                        >
                                            Grower User
                                        </label>
                                        {(this.props.user.isAdmin() || showUser) && 
                                            <Typeahead
                                                selected={selectedUser}
                                                onChange={(selected): void => {
                                                    this.setValue(
                                                        'user',
                                                        selected,
                                                    )
                                                }}
                                                inputProps={{
                                                    className: "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " + (growerId && this.props.user.isAdmin() && !selectedUser ? 'border-red-500 focus:border-red-500' : ''),
                                                }}
                                                id='user'
                                                options={this.state.userOptions}
                                                highlightOnlyResult={true}
                                                placeholder="Select a Grower User"
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <div className="form-check form-check-inline -mt-1 ml-5 float-right">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            className="form-radio"
                                            name="wellWater"
                                            checked={formatSSN === true}
                                            onClick={(): void => {
                                                this.setState({
                                                    formatSSN: true,
                                                });
                                                this.setValue(
                                                    'ein',
                                                    grower.ein,
                                                    true,
                                                );
                                            }}
                                        />
                                        <span className="ml-2">SSN</span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input
                                            type="radio"
                                            className="form-radio"
                                            name="wellWater"
                                            checked={formatSSN === false}
                                            onClick={(): void => {
                                                this.setState({
                                                    formatSSN: false,
                                                });
                                                this.setValue(
                                                    'ein',
                                                    grower.ein,
                                                    false,
                                                );
                                            }}
                                        />
                                        <span className="ml-2">EIN</span>
                                    </label>
                                </div>
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="ein"
                                >
                                    Tax ID (EIN/SSN)
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="ein"
                                    type="text"
                                    placeholder="Tax Id # (EIN/SSN)"
                                    value={grower.ein}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'ein',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="primaryNumber"
                                >
                                    Primary Phone #
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="primaryNumber"
                                    type="tel"
                                    placeholder="Phone #"
                                    value={grower.primaryNumber}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'primaryNumber',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="secondaryNumber"
                                >
                                    Secondary Phone #
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="secondaryNumber"
                                    type="tel"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    placeholder="Alt Phone #"
                                    value={grower.secondaryNumber}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'secondaryNumber',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="address"
                                >
                                    Address
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="address"
                                    type="text"
                                    placeholder="Street address or P.O. Box"
                                    value={grower.address}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'address',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="address2"
                                    type="text"
                                    placeholder="Apt, Suite, Unit, Building (optional)"
                                    value={grower.address2}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'address2',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="city"
                                >
                                    City
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="city"
                                    type="text"
                                    placeholder="City"
                                    value={grower.city}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'city',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="state"
                                >
                                    State
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="state"
                                    type="text"
                                    placeholder="State"
                                    value={grower.state}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'state',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="zipCode"
                                >
                                    Zip
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="zipCode"
                                    type="text"
                                    placeholder="Zip Code"
                                    value={grower.zipCode}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'zipCode',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </form>

                    <div className="py-2">
                        <Button
                            className="float-right"
                            onClick={this.saveChanges}
                            type="button"
                        >
                            Save Changes
                        </Button>
                        {growerId && growerId !== 'new' && (
                            <Button
                                className="mx-2 float-right"
                                onClick={this.delete}
                                type="button"
                                variant="delete"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GrowerEditComponent);

export class FieldEstimateOptions {
    getSelectOptions(
        property: '',
    ): {
        label: string;
        value: string;
    }[] {
        switch (property) {
            default:
                return [];
        }
    }
}

import { Agreement, AgreementBlocks, Block } from '@citrus-tracker/types';
import { Row } from './blocksTable/blocksTable';

export const assignedBlocksHeaders = [
    { title: 'Block ID', sortable: true },
    { title: 'Block description', sortable: true },
    { title: 'Date Assigned', sortable: true },
    { title: 'Date unassigned', sortable: true },
    { title: 'Acres allocated', sortable: true },
    { title: '', sortable: false },
];
export const blockHeaders = [
    { title: 'Block ID', sortable: true },
    { title: 'Block description', sortable: true },
    { title: 'District', sortable: true },
    { title: 'Total Acres', sortable: true },
    { title: 'Available Acres', sortable: true },
    { title: 'Add acres to agreement', sortable: false },
    { title: 'Crop', sortable: true },
    { title: 'Variety', sortable: true },
    { title: 'Sub-Variety', sortable: true },
    { title: 'Organic', sortable: true },
    { title: 'Plant Year', sortable: true },
    { title: 'Age Range', sortable: true },
    { title: 'Rootstock', sortable: true },
    { title: 'Trees/Acre', sortable: true },
    { title: 'Trees', sortable: true },
];

export const getBlockRows = (blocks: Block[], addAcres: any): Row[] => {
    const res: Row[] = blocks.map(
        (el: Block): Row => {
            return {
                line: [
                    {
                        information: el.block ?? '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.block_description,
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.district,
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information:
                            '' +
                            (el.total_acres
                                ? parseFloat(el.total_acres)
                                : 0
                            ).toFixed(2),
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information:
                            el.total_acres && el.assigned_acres
                                ? (
                                      parseFloat(el.total_acres) -
                                      parseFloat(el.assigned_acres)
                                  ).toFixed(2) + ''
                                : '' +
                                  (el.total_acres
                                      ? parseFloat(el.total_acres)
                                      : 0
                                  ).toFixed(2),
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: 'AddAcres',
                        onClickAction: (value, id) => {
                            addAcres(id, value);
                        },
                    },
                    {
                        information: el.crop?.crop + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.variety?.variety + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.subVariety?.sub_variety + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.organic + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.plant_year + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.plantAge?.age + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information: el.rootstock?.rootstock + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information:
                            parseInt(
                                el.trees_per_acre
                                    ? el.trees_per_acre.toString()
                                    : '0',
                            ) + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                    {
                        information:
                            parseInt(el.trees ? el.trees.toString() : '0') + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.id}`;
                        },
                    },
                ],
                rowId: el.id + '',
            };
        },
    );
    return res;
};

export const getAssignedBlocksRows = (
    blocks: AgreementBlocks[],
    deleteFunction: any,
): Row[] => {
    const res: Row[] = blocks.map(
        (el: AgreementBlocks): Row => {
            return {
                line: [
                    {
                        information:
                            el.block?.block + '' ?? 'could not load name',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.block?.id}`;
                        },
                    },
                    {
                        information:
                            el.block?.block_description ||
                            'could not load description',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.block?.id}`;
                        },
                    },
                    {
                        information: '' + el.startDate,
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.block?.id}`;
                        },
                    },
                    {
                        information: el.endDate ? el.endDate + '' : 'open',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.block?.id}`;
                        },
                    },
                    {
                        information: el.blocksAllocated
                            ? parseFloat(el.blocksAllocated.toString()).toFixed(
                                  2,
                              )
                            : 0.0 + '',
                        onClickAction: () => {
                            window.location.href = `/blocks/edit/${el.block?.id}`;
                        },
                    },
                    {
                        information: 'Remove',
                        onClickAction: () => {
                            deleteFunction(el.id);
                        },
                    },
                ],
                rowId: el.id + '',
            };
        },
    );
    return res;
};

import React from 'react';
import { DynamicIcon } from '../dynamic-icon/dynamic-icon';

import './button.module.scss';

/* eslint-disable-next-line */
export interface ButtonProps {
    children?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    type?: 'submit' | 'reset' | 'button';
    ref?: React.RefObject<HTMLButtonElement>;
    icon?: string;
    disabled?: boolean;
    variant?: string;
    style?: object;
    title?: string;
    className?: string;
    name?: string;
    id?: string;
    value?: string;
}

export function Button(props: ButtonProps) {
    let classes =
        'inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
    if (props.variant === 'secondary') {
        if (props.disabled) {
            classes =
                classes +
                ' border-gray-300 text-gray-700 bg-gray-200 hover:bg-gray-50 cursor-default';
        } else {
            classes =
                classes +
                ' border-gray-300 text-gray-700 bg-white hover:bg-gray-50';
        }
    } else if (props.variant === 'exit-next') {
        if (props.disabled) {
            classes = 
                classes + 
                ' border-transparent text-white bg-gray-200 hover:bg-gray-50 cursor-default';
        } else {
            classes = 
                classes + 
                'border-green-600 bg-green-600 border-8 text-white hover:bg-green-700 hover:text-white';
        }
    } else if (props.variant === 'exit-no-save') {
        if (props.disabled) {
            classes = 
                classes +
                'border-gray-300 text-gray-700 bg-white hover:bg-gray-50';
        } else {
            classes = 
                classes + 
                'border-transparent text-white bg-red-600 hover:bg-red-700';
        }
    } else if (props.variant === 'save-and-exit') {
        if (props.disabled) {
            classes = 
                classes +
                'border-transparent text-white bg-indigo-400 cursor-default';
        } else {
            classes = 
                classes +
                'border-indigo-600 bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white';
        }
    } else if (props.variant === 'delete') {
        if (props.disabled) {
            classes =
                classes +
                ' border-transparent text-white bg-gray-200 hover:bg-gray-50 cursor-default';
        } else {
            classes =
                classes +
                ' border-transparent text-white bg-red-600 hover:bg-red-700';
        }
    } else {
        if (props.disabled) {
            classes =
                classes +
                ' border-transparent text-white bg-indigo-400 cursor-default';
        } else {
            classes =
                classes +
                ' border-transparent text-white bg-indigo-600 hover:bg-indigo-700';
        }
    }
    if (props.children) {
        classes = classes + ' px-4';
    } else {
        classes = classes + ' px-2';
    }
    if (props.className) {
        classes = classes + ' ' + props.className;
    }
    return (
        <button
            {...props}
            type={props.type ? props.type : 'button'}
            className={classes}
        >
            {props.icon && (
                <DynamicIcon
                    className={props.children ? 'h-5 w-5 mr-2' : 'h-5 w-5'}
                    icon={props.icon}
                />
            )}
            {props.children}
        </button>
    );
}

export default Button;

import React, {
    ChangeEvent,
    Component,
    Fragment,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import './table.scss';

import { Api } from '@citrus-tracker/api';
import { Input, Select } from '@citrus-tracker/layout/form';
import {
    getFlatBlockValue,
    Block,
    BlockFilter,
    BlockFilterOptions,
    BlockOptions,
    User,
} from '@citrus-tracker/types';
import BulkEditBlock from '../edit/bulk-edit';
import Filter, { FilterColumn } from '../filter/filter';
import { Button, Table as UITable, Modal } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    growerId: string;
    currentBlockIndex: number;
    filter: BlockFilter;
    filterOptions: BlockFilterOptions;
    blocks: Block[];
    blockOptions: BlockOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalBlocks: number;
    loadBlocks(
        growerId: string,
        page: number,
        blocksPerPage: number,
    ): Promise<void>;
    updateFilter(filter: BlockFilter): void;
    user?: User;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        bulkEditBlocks: Block[];
        deleteOnImport: boolean;
        downloadDropdown: boolean;
        editingBlock?: Block | null;
        fileToImport?: File;
        importError: string;
        importLoading: boolean;
        page: number;
        blocksPerPage: number;
        selectedForBulkEdit: number[];
        showImport: boolean;
        blockVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        bulkEditBlocks: [],
        deleteOnImport: false,
        downloadDropdown: false,
        importError: '',
        importLoading: false,
        page: 1,
        blocksPerPage: 15,
        selectedForBulkEdit: [],
        showImport: false,
        blockVisibleColumns: {
            block_description: false,
            district: true,
            block: true,
            ranch: true,
            apn: true,
            ein: true,
            grower: true,
            organic: true,
            acres: true,
            trees: true,
            trees_per_acre: true,
            plant_year: true,
            google_maps_url: true,
            status: true,
            updated_by: true,
            last_updated: true,
            date_created: true,
            agreementBlocks: true,
            crop: true,
            variety: true,
            subVariety: true,
            plantAge: true,
            rootstock: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentBlock();

        const columnString = localStorage.getItem('blockVisibleColumns');
        if (columnString) {
            this.setState({ blockVisibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );

        const page = localStorage.getItem('blockPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentBlockIndex } = this.props;

        if (
            currentBlockIndex &&
            (!oldProps.blocks ||
                currentBlockIndex !== oldProps.currentBlockIndex)
        ) {
            this.goToCurrentBlock();
        }
    }

    goToCurrentBlock = (): void => {
        const { currentBlockIndex, blocks } = this.props;

        if (blocks) {
            if (currentBlockIndex) {
                this.goToBlock(currentBlockIndex);
            } else {
                this.goToBlock(1);
            }
        }
    };

    confirmImport = async (): Promise<void> => {
        const { deleteOnImport, fileToImport } = this.state;
        const { growerId } = this.props;

        if (!fileToImport) {
            return;
        }

        this.setState({ importError: '', importLoading: true });

        try {
            const success = await Api.blocks.import(
                fileToImport,
                deleteOnImport,
            );
            if (success) {
                this.setState(
                    {
                        fileToImport: null,
                        importLoading: false,
                        showImport: false,
                    },
                    () => {
                        const { filter, loadBlocks, updateFilter } = this.props;
                        const { page, blocksPerPage } = this.state;
                        loadBlocks(growerId, page, blocksPerPage);
                        updateFilter(filter);
                    },
                );
            } else {
                this.setState({
                    importError: 'There was a problem parsing the file',
                    importLoading: false,
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            this.setState({
                importError: error.message ?? '',
                importLoading: false,
            });
        }
    };

    goToPage = (newPage: number): void => {
        const { loadBlocks, growerId } = this.props;
        const { blocksPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadBlocks(growerId, newPage, blocksPerPage);
        
        //Store page in session so we can persist across page reloads
        localStorage.setItem('blockPage', JSON.stringify(newPage));
    };

    goToBlock = (blockIndex: number): void => {
        const { blocksPerPage } = this.state;

        if (blocksPerPage) {
            const page = localStorage.getItem('blockPage') ?? Math.ceil(blockIndex / blocksPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    inlineEdit = async (blockId: number | null | undefined): Promise<void> => {
        const { loadBlocks, growerId } = this.props;
        const { page, blocksPerPage } = this.state;

        await loadBlocks(growerId, page, blocksPerPage);

        if (blockId) {
            const { blocks } = this.props;

            for (const block of blocks) {
                if (block.id === blockId) {
                    this.setState({ editingBlock: block });
                }
            }
        } else {
            this.setState({ editingBlock: null });
        }
    };

    inlineUpdate = async (
        blockId: number | undefined,
        property: keyof Block,
        newValue: string | number | { id: number } | null,
    ): Promise<void> => {
        if (!newValue) {
            newValue = null;
        }
        if (blockId && property) {
            await Api.blocks.save(({
                id: blockId,
                [property]: newValue,
            } as unknown) as Block);

            const { editingBlock } = this.state;
            if (editingBlock && editingBlock.id === blockId) {
                this.setState({
                    editingBlock: { ...editingBlock, [property]: newValue },
                });
            }
        }
    };

    openBulkEdit = (): void => {
        const { blocks } = this.props;
        const { selectedForBulkEdit } = this.state;

        const bulkEditBlocks = blocks.filter(
            (block) =>
                block.id !== undefined &&
                selectedForBulkEdit.indexOf(block.id) >= 0,
        );
        this.setState({ bulkEditBlocks });
    };

    closeBulkdEdit = (): void => {
        this.setState({ bulkEditBlocks: [] });
    };

    openImport = (): void => {
        this.setState({
            importError: '',
            showImport: true,
        });
    };

    openNew = (): void => {
        this.props.history.push(`/blocks/new`);
    };

    openEdit = (blockIndex?: number): void => {
        if (blockIndex && this.props.growerId) {
            this.props.history.push(
                `/blocks/${this.props.growerId}/edit/${blockIndex}`,
            );
        } else if (blockIndex) {
            this.props.history.push(`/blocks/edit/${blockIndex}`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    selectFileToImport = (e: ChangeEvent<HTMLInputElement>): void => {
        const files = e.target.files;

        if (files && files.length > 0) {
            this.setState({ fileToImport: files[0] });
        }
    };

    toggleColumnVisibility = (property: keyof Block): void => {
        const blockVisibleColumns = {
            ...this.state.blockVisibleColumns,
            [property]: !this.state.blockVisibleColumns[property],
        };

        this.setState({
            blockVisibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('blockVisibleColumns', JSON.stringify(blockVisibleColumns));
    };

    packingHouseDisplay = (block: Block): string => {
        const packingHouseNames = block?.agreementBlocks?.map(
            (agreementBlock) => {
                return agreementBlock.agreement?.packingHouse
                    ?.packing_house_name;
            },
        );
        const uniquePackingHouseNames = [...new Set(packingHouseNames)];

        return uniquePackingHouseNames?.join(', ') ?? '';
    };

    renderForDisplay = (block: Block, property: keyof Block): ReactElement => {
        switch (property) {
            case 'crop':
                return <span>{block.crop && block.crop.crop}</span>;
            case 'agreementBlocks':
                return (
                    <span title={this.packingHouseDisplay(block)}>
                        {this.packingHouseDisplay(block)}
                    </span>
                );
            case 'apn':
                return <span>{block.apn}</span>;
            case 'ein':
                return <span>{block.grower && block.grower.ein}</span>;
            case 'grower':
                return <span>{block.grower && block.grower.name}</span>;
            case 'ranch':
                return <span>{block.ranch && block.ranch.ranchName}</span>;
            case 'plantAge':
                return <span>{block.plantAge && block.plantAge.age}</span>;
            case 'rootstock':
                return (
                    <span>{block.rootstock && block.rootstock.rootstock}</span>
                );
            case 'subVariety':
                return (
                    <span>
                        {block.subVariety && block.subVariety.sub_variety}
                    </span>
                );
            case 'trees':
                return (
                    <span>
                      {
                        block.trees && new Intl.NumberFormat()
                          .format(Math.round(block.trees))
                          .toString()
                      }
                    </span>
                );
            case 'trees_per_acre':
                return (
                    <span>
                      {
                        block.trees_per_acre && new Intl.NumberFormat()
                          .format(Math.round(block.trees_per_acre))
                          .toString()
                      }
                    </span>
                );
            case 'variety':
                return <span>{block.variety && block.variety.variety}</span>;
            default:
                return <span>{block[property]}</span>;
        }
    };

    renderForEdit = (block: Block, property: keyof Block): ReactElement => {
        const { blockOptions } = this.props;

        const selectedCropId =
            block && block.crop && block.crop.id ? block.crop.id : null;
        const selectedVarietyId =
            block && block.variety && block.variety.id
                ? block.variety.id
                : null;
        if (selectedCropId) {
            for (const crop of blockOptions.crops) {
                if (crop.id === selectedCropId) {
                    blockOptions.selectedCrop = crop;

                    if (
                        selectedVarietyId &&
                        blockOptions.selectedCrop.varieties
                    ) {
                        for (const variety of blockOptions.selectedCrop
                            .varieties) {
                            if (variety.id === selectedVarietyId) {
                                blockOptions.selectedVariety = variety;
                            }
                        }
                    }
                }
            }
        }

        switch (property) {
            case 'grower':
                return <span>{block.grower && block.grower.name}</span>;
            case 'agreementBlocks':
                return <span>{this.packingHouseDisplay(block)}</span>;
            case 'apn':
                return <span>{block.apn}</span>;
            case 'ein':
                return <span>{block.grower?.ein}</span>;
            case 'ranch':
                return <span>{block.ranch?.ranchName}</span>;
            case 'block_description':
                return <span>{block[property]}</span>;
            case 'total_acres':
            case 'trees_per_acre':
                return (
                    <span>
                        <Input
                            id={property}
                            type="number"
                            step="0.01"
                            value={getFlatBlockValue(block, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(block?.id, property, newValue)
                            }
                        />
                    </span>
                );
            case 'plant_year':
                return (
                    <span>
                        <Input
                            id="plant_year"
                            type="number"
                            min="1900"
                            max={new Date().getFullYear()}
                            value={getFlatBlockValue(block, 'plant_year')}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    block?.id,
                                    'plant_year',
                                    newValue,
                                )
                            }
                        />
                    </span>
                );
            case 'trees':
                return (
                    <span>
                        <Input
                            id="trees"
                            disabled={!!block?.trees_per_acre}
                            type="number"
                            value={getFlatBlockValue(block, 'trees')}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(block?.id, 'trees', newValue)
                            }
                        />
                    </span>
                );
            // Selects
            case 'district':
            case 'organic':
                return (
                    <span>
                        <Select
                            id={property}
                            options={blockOptions.getSelectOptions(property)}
                            value={getFlatBlockValue(block, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(block?.id, property, newValue)
                            }
                        />
                    </span>
                );
            // Related objects
            case 'crop':
            case 'variety':
            case 'subVariety':
            case 'rootstock':
                return (
                    <span>
                        <Select
                            id={property}
                            options={blockOptions.getSelectOptions(property)}
                            value={getFlatBlockValue(block, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    block?.id,
                                    property,
                                    newValue
                                        ? {
                                              id: parseInt(newValue),
                                          }
                                        : null,
                                )
                            }
                        />
                    </span>
                );
            case 'plantAge':
                return (
                    <span>
                        <Select
                            id={property}
                            disabled={block.plant_year}
                            options={blockOptions.getSelectOptions(property)}
                            value={getFlatBlockValue(block, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    block?.id,
                                    property,
                                    newValue
                                        ? {
                                              id: parseInt(newValue),
                                          }
                                        : null,
                                )
                            }
                        />
                    </span>
                );
            default:
                return (
                    <span>
                        <Input
                            id={property}
                            value={getFlatBlockValue(block, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(block?.id, property, newValue)
                            }
                        />
                    </span>
                );
        }
    };

    toggleSelectedForBulkEdit = (blockId: number | undefined): void => {
        if (blockId === undefined) {
            return;
        }

        const { selectedForBulkEdit } = this.state;

        const i = selectedForBulkEdit.indexOf(blockId);
        if (i >= 0) {
            selectedForBulkEdit.splice(i, 1);
        } else {
            selectedForBulkEdit.push(blockId);
        }

        this.setState({ selectedForBulkEdit });
    };

    toggleAllSelectedForBulkEdit = (selected: boolean): void => {
        const { blocks } = this.props;

        let selectedForBulkEdit: number[] = [];
        if (selected) {
            selectedForBulkEdit = blocks.map((block) => block.id || 0);
        }

        this.setState({ selectedForBulkEdit });
    };

    bulkEditFinished = (): void => {
        const { loadBlocks, growerId } = this.props;
        const { page, blocksPerPage } = this.state;

        loadBlocks(growerId, page, blocksPerPage);

        this.setState({ bulkEditBlocks: [] });
    };

    render(): ReactElement {
        const {
            blocks,
            blockOptions,
            totalBlocks,
            filter,
            filterOptions,
            growerId,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            user,
        } = this.props;
        const {
            bulkEditBlocks,
            deleteOnImport,
            editingBlock,
            fileToImport,
            importError,
            importLoading,
            page,
            blocksPerPage,
            selectedForBulkEdit,
            showImport,
            blockVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalBlocks / blocksPerPage);

        const indexBase = (page - 1) * blocksPerPage;

        const allColumns: {
            property: keyof Block;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'agreementBlocks' as keyof Block,
                title: 'Packing House',
                sort: 'block.block',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'apn' as keyof Block,
                title: 'APN',
                sort: 'block.apn',
                tdClass:
                    'px-6 py-4 whitespace-nowrap max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'ein' as keyof Block,
                title: 'EIN',
                sort: 'block.ein',
                tdClass:
                    'px-6 py-4 whitespace-nowrap max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'total_acres' as keyof Block,
                title: 'Planted Acres',
                sort: 'block.total_acres',
            },
            {
                property: 'block' as keyof Block,
                title: 'Block ID',
                sort: 'block.block',
            },
            {
                property: 'ranch' as keyof Block,
                title: 'Ranch',
                sort: 'block.ranch',
            },
            {
                property: 'grower' as keyof Block,
                title: 'Entity',
                sort: 'block.grower.name',
            },
            {
                property: 'block_description' as keyof Block,
                title: 'Block Description',
                sort: 'block.block_description',
            },
            {
                property: 'district' as keyof Block,
                title: 'District',
                filter: true,
                sort: 'block.district',
            },
            {
                property: 'crop' as keyof Block,
                title: 'Crop',
                filter: true,
                sort: 'crop.crop',
            },
            {
                property: 'variety' as keyof Block,
                title: 'Sunkist Marketing Variety',
                filter: true,
                sort: 'variety.variety',
            },
            {
                property: 'subVariety' as keyof Block,
                title: 'Sub-Variety',
                filter: true,
                sort: 'subVariety.sub_variety',
            },
            {
                property: 'organic' as keyof Block,
                title: 'Organic',
                sort: 'block.organic',
            },
            {
                property: 'plant_year' as keyof Block,
                title: 'Plant Year',
                sort: 'block.plant_year',
            },
            {
                property: 'plantAge' as keyof Block,
                title: 'Age Range',
                //for some reason block.plantAge and block.plant_age_id don't sore properly, this does
                sort: 'block.plant_year',
            },
            {
                property: 'rootstock' as keyof Block,
                title: 'Rootstock',
                filter: true,
                sort: 'block.rootstock',
            },
            {
                property: 'trees_per_acre' as keyof Block,
                title: 'Trees/Acre',
                sort: 'block.trees_per_acre',
            },
            {
                property: 'trees' as keyof Block,
                title: 'Trees',
                //for some reason block.trees does not sort in an expected way, but this seems to work
                sort: 'block.trees_per_acre * block.acres',
            },
        ];

        //Don't show Packing House column to non-admins
        if (!user?.isAdmin()) {
            allColumns.shift();
        }

        const columns = allColumns.filter(
            (column) => blockVisibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        if (growerId === '' && window.location.pathname !== '/blocks') {
            return (
                <div className="block">
                    <div className="block-content"></div>
                </div>
            );
        }

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        growerId={growerId}
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        openBulkEdit={
                            selectedForBulkEdit.length > 0
                                ? this.openBulkEdit
                                : undefined
                        }
                        openNew={this.openNew}
                        openImport={this.openImport}
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={user?.isAdmin()}
                        visibleColumns={blockVisibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={blocks}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                        //bulk edit
                        toggleSelectedForBulkEdit={
                            this.toggleSelectedForBulkEdit
                        }
                        toggleAllSelectedForBulkEdit={
                            this.toggleAllSelectedForBulkEdit
                        }
                        selectedForBulkEdit={selectedForBulkEdit}
                        //inline edit
                        inlineEdit={this.inlineEdit}
                        editingRow={editingBlock}
                        renderForEdit={this.renderForEdit}
                        currentUser={user}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                                Showing{' '}
                                {totalBlocks === 0
                                    ? 0
                                    : (page - 1) * blocksPerPage + 1}{' '}
                                to{' '}
                                {totalBlocks > blocksPerPage
                                    ? page * blocksPerPage
                                    : totalBlocks}{' '}
                                of {totalBlocks} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={
                                            totalBlocks > 0
                                                ? Math.ceil(
                                                      totalBlocks /
                                                          blocksPerPage,
                                                  )
                                                : 1
                                        }
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    title="Import blocks from a .csv file"
                    show={showImport}
                    close={(): void =>
                        this.setState({
                            showImport: false,
                        })
                    }
                    content={
                        <Fragment>
                            {' '}
                            <div className="block-content">
                                <label htmlFor="deleteOnImport">
                                    <input
                                        type="checkbox"
                                        id="deleteOnImport"
                                        checked={deleteOnImport}
                                        onChange={(): void =>
                                            this.setState({
                                                deleteOnImport: !deleteOnImport,
                                            })
                                        }
                                    />{' '}
                                    Delete blocks not in the file
                                </label>

                                <input
                                    type="file"
                                    onChange={this.selectFileToImport}
                                    className="mt-4"
                                />
                            </div>
                            {!!importError && (
                                <div className="block-content">
                                    <div className="alert alert-error text-center">
                                        <strong>{importError}</strong>
                                    </div>
                                </div>
                            )}
                            {importLoading && (
                                <div className="block-content">Processing</div>
                            )}
                            <Button
                                onClick={(): void => {
                                    this.confirmImport();
                                }}
                                type="button"
                                data-dismiss="modal"
                                disabled={!fileToImport || importLoading}
                                className="float-right mt-4"
                            >
                                Import
                            </Button>
                            <Button
                                type="button"
                                onClick={(): void =>
                                    this.setState({
                                        showImport: false,
                                    })
                                }
                                data-dismiss="modal"
                                disabled={importLoading}
                                variant="secondary"
                                className="mr-4 float-right mt-4"
                            >
                                Cancel
                            </Button>
                        </Fragment>
                    }
                />
                <Modal
                    title="Edit all selected blocks"
                    show={bulkEditBlocks.length > 0}
                    close={this.closeBulkdEdit}
                    content={
                        <Fragment>
                            {' '}
                            <div className="block-content">
                                <BulkEditBlock
                                    onFinished={this.bulkEditFinished}
                                    blocks={bulkEditBlocks}
                                    blockOptions={blockOptions}
                                />
                            </div>
                        </Fragment>
                    }
                />
            </div>
        );
    }
}

export default withRouter(Table);

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './summary.scss';
import { Record } from '@citrus-tracker/types';

export interface TableProps extends RouteComponentProps {
    record: Record;
    cartonsPerBin: number;
    fruitSizes: number[];
}

export class Summary extends Component<TableProps> {
    props: Readonly<TableProps>;

    state: {
        editingRecord: Record;
    } = {
        editingRecord: {},
    };

    componentDidMount(): void {
        this.setState({ editingRecord: this.props.record });
    }

    calcTotal(): number {
        const { editingRecord } = this.state;
        return (
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_1 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_2 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_3 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_4 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_5 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_6 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_7 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_8 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_9 ?? 0).toString(),
            ) +
            parseInt(
                (editingRecord.fieldEstimate?.fruit_size_10 ?? 0).toString(),
            )
        );
    }

    calcCartons(): string {
        const { editingRecord } = this.state;
        const { cartonsPerBin } = this.props;
        return ((((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0});
    }

    render(): ReactElement {
        const { editingRecord } = this.state;
        const { cartonsPerBin, fruitSizes } = this.props;

        
        return (
                <div
                    className='overflow-hidden grid grid-flow-rows grid-cols-3 grid-rows-2 gap-x-16 gap-y-6 mb-4'>
                    <table>
                        <thead className='bg-gray-50 text-xs text-gray-500 h-10 divide-y divide-gray-200'>
                            <tr>
                                <th className='text-left pl-2'>ESTIMATED</th>
                                <th className='text-center'>SEASON</th>
                                <th className='text-right pr-2'>Picked</th>
                            </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200'>
                            <tr className='bg-white text-xs h-8'>
                                <td className='pl-2 bg-white text-left'>
                                    <strong>Bins</strong>
                                </td>
                                <td className='text-center'>{((editingRecord.fieldEstimate?.total_bins ?? 0)*1).toLocaleString(undefined, {maximumFractionDigits:0})}</td>
                                <td className='text-right pr-2'>{editingRecord.fieldEstimate?.picked_to_date ?? 0}</td>
                            </tr>
                            <tr className='bg-white text-xs h-8'>
                                <td className='pl-2 bg-white text-left'>
                                    <strong>Est. Fresh Cartons</strong>
                                </td>
                                <td className='bg-white text-center'>
                                    {(((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className='text-right pr-2'>{((editingRecord.fieldEstimate?.picked_to_date ?? 0) * cartonsPerBin).toLocaleString(undefined, {maximumFractionDigits:0})}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead className='bg-gray-50 text-xs text-gray-500 h-10 divide-y divide-gray-200'>
                            <tr>
                                <th className='text-left pl-2'>CARTONS</th>
                                <th>%</th>
                                <th>SEASON</th>
                                <th className='text-right pr-2'>PICKED</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            <tr className='bg-white text-xs h-8'>
                                <td className='pl-2 bg-white text-left'>
                                    <strong>Export</strong>
                                </td>
                                <td className='text-center'>{((editingRecord.fieldEstimate?.export_percent ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className='text-center'>
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        ((editingRecord.fieldEstimate
                                            ?.export_percent ?? 0) /
                                            100) * (editingRecord.fieldEstimate?.utilization ?? 0) / 100 )
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className='text-right pr-2'>
                                    {(
                                        (editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                        cartonsPerBin *
                                        ((editingRecord.fieldEstimate
                                            ?.export_percent ?? 0) /
                                            100)
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className='bg-white text-xs h-8'>
                                <td className='text-left pl-2'>
                                    <strong>Domestic</strong>
                                </td>
                                <td className='text-center'>{100 - (editingRecord.fieldEstimate?.export_percent ?? 0)}%</td>
                                <td className='text-center'>
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        ((100 -
                                            (editingRecord.fieldEstimate
                                                ?.export_percent ?? 0)) /
                                            100) * (editingRecord.fieldEstimate?.utilization ?? 0) / 100 )
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className='text-right pr-2'>
                                    {(
                                        (editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                        cartonsPerBin *
                                        ((100 -
                                            (editingRecord.fieldEstimate
                                                ?.export_percent ?? 0)) /
                                            100)
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead className='bg-gray-50 text-xs text-gray-500 h-10 divide-y divide-gray-200'>
                            <tr>
                                <th className='text-left pl-2'>CARTONS</th>
                                <th>%</th>
                                <th>SEASON</th>
                                <th className='text-right pr-2'>PICKED</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            <tr className='bg-white text-xs h-8'>
                                <td className='text-left pl-2'>
                                    <strong>Fancy</strong>
                                </td>
                                <td className='text-center'>{((editingRecord.fieldEstimate?.fancy_percent ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className='text-center'>
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        ((editingRecord.fieldEstimate
                                            ?.fancy_percent ?? 0) /
                                            100) * (editingRecord.fieldEstimate?.utilization ?? 0) / 100 )
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className='text-right pr-2'>
                                    {(
                                        (editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                        cartonsPerBin *
                                        ((editingRecord.fieldEstimate
                                            ?.fancy_percent ?? 0) /
                                            100)
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className='bg-white text-xs h-8'>
                                <td className='text-left pl-2'>
                                    <strong>Choice</strong>
                                </td>
                                <td className='text-center'>{100 - (editingRecord.fieldEstimate?.fancy_percent ?? 0)}%</td>
                                <td className='text-center'>
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        ( (100 - (editingRecord.fieldEstimate
                                            ?.fancy_percent ?? 0)) /
                                            100) * (editingRecord.fieldEstimate?.utilization ?? 0) / 100 )
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className='text-right pr-2'>
                                    {(
                                        (editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                        cartonsPerBin *
                                        (100 - (editingRecord.fieldEstimate
                                            ?.fancy_percent ?? 0) /
                                            100)
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='col-span-3'>
                    <thead className='bg-gray-50 text-xs text-gray-500 h-10 divide-y divide-gray-200'>
                            <tr>
                                <th className='text-left pl-2 w-72'>SIZES</th>
                                <th className='text-center'>{fruitSizes[0]}</th>
                                <th className='text-center'>{fruitSizes[1]}</th>
                                <th className='text-center'>{fruitSizes[2]}</th>
                                <th className='text-center'>{fruitSizes[3]}</th>
                                <th className='text-center'>{fruitSizes[4]}</th>
                                <th className='text-center'>{fruitSizes[5]}</th>
                                <th className='text-center'>{fruitSizes[6]}</th>
                                <th className='text-center'>{fruitSizes[7]}</th>
                                <th className='text-center'>{fruitSizes[8]}</th>
                                <th className='text-center'>{fruitSizes[9]}</th>
                                <th className='text-right pr-2 w-36'>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            <tr className='bg-white text-xs h-8'>
                                <td className="ml-2 bg-white text-left text-xs pl-2"><strong>%</strong></td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_1 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_2 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_3 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_4 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_5 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_6 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_7 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_8 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_9 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-center text-xs">{((editingRecord.fieldEstimate?.fruit_size_10 ?? 0) * 1).toLocaleString(undefined, {maximumFractionDigits:0})}%</td>
                                <td className="bg-white text-right text-xs pr-2">{this.calcTotal()}%</td>
                            </tr>
                            <tr className='bg-white text-xs h-8'>
                                <td className="ml-2 bg-white text-left text-xs pl-2">
                                    <strong>Est. Fresh Cartons</strong>
                                </td>
                                <td className="ml-2 bg-white text-center text-xs pl-2">{(((((editingRecord.fieldEstimate?.fruit_size_1 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_2 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_3 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_4 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_5 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_6 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_7 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_8 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_9 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-center text-xs">{(((((editingRecord.fieldEstimate?.fruit_size_10 ?? 0) / 100) * ((editingRecord.fieldEstimate?.total_bins ?? 0) * cartonsPerBin) * (editingRecord.fieldEstimate?.utilization ?? 0)/100)).toLocaleString(undefined, {maximumFractionDigits:0}))}</td>
                                <td className="bg-white text-right text-xs pr-2">{this.calcCartons()}</td>
                            </tr>
                        </tbody>
                    </table>        
                </div>
        )
    }
}

export default withRouter(Summary);
import React, { Component, Fragment, ReactElement } from 'react';

import './select.module.scss';

/* eslint-disable-next-line */
export interface SelectProps {
    id: string;
    label?: string;
    onChange(newValue: string): unknown;
    options: {
        label: string;
        value: string | number;
    }[];
    value: string | number;

    [key: string]: unknown;
}

export class Select extends Component<SelectProps> {
    render(): ReactElement {
        const {
            id,
            label,
            onChange,
            options,
            value,
            ...additionalProps
        } = this.props;

        return (
            <Fragment>
                {label && (
                    <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor={id}
                    >
                        {label}:{' '}
                    </label>
                )}
                <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 my-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={id}
                    onChange={(e): unknown => onChange(e.target.value)}
                    value={value.toString()}
                    {...additionalProps}
                >
                    <option value="">
                        {label && `Please Select ${label}`}
                    </option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </Fragment>
        );
    }
}

export default Select;

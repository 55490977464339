import React, { ReactChild, ReactElement } from 'react';

import './layout-form.module.scss';

/* eslint-disable-next-line */
export interface FormProps {
    children: ReactChild | null | (ReactChild | null)[];
    id?: string;
    onSubmit(): unknown;

    [key: string]: unknown;
}

export function Form(props: FormProps): ReactElement {
    const { children, id, onSubmit, ...additionalProperties } = props;
    return (
        <form id="wizardForm" onSubmit={onSubmit} {...additionalProperties}>
            {children}
        </form>
    );
}

export default Form;

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Api } from '@citrus-tracker/api';
import { User, Ranch, RanchOptions, RanchFilter, RanchFilterOptions, Agreement } from '@citrus-tracker/types';

import Table from './table';

import './ranches.module.scss';
const ranchesPerPage = 15;

interface PathParams {
    growerId: string;
}

/* eslint-disable-next-line */
export interface RanchProps extends RouteComponentProps<PathParams> {
    user?: User | null;
    agreement?: Agreement | null;
}

export class RanchComponent extends Component<RanchProps> {
    props: Readonly<RanchProps>;

    state: {
        growerId: string;
        currentRanchIndex: number;
        errorAlert: string;
        filter: RanchFilter;
        filterOptions: RanchFilterOptions;
        ranches: Ranch[];
        ranchOptions: RanchOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalRanches: number;
        page: number;
    } = {
        growerId: '',
        currentRanchIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        ranches: [],
        ranchOptions: {},
        sort: 'ranch.id',
        sortDirection: 'DESC',
        successAlert: '',
        totalRanches: 0,
        page: 1,
    };

    componentDidMount(): void {
        const id = this.props?.match?.params.growerId;

        if (id) {
            this.setState({ growerId: id });
            this.loadRanches(id.toString());
        } else {
            this.loadRanches();
        }

        
        this.updateFilter({});
    }

    loadRanches = async (
        growerId = '',
        page = 1,
        ranchesPerPage = 15,
    ): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        if(growerId === ''){
            growerId = this.state.growerId;
        }

        if(window.location.href.includes('agreements')){
            growerId = this.props.agreement?.grower?.id.toString() ?? '';
        }

        const ranches = await Api.ranches.list(
            growerId,
            page,
            ranchesPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalRanches: ranches.total,
            ranches: ranches.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadRanches(this.state.growerId);
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadRanches(this.state.growerId);
            });
        }
    };
    setCurrentRanchIndex = (currentRanchIndex: number): void => {
        this.setState({ currentRanchIndex });
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadRanches(this.state.growerId);
            });
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.loadRanches(
            this.state.growerId,
            data.selected + 1,
            ranchesPerPage,
        );
        this.setState({ page: data.selected + 1 });
    };

    updateFilter = (filter: RanchFilter): void => {
        window.localStorage.setItem('ranchFilters', JSON.stringify(filter));

        this.setState({ filter }, () => {
            this.updateFilterOptions();
        });
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter, growerId } = this.state;
        const filterOptions = await Api.ranches.getFilterOptions(filter, growerId);
        this.setState({ filterOptions }, () => {
            this.loadRanches(this.state.growerId);
        });
    };

    render(): ReactElement {
        const { 
            currentRanchIndex,
            ranches, 
            totalRanches, 
            page, 
            growerId,
            filter,
            filterOptions,
            ranchOptions,
            sort,
            sortDirection, 
        } = this.state;
        const { user } = this.props;
        return (
            <div className="px-8">
                <div className="flex justify-between items-center flex-col mb-2 sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Ranches
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            { !window.location.href.includes('agreements') && user?.isAdmin() &&
                                <Link
                                    to={`/growers/${growerId}`}
                                    className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                >
                                    Entities
                                </Link> 
                            }
                            {growerId && ranches && ranches.length > 0 && (
                                <Link
                                    to={`/blocks/${growerId}`}
                                    className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                >
                                    Blocks
                                </Link>
                            )}
                            {growerId &&
                                ranches &&
                                ranches.length > 0 &&
                                ranches[0].blocks &&
                                ranches[0].blocks.length > 0 && (
                                    <Link
                                        to={`/agreements/add/${growerId}`}
                                        className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                    >
                                        New Agreement
                                    </Link>
                                )}
                            <Link
                                to={`/ranches/${
                                    growerId ? growerId : user?.id
                                }/edit/new`}
                                className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right ml-4"
                            >
                                <div className="mr-3">+</div>
                                <div>New</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <Table
                        currentRanchIndex={currentRanchIndex}
                        filter={filter}
                        filterOptions={filterOptions}
                        loadRanches={this.loadRanches}
                        ranches={ranches}
                        ranchOptions={ranchOptions}
                        reverseSort={this.reverseSort}
                        setSort={this.setSort}
                        sort={sort}
                        sortDirection={sortDirection}
                        totalRanches={totalRanches}
                        updateFilter={this.updateFilter}
                        user={user ?? undefined}
                        growerId={growerId}
                    />
                </div>
                {growerId && (
                    <div className="my-2 float-left">
                        <Link
                            to={`/ranchs/${growerId}`}
                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right"
                        >
                            Back to Ranch
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(RanchComponent);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".edit_modal__2xNZl.edit_show__w2i0X {\n  display: block; }\n", ""]);
// Exports
exports.locals = {
	"modal": "edit_modal__2xNZl",
	"show": "edit_show__w2i0X"
};
module.exports = exports;

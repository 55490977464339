import { Api } from '@citrus-tracker/api';
import { Agreement, Grower, PackingHouse, User } from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';
import { Component, ReactElement } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

interface PathParams {
    growerId: string;
}
export interface AgreementProps extends RouteComponentProps<PathParams> {
    userId: string;
}

export class CreateAgreement extends Component<AgreementProps> {
    props: Readonly<AgreementProps>;

    state: {
        agreement: Agreement;
        growers: Grower[];
        packingHouses: PackingHouse[];
        packingHousesUsers: User[];
    } = {
        agreement: {
            last_updated: new Date(),
            date_created: new Date(),
        } as Agreement,
        growers: [],
        packingHouses: [],
        packingHousesUsers: [],
    };

    componentDidMount(): void {
        this.loadGrowers();
        this.loadPackingHouses();
    }

    loadGrowers = async (): Promise<void> => {
        let growers = await Api.growers.getAll();

        const growerId = this.props.match.params.growerId;
        if (growerId) {
            const agreement = { ...this.state.agreement };

            growers = growers.filter(
                (g) => g.user?.id?.toString() === growerId,
            );

            agreement.grower = growers[0];

            this.setState({
                growers,
                agreement,
            });
        } else {
            this.setState({
                growers,
            });
        }
    };

    loadPackingHouses = async (): Promise<void> => {
        const packingHouses = await Api.users.listPackingHouse();

        this.setState({
            packingHouses,
        });
    };

    loadPackingHouseUsers = async (id: string): Promise<void> => {
        const packingHousesUsers = await Api.users.listPackingHouseUsers(id);

        this.setState({
            packingHousesUsers,
        });
    };

    handleDetail = (event: any) => {
        const agreement = {
            ...this.state.agreement,
        };

        agreement.details = event.target.value;

        this.setState({
            agreement,
        });
    };

    handleAssociation = (event: any) => {
        const agreement = {
            ...this.state.agreement,
        };

        agreement.association = event.target.value;

        this.setState({
            agreement,
        });
    };

    handleGrower = (event: any) => {
        const agreement = {
            ...this.state.agreement,
        };

        agreement.grower = this.state.growers.find(
            (grower) => grower.id == event.target.value,
        );

        this.setState({
            agreement,
        });
    };

    handlePackingHouse = (event: any) => {
        const agreement = {
            ...this.state.agreement,
        };

        agreement.packingHouse = this.state.packingHouses.find(
            (packingHouse) => packingHouse.id == event.target.value,
        );

        this.loadPackingHouseUsers(agreement?.packingHouse?.id.toString() || '');

        this.setState({
            agreement,
        });
    };

    handlePackingHouseUser = (event: any) => {
        const agreement = {
            ...this.state.agreement,
        };

        agreement.packingHouseUser = this.state.packingHousesUsers.find(
            (packingHouse) => packingHouse.id == event.target.value,
        );

        this.setState({
            agreement,
        });
    };


    submit = async (e: any) => {
        e.preventDefault();
        const agreement = await Api.agreements.save(this.state.agreement);
        this.props.history.push(`/agreements/details/${agreement.id}`);
    };

    render(): ReactElement {
        const { agreement, growers, packingHouses, packingHousesUsers } = this.state;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const growerOptions: any[] = [];
        growers.forEach((element) => {
            growerOptions.push({ id: element.id, label: element.name });
        });

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <div className="text-4xl font-bold text-black">
                        Create agreement
                    </div>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            <Link to={'/agreements'}>
                                <div className="flex justify-center cursor-pointer px-4 py-2 my-8 items-center sm:m-3 bg-indigo-600 border-indigo-600 text-white rounded-md text-base font-medium">
                                    <div>Close</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <form className="p-4 bg-white">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Agreement details*
                            </label>
                            <input
                                type="text"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={agreement.details}
                                onChange={this.handleDetail}
                            />
                        </div>
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Association type*
                            </label>
                            <input
                                type="text"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={agreement.association}
                                onChange={this.handleAssociation}
                            />
                        </div>
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Select a Packing House*
                            </label>
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={agreement.packingHouse?.id}
                                onChange={this.handlePackingHouse}
                            >
                                <option>Please select a Packing House</option>
                                {Array.isArray(packingHouses) &&
                                    packingHouses.map((el, i) => (
                                        <option key={i} value={el.id}>
                                            {el.packing_house_name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="w-full px-3 my-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Packing House User*
                            </label>
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={agreement.packingHouseUser?.id}
                                onChange={this.handlePackingHouseUser}
                            >
                                <option>Please select a Packing House User</option>
                                {Array.isArray(packingHousesUsers) &&
                                    packingHousesUsers.map((el, i) => (
                                        <option key={i} value={el.id}>
                                            {el.firstName} {el.lastName}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="w-full px-3 my-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Select a Grower Entity*
                            </label>
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={agreement.grower?.id}
                                onChange={this.handleGrower}
                            >
                                <option>Please select a Grower Entity</option>
                                {Array.isArray(growers) &&
                                    growers.map((el, i) => (
                                        <option key={i} value={el.id}>
                                            {el.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </form>

                    <Button
                        type="submit"
                        variant="save-and-exit"
                        disabled={!agreement.grower || !agreement.packingHouse || !agreement.packingHouseUser || !agreement.details || !agreement.association}
                        onClick={this.submit}
                        className="float-right px-4 py-2 my-8"
                    >
                        Create
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(CreateAgreement);

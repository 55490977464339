import React, {
    Component,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { Api } from '@citrus-tracker/api';
import {
    PackingHouse,
    PackingHouseFilter,
    PackingHouseFilterOptions,
    PackingHouseOptions,
} from '@citrus-tracker/types';
import Filter, { FilterColumn } from './filter';
import { Table as UITable } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentPackingHouseIndex: number;
    filter: PackingHouseFilter;
    filterOptions: PackingHouseFilterOptions;
    PackingHouses: PackingHouse[];
    PackingHouseOptions: PackingHouseOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalPackingHouses: number;
    loadPackingHouses(page: number, PackingHousesPerPage: number): Promise<void>;
    updateFilter(filter: PackingHouseFilter): void;
    packinghouse?: PackingHouse;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        page: number;
        PackingHousesPerPage: number;
        packinghouseVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        page: 1,
        PackingHousesPerPage: 15,
        packinghouseVisibleColumns: {
            packing_house_name: true,
            packing_house_code: true,
            exchange: true,
            association: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentPackingHouse();

        const columnString = localStorage.getItem('packinghouseVisibleColumns');
        if (columnString) {
            this.setState({ packinghouseVisibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );

        const page = localStorage.getItem('packinghousesPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentPackingHouseIndex } = this.props;

        if (
            currentPackingHouseIndex &&
            (!oldProps.PackingHouses ||
                currentPackingHouseIndex !== oldProps.currentPackingHouseIndex)
        ) {
            this.goToCurrentPackingHouse();
        }
    }

    goToCurrentPackingHouse = (): void => {
        const { currentPackingHouseIndex, PackingHouses } = this.props;

        if (PackingHouses) {
            if (currentPackingHouseIndex) {
                this.goToPackingHouse(currentPackingHouseIndex);
            } else {
                this.goToPackingHouse(1);
            }
        }
    };

    goToPage = (newPage: number): void => {
        const { loadPackingHouses } = this.props;
        const { PackingHousesPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadPackingHouses(newPage, PackingHousesPerPage);
        
        //Store page in session so we can persist across page reloads
        localStorage.setItem('packinghousesPage', JSON.stringify(newPage));
    };

    goToPackingHouse = (PackingHouseIndex: number): void => {
        const { PackingHousesPerPage } = this.state;

        if (PackingHousesPerPage) {
            const page = localStorage.getItem('packinghousesPage') ?? Math.ceil(PackingHouseIndex / PackingHousesPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    openEdit = (PackingHouseIndex?: number): void => {
        if (PackingHouseIndex) {
            this.props.history.push(`/PackingHouses/${PackingHouseIndex}/edit`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    toggleColumnVisibility = (property: keyof PackingHouse): void => {
        const packinghouseVisibleColumns = {
            ...this.state.packinghouseVisibleColumns,
            [property]: !this.state.packinghouseVisibleColumns[property],
        };

        this.setState({
            packinghouseVisibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('packinghouseVisibleColumns', JSON.stringify(packinghouseVisibleColumns));
    };

    renderForDisplay = (
        packinghouse: PackingHouse,
        property: keyof PackingHouse,
    ): ReactElement => {
        switch (property) {
            default:
                return <span>{packinghouse[property]}</span>;
        }
    };

    render(): ReactElement {
        const {
            PackingHouses,
            totalPackingHouses,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            packinghouse,
        } = this.props;
        const {
            page,
            PackingHousesPerPage,
            packinghouseVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalPackingHouses / PackingHousesPerPage);

        const indexBase = (page - 1) * PackingHousesPerPage;

        const allColumns: {
            property: keyof PackingHouse;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'id' as keyof PackingHouse,
                title: 'Id',
                filter: true,
                sort: 'packinghouse.id',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'packing_house_name' as keyof PackingHouse,
                title: 'Name',
                filter: true,
                sort: 'packinghouse.packing_house_name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'packing_house_code' as keyof PackingHouse,
                title: 'Code',
                filter: true,
                sort: 'packinghouse.packing_house_code',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'exchange' as keyof PackingHouse,
                title: 'Exchange',
                filter: true,
                sort: 'packinghouse.exchange',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'association' as keyof PackingHouse,
                title: 'Association #',
                filter: true,
                sort: 'packinghouse.association',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
        ];

        const columns = allColumns.filter(
            (column) => packinghouseVisibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        const start = (page * PackingHousesPerPage) - (PackingHousesPerPage - 1);
        const end = Math.min(start + PackingHousesPerPage - 1, totalPackingHouses);

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={packinghouse?.isAdmin()}
                        visibleColumns={packinghouseVisibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={PackingHouses}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalPackingHouses} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Table);

import {
    Component,
    Fragment,
    ReactElement,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import './table.scss';

import { Api } from '@citrus-tracker/api';
import { Input } from '@citrus-tracker/layout/form';
import {
    FieldEstimate,
    FieldEstimateOptions,
    getFlatRecordValue,
    Record,
    RecordFilter,
    RecordFilterOptions,
    User,
} from '@citrus-tracker/types';
import BulkEditFieldEstimate from '../edit/bulk-edit';
import Filter from '../filter/filter';
import {  Table as UITable, Modal } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentRecordIndex: number;
    filter: RecordFilter;
    filterOptions: RecordFilterOptions;
    estimates: FieldEstimate[];
    records: Record[];
    estimateOptions: FieldEstimateOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalRecords: number;
    loadRecords(page: number, recordsPerPage: number): Promise<void>;
    updateFilter(filter: RecordFilter): void;
    user?: User;
}

export class Table extends Component<TableProps> {
    props: Readonly<TableProps>;

    state: {
        bulkEditEstimates: FieldEstimate[];
        selectedForBulkEdit: number[];
        page: number;
        recordsPerPage: number;
        editingRecord?: Record | null;
        estimateVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        bulkEditEstimates: [],
        selectedForBulkEdit: [],
        page: 1,
        recordsPerPage: 15,
        estimateVisibleColumns: {
            grower_provided: false,
            block_description_provided: false,
            grower_id: true,
            grower_name: false,
            district: false,
            block: false,
            block_name: false,
            organic: false,
            acres: false,
            trees: false,
            trees_per_acre: false,
            plant_year: false,
            google_maps_url: false,
            status: false,
            updated_by: false,
            last_updated: false,
            date_created: false,
            packingHouse: true,
            crop: false,
            variety: false,
            subVariety: false,
            plantAge: false,
            rootstock: false,
        },
    };

    componentDidMount(): void {
        this.goToCurrentRecord();

        const columnString = localStorage.getItem('estimateVisibleColumns');
        if (columnString) {
            this.setState({ estimateVisibleColumns: JSON.parse(columnString) });
        }

        const page = localStorage.getItem('estimatePage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentDidUpdate(oldProps: TableProps): void {
        const { currentRecordIndex } = this.props;

        if (
            currentRecordIndex &&
            (!oldProps.records ||
                currentRecordIndex !== oldProps.currentRecordIndex)
        ) {
            this.goToCurrentRecord();
        }
    }

    goToCurrentRecord = (): void => {
        const { currentRecordIndex, records } = this.props;

        if (records) {
            if (currentRecordIndex) {
                this.goToRecord(currentRecordIndex);
            } else {
                this.goToRecord(1);
            }
        }
    };

    goToPage = (newPage: number): void => {
        const { loadRecords } = this.props;
        const { recordsPerPage } = this.state;
        this.setState({ page: newPage });
        loadRecords(newPage, recordsPerPage);

        //Store page in session so we can persist across page reloads
        localStorage.setItem('estimatePage', JSON.stringify(newPage));
    };

    goToRecord = (recordIndex: number): void => {
        const { recordsPerPage } = this.state;

        if (recordsPerPage) {
            const page = localStorage.getItem('estimatePage') ?? Math.ceil(recordIndex / recordsPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    inlineEdit = async (recordId: number | null | undefined): Promise<void> => {
        const { loadRecords } = this.props;
        const { page, recordsPerPage } = this.state;

        await loadRecords(page, recordsPerPage);

        if (recordId) {
            const { records } = this.props;
            for (const record of records) {
                if (record.id === recordId) {
                    this.setState({ editingRecord: record });
                }
            }
        } else {
            this.setState({ editingRecord: null });
        }
    };

    inlineUpdate = async (
        recordId: number | undefined,
        property: keyof Record,
        newValue: string | number | { id: number } | null,
    ): Promise<void> => {
        if (!newValue) {
            newValue = null;
        }
        if (recordId && property) {
            await Api.records.save({ id: recordId, [property]: newValue });

            const { editingRecord } = this.state;
            if (editingRecord && editingRecord.id === recordId) {
                this.setState({
                    editingRecord: { ...editingRecord, [property]: newValue },
                });
            }
        }
    };

    openBulkEdit = (): void => {
        const { records } = this.props;
        const { selectedForBulkEdit } = this.state;

        const bulkEditEstimates = records.filter(
            (record) =>
                record.id !== undefined &&
                selectedForBulkEdit.indexOf(record.id) >= 0,
        );
        this.setState({ bulkEditEstimates });
    };

    closeBulkEdit = (): void => {
        this.setState({ bulkEditEstimates: [] });
    };

    openEdit = (recordIndex?: number): void => {
        if (recordIndex) {
            this.props.history.push(`/estimates/${recordIndex}/edit`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    toggleColumnVisibility = (property: keyof Record): void => {
        const estimateVisibleColumns = {
            ...this.state.estimateVisibleColumns,
            [property]: !this.state.estimateVisibleColumns[property],
        };

        this.setState({
            estimateVisibleColumns,
        });

        //Store in session so we can persist across page reloads
        localStorage.setItem('estimateVisibleColumns', JSON.stringify(estimateVisibleColumns));
    };

    renderForDisplay = (
        record: Record,
        property: keyof Record,
    ): ReactElement => {
        switch (property) {
            case 'packingHouse':
                return (
                    <span title={record.packingHouse &&
                      record.packingHouse.packing_house_name}>
                        {record.packingHouse &&
                            record.packingHouse.packing_house_name}
                    </span>
                );
            case 'grower_id':
                return (
                  <span title={record.grower_id}>
                      {record.grower_id}
                  </span>
                );
            case 'subVariety':
                return (
                    <span>
                        {record.subVariety && record.subVariety.sub_variety}
                    </span>
                );
            case 'variety':
                return <span>{record.variety && record.variety.variety}</span>;
            case 'trees':
                return <span>
                  {
                    record.trees && new Intl.NumberFormat()
                      .format(Math.round(record.trees))
                      .toString()
                  }
                  </span>;
            case 'trees_per_bin':
              return <span>
                  {
                    record.trees_per_bin && new Intl.NumberFormat()
                      .format(Math.round(record.trees_per_bin))
                      .toString()
                  }
                  </span>;
            case 'trees_per_acre':
              return <span>
                  {
                    record.trees_per_acre && new Intl.NumberFormat()
                      .format(Math.round(record.trees_per_acre))
                      .toString()
                  }
                  </span>;
            default:
                if(property.includes('fieldEstimate') && record.fieldEstimate){
                    const fieldkey = property.replace('fieldEstimate.', '');
                    switch(fieldkey) {
                        case 'picked_to_date':
                            return <span>
                                {
                                    record.fieldEstimate.picked_to_date && new Intl.NumberFormat()
                                    .format(Math.round(record.fieldEstimate.picked_to_date))
                                    .toString()
                                }
                                </span>;
                        case 'total_bins':
                            return <span>
                                {
                                record.fieldEstimate.total_bins && new Intl.NumberFormat()
                                    .format(Math.round(record.fieldEstimate.total_bins))
                                    .toString()
                                }
                                </span>;
                        default:
                           return <span>{record.fieldEstimate[fieldkey as keyof FieldEstimate]}</span>;
                    }
                }
                return <span>{record[property]}</span>;
        }
    };

    renderForEdit = (record: Record, property: keyof Record): ReactElement => {
        switch (property) {
            case 'packingHouse':
                return (
                    <span>
                        {record.packingHouse &&
                            record.packingHouse.packing_house_name}
                    </span>
                );
            case 'subVariety':
                return (
                    <span>
                        {record.subVariety && record.subVariety.sub_variety}
                    </span>
                );
            case 'variety':
                return <span>{record.variety && record.variety.variety}</span>;
            default:
                return (
                    <span>
                        <Input
                            id={property}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    property,
                                    newValue,
                                )
                            }
                            value={getFlatRecordValue(record, property)}
                        />
                    </span>
                );
        }
    };

    toggleSelectedForBulkEdit = (recordId: number | undefined): void => {
        if (recordId === undefined) {
            return;
        }

        const { selectedForBulkEdit } = this.state;


        const i = selectedForBulkEdit.indexOf(recordId);
        if (i >= 0) {
            selectedForBulkEdit.splice(i, 1);
        } else {
            selectedForBulkEdit.push(recordId);
        }

        this.setState({ selectedForBulkEdit });
    };


    toggleAllSelectedForBulkEdit = (selected: boolean): void => {
        const { records } = this.props;

        let selectedForBulkEdit: number[] = [];
        if (selected) {
            selectedForBulkEdit = records.map((record) => record.id || 0);
        }

        this.setState({ selectedForBulkEdit });
    };

    bulkEditFinished = (): void => {
        const { loadRecords } = this.props;
        const { page, recordsPerPage } = this.state;

        loadRecords(page, recordsPerPage);

        this.setState({ bulkEditEstimates: [] });
    };

    render(): ReactElement {
        const {
            records,
            estimateOptions,
            totalRecords,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            user,
        } = this.props;
        const {
            bulkEditEstimates,
            editingRecord,
            page,
            recordsPerPage,
            selectedForBulkEdit,
            estimateVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalRecords / recordsPerPage);

        const indexBase = 0;

        const allColumns: {
            property: keyof Record;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'packingHouse' as keyof Record,
                title: 'Packing House',
                sort: 'packingHouse.packing_house_name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'district' as keyof Record,
                title: 'District',
                sort: 'record.district',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'grower_id' as keyof Record,
                title: 'Grower ID',
                sort: 'record.grower_id',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'grower_name' as keyof Record,
                title: 'Grower',
                sort: 'record.grower_name',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'variety' as keyof Record,
                title: 'Variety',
                sort: 'record.variety.variety',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'subVariety' as keyof Record,
                title: 'Sub-Variety',
                sort: 'record.subVariety.sub_variety',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'block' as keyof Record,
                title: 'Block',
                sort: 'record.block',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.id' as keyof Record,
                title: 'Block ID',
                sort: 'fieldEstimate.id',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.total_bins' as keyof Record,
                title: 'Current Estimate',
                sort: 'record.total_bins',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.picked_to_date' as keyof Record,
                title: 'Bins Picked',
                sort: 'fieldEstimate.picked_to_date',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.trees_per_bin' as keyof Record,
                title: 'Trees Per Bin',
                sort: 'fieldEstimate.record.trees_per_bin',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            // {
            //     property: 'rootstock' as keyof Record,
            //     title: 'Rootstock',
            //     sort: 'rootstock.rootstock',
            //     tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'acres' as keyof Record,
                title: 'Planted Acres',
                sort: 'record.acres',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'trees_per_acre' as keyof Record,
                title: 'Trees Per Acre',
                sort: 'record.trees_per_acre',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'trees' as keyof Record,
                title: 'Trees Count',
                sort: 'record.trees',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.harvest_phase' as keyof Record,
                title: 'Harvest Phase',
                sort: 'fieldEstimate.harvest_phase',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.estimate_date' as keyof Record,
                title: 'Estimate Date',
                sort: 'fieldEstimate.estimate_date',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.reason' as keyof Record,
                title: 'Reason',
                sort: 'fieldEstimate.reason',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.picked_to_date' as keyof Record,
                title: 'Bins Picked to Date',
                sort: 'fieldEstimate.picked_to_date',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            // {
            //     // property: 'fieldEstimate' as keyof Record,
            //     title: 'Bins Season Estimate',
            //     // sort: 'fieldEstimate.bins_season_estimate',
            //     tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'fieldEstimate.trees_per_bin' as keyof Record,
                title: 'Trees Per Bin',
                sort: 'fieldEstimate.trees_per_bin',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.cartons_per_tree' as keyof Record,
                title: 'Raw Cartons Per Tree',
                sort: 'fieldEstimate.cartons_per_tree',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            // {
            //     // property: 'fieldEstimate' as keyof Record,
            //     title: 'Cartons Per Bin',
            //     // sort: 'fieldEstimate.cartons_per_bin',
            //     tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'fieldEstimate.export_percent' as keyof Record,
                title: 'Export %',
                sort: 'fieldEstimate.export_percent',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fancy_percent' as keyof Record,
                title: 'Fancy %',
                sort: 'fieldEstimate.fancy_percent',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_1' as keyof Record,
                title: 'Fruit Size 1',
                sort: 'fieldEstimate.fruit_size_1',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_2' as keyof Record,
                title: 'Fruit Size 2',
                sort: 'fieldEstimate.fruit_size_2',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_3' as keyof Record,
                title: 'Fruit Size 3',
                sort: 'fieldEstimate.fruit_size_3',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_4' as keyof Record,
                title: 'Fruit Size 4',
                sort: 'fieldEstimate.fruit_size_4',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_5' as keyof Record,
                title: 'Fruit Size 5',
                sort: 'fieldEstimate.fruit_size_5',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_6' as keyof Record,
                title: 'Fruit Size 6',
                sort: 'fieldEstimate.fruit_size_6',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_7' as keyof Record,
                title: 'Fruit Size 7',
                sort: 'fieldEstimate.fruit_size_7',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_8' as keyof Record,
                title: 'Fruit Size 8',
                sort: 'fieldEstimate.fruit_size_8',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_9' as keyof Record,
                title: 'Fruit Size 9',
                sort: 'fieldEstimate.fruit_size_9',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.fruit_size_10' as keyof Record,
                title: 'Fruit Size 10',
                sort: 'fieldEstimate.fruit_size_10',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.reason' as keyof Record,
                title: 'Estimate Reason',
                sort: 'fieldEstimate.reason',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.market' as keyof Record,
                title: 'Market',
                sort: 'fieldEstimate.market',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            // {
            //     property: 'fieldEstimate' as keyof Record,
            //     title: 'Grade % Choice',
            //     sort: 'fieldEstimate.choice_percent',
            //     tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            // },
            {
                property: 'fieldEstimate.notes' as keyof Record,
                title: 'Field Notes / Status',
                sort: 'fieldEstimate.notes',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.block_cleaned' as keyof Record,
                title: 'Block Cleaned',
                sort: 'fieldEstimate.block_cleaned',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
            {
                property: 'fieldEstimate.updated_by' as keyof Record,
                title: 'Updated By',
                sort: 'fieldEstimate.updated_by',
                tdClass: 'px-6 py-4 whitepace-nowrap font-medium text-gray-900',
            },
        ];

        //Don't show Packing House column to non-admins.. Is this relevant for the Block Estimates?  Do all users get to see Block Estimates?
        if (!user?.isAdmin()) {
            allColumns.shift();
        }

        //Do we want to keep the packing house filter?  Figma doesn't show it...
        const columns = allColumns.filter(
            (column) => estimateVisibleColumns[column.property],
        );

        const start = (page * recordsPerPage) - (recordsPerPage - 1);
        const end = Math.min(start + recordsPerPage - 1, totalRecords);

        let bulkEditRecords: Record[] = [];

        if(selectedForBulkEdit.length > 0){
            bulkEditRecords = records.filter(
                (record) =>
                    record.id !== undefined &&
                    selectedForBulkEdit.indexOf(record.id) >= 0,
            );
        }

        return (
            <div className="block">
                <div className="block-content">
                    {records && (
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        openBulkEdit={
                            (selectedForBulkEdit.length > 0 && filter.district && filter.subVariety)
                                ? this.openBulkEdit
                                : undefined
                        }
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={user?.isAdmin()}
                        visibleColumns={estimateVisibleColumns}
                        Api={Api}
                    />)}
                    <UITable
                        columns={columns}
                        rows={records}
                        renderForDisplay={this.renderForDisplay}
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                        toggleSelectedForBulkEdit={
                            this.toggleSelectedForBulkEdit
                        }
                        toggleAllSelectedForBulkEdit={
                            this.toggleAllSelectedForBulkEdit
                        }
                        selectedForBulkEdit={selectedForBulkEdit}
                        inlineEdit={this.inlineEdit}
                        editingRow={editingRecord}
                        renderForEdit={this.renderForEdit}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalRecords} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalRecords > 0
                                            ? Math.ceil(
                                                  totalRecords / recordsPerPage,
                                              )
                                            : 1}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    title="Field Estimate Bulk Edit"
                    show={bulkEditEstimates.length > 0}
                    close={this.closeBulkEdit}
                    content={
                        <Fragment>
                            {' '}
                            <div className="block-content">
                                <BulkEditFieldEstimate
                                    onFinished={this.bulkEditFinished}
                                    estimates={bulkEditEstimates}
                                    fieldEstimateOptions={estimateOptions}
                                    fruitSizes={Api.fieldEstimates.fruitSizes(bulkEditRecords.length > 0 ? bulkEditRecords[0].crop?.crop : '')}
                                />
                            </div>
                        </Fragment>
                    }
                />
            </div>
        );
    }
}

export default withRouter(Table);

import {
  Crop,
  Grower,
  PackingHouse,
  Record,
  RecordFilter,
  RecordFilterOptions,
  SubVariety,
} from '@citrus-tracker/types';
import React, { Component, ReactElement, RefObject } from 'react';
import {
  Button,
  DropdownMenu,
} from '@citrus-tracker/ui';

import './filter.scss';

/* eslint-disable-next-line */
export interface FilterProps {
  filter: RecordFilter;
  filterOptions: RecordFilterOptions;
  openBulkEdit?(): void;
  nextPage?(): void;
  prevPage?(): void;
  updateFilter(newOptions: RecordFilter): void;
  columns?: {
    property: keyof Record;
    title: string;
  }[];
  toggleColumnVisibility?(property: keyof Record): unknown;
  isAdmin?: boolean;
  visibleColumns?: {
    [key: string]: boolean;
  };
  Api?: any;
}

export class Filter extends Component<FilterProps> {
  columnDropdownRef: RefObject<HTMLDivElement>;
  columnDropdownTriggerRef: RefObject<HTMLButtonElement>;

  props: Readonly<FilterProps>;

  constructor(props: FilterProps) {
    super(props);

    this.columnDropdownRef = React.createRef();
    this.columnDropdownTriggerRef = React.createRef();
  }

  clearFilters = (): void => {
    const { updateFilter } = this.props;
    updateFilter({
      packingHouse: { id: 0 } as PackingHouse,
      crop: { id: 0 } as Crop,
      district: "",
      subVariety: { id: 0 } as SubVariety,
      grower_provided: "",
    });

    localStorage.setItem('estimatePage', JSON.stringify(1));
  };

  setFilterOption = (
    property: string,
    value:
      | string
      | number
      | Crop
      | PackingHouse
      | Grower
      | object
      | undefined,
  ): void => {
    const { filter, updateFilter } = this.props;

    updateFilter({
      ...filter,
      [property]: value,
    });
  };

  render(): ReactElement {
    const {
      filter,
      filterOptions,
      openBulkEdit,
      nextPage,
      prevPage,
      columns,
      toggleColumnVisibility,
      isAdmin,
      visibleColumns,
    } = this.props;

    return (
        <div className="grid grid-cols-7 grid-rows-2 gap-4 mb-4 items-start">
            {isAdmin && (
              <div
                className="col-start-1 row-start-1"
                style={{ paddingTop: 0, paddingLeft: 0 }}
              >
                <label
                  className="block text-xs font-medium text-gray-700"
                  htmlFor="filter"
                >
                  Packing House:
                </label>{' '}
                <select
                  disabled={window.location.href.includes('edit')}
                  className="h-8 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="packingHouseIdFilter"
                  name="packingHouseIdFilter"
                  onChange={(e): void =>
                    this.setFilterOption(
                      'packingHouse',
                      e.target.value
                        ? { id: parseInt(e.target.value) }
                        : '',
                    )
                  }
                  value={filter.packingHouse?.id}
                >
                  <option value="0">All</option>
                  {filterOptions?.packingHouse?.map(function (
                    packingHouse,
                    index,
                  ) {
                    return (
                      <option
                        key={index}
                        value={packingHouse.value.id}
                      >
                        {packingHouse.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {(
              <div
                className="col-start-2"
                style={{ paddingTop: 0 }}
              >
                <label
                  className="block text-xs font-medium text-gray-700"
                  htmlFor="districtFilter"
                >
                  District:
                </label>{' '}
                <select
                  disabled={window.location.href.includes('edit')}
                  className="h-8 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="districtFilter"
                  name="districtFilter"
                  onChange={(e): void =>
                    this.setFilterOption('district', e.target.value)
                  }
                  value={filter.district}
                >
                  <option value="">All</option>
                  {filterOptions?.district?.map(function (
                    district,
                    index,
                  ) {
                    return (
                      <option key={index} value={district.value}>
                        {district.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {(
              <div
                className="col-start-3"
                style={{ paddingTop: 0 }}
              >
                <label
                  className="block text-xs font-medium text-gray-700"
                  htmlFor="cropFilter"
                >
                  Crop Filter:
                </label>{' '}
                <select
                  disabled={window.location.href.includes('edit')}
                  className="h-8 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="cropFilter"
                  name="cropFilter"
                  onChange={(e): void =>
                    this.setFilterOption(
                      'crop',
                      e.target.value
                        ? { id: parseInt(e.target.value) }
                        : '',
                    )
                  }
                  value={filter.crop?.id}
                >
                  <option value="">All</option>
                  {filterOptions?.crop?.map(function (crop, index) {
                    return (
                      <option key={index} value={crop.value.id}>
                        {crop.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {(
              <div
                className="col-start-4"
                style={{ paddingTop: 0 }}
              >
                <label
                  className="block text-xs font-medium text-gray-700"
                  htmlFor="cropFilter"
                >
                  Sub-Variety Filter:
                </label>{' '}
                <select
                  disabled={window.location.href.includes('edit')}
                  className="h-8 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="varietyFilter"
                  name="varietyFilter"
                  onChange={(e): void =>
                    this.setFilterOption(
                      'subVariety',
                      e.target.value
                        ? { id: parseInt(e.target.value) }
                        : '',
                    )
                  }
                  value={filter.subVariety?.id}
                >
                  <option value="0">All</option>
                  {filterOptions?.subVariety?.map(function (
                    subVariety,
                    index,
                  ) {
                    return (
                      <option
                        key={index}
                        value={subVariety.value.id}
                      >
                        {subVariety.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {(
              <div
                className="col-start-5"
                style={{ paddingTop: 0 }}
              >
                <label
                  className="block text-xs font-medium text-gray-700"
                  htmlFor="cropFilter"
                >
                  Grower Filter:
                </label>{' '}
                <select
                  disabled={window.location.href.includes('edit')}
                  className="h-8 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="growerFilter"
                  name="growerFilter"
                  onChange={(e): void =>
                    this.setFilterOption(
                      'grower',
                      e.target.value
                        ? { id: parseInt(e.target.value) }
                        : '',
                    )
                  }
                  value={filter.grower_provided}
                >
                  <option value="">All</option>
                  {filterOptions?.grower_provided?.map(
                    (grower_provided, index) => {
                      return (
                        <option
                          key={index}
                          value={grower_provided.value}
                        >
                          {grower_provided.value}
                        </option>
                      );
                    },
                  )}
                </select>
              </div>
            )}

            {(
              <div
                className="col-start-6"
                style={{ paddingTop: 0 }}
              >
                <label
                  className="block text-xs font-medium text-gray-700"
                  htmlFor="name"
                >
                  Block Description:
                </label>
                <div className="relative w-full bg-white text-left cursor-default sm:text-sm">
                  <input
                    disabled={window.location.href.includes('edit')}
                    type="text"
                    placeholder="Search"
                    className="h-8 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  ></input>
                </div>
              </div>
            )}

          
            {toggleColumnVisibility && (
              <div className="row-start-2 col-start-7 relative float-right justify-self-end order-last">
                <DropdownMenu
                  icon="CogIcon"
                  items={columns?.map((column) => (
                    <label
                      key={column.property}
                      htmlFor={`${column.property}Visible`}
                      className="pr-4 block whitespace-nowrap"
                    >
                      <input
                        type="checkbox"
                        id={`${column.property}Visible`}
                        checked={
                          visibleColumns &&
                          visibleColumns[column.property]
                        }
                        onChange={(): unknown =>
                          toggleColumnVisibility(
                            column.property,
                          )
                        }
                      />{' '}
                      {column.title}
                    </label>
                  ))}
                  showArrow={false}
                />
              </div>
            )}

            {openBulkEdit && (
              <Button
                className="row-start-2 col-start-7 ml-4 mr-4 justify-self-start"
                onClick={openBulkEdit}
              >
                Bulk Edit
              </Button>
            )}
            {(Object.keys(filter).length > 0 || ( localStorage.getItem('estimatePage') !== undefined && parseInt(localStorage.getItem('estimatePage')?.toString() ?? '0') > 1 )) && (
              (filter.packingHouse?.id !== 0 ||
                filter.crop?.id !== 0 ||
                filter.district !== "" ||
                filter.grower_provided !== "" ||
                filter.subVariety?.id !== 0) &&
                !window.location.href.includes('edit')) && (
              <Button
                className='row-start-1 col-start-7 ml-4 mr-4 order-first text-center w-1/2 self-end'
                type='submit'
                onClick={this.clearFilters}
              >
                Clear Filters
              </Button>
            )}
          
      </div>
    );
  }
}

export default Filter;

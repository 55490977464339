import React from 'react';

import './ui.module.scss';

/* eslint-disable-next-line */
export interface UiProps {}

export function Ui(props: UiProps) {
    return (
        <div>
            <h1>Welcome to ui!</h1>
        </div>
    );
}

export default Ui;

import { ListRequest } from '..';
import { AgreementBlocks } from './agreementBlocks';
import { BlockFilter } from './filter';
import { Grower } from './grower';
import { Crop } from './crop';
import { PlantAge } from './plantAge';
import { Rootstock } from './rootstock';
import { SubVariety } from './subVariety';
import { Variety } from './variety';
import { Ranch } from './ranch';

export interface Block {
    id: number;

    block_description: string;

    district: string;

    name: string;

    block?: string;

    blockName?: string;

    organic?: string;

    trees?: number;

    trees_per_acre?: number;

    plant_year?: string;

    google_maps_url?: string;

    status?: string;

    apn?: string;

    ein?: string;

    updated_by?: string;

    last_updated?: Date;

    date_created?: Date;

    total_acres?: string;

    assigned_acres?: string;

    tree_age?: number;

    agreementBlocks?: AgreementBlocks[];

    grower: Grower;

    crop?: Crop;

    variety?: Variety;

    subVariety?: SubVariety;

    plantAge?: PlantAge;

    rootstock?: Rootstock;

    ranch?: Ranch;

    packingHouseId?: number;

    packingHouseUserId?: number;
}

export function getFlatBlockValue(
    record?: Block | null,
    property?: keyof Block,
): string | number {
    if (record && property) {
        const value = record[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        } else if (!Array.isArray(value)) {
            return value?.id ?? '';
        }
    }

    return '';
}

export interface BlockListRequest extends ListRequest {
    growerId?: number;
    filter?: BlockFilter;
    sort?: string;
    sortDirection?: string;
}

import React, { Component, ReactElement } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { Api } from '@citrus-tracker/api';
import {
    Record,
    RecordFilter,
    RecordFilterOptions,
    RecordOptions,
    User,
} from '@citrus-tracker/types';
import RecordForm from './form';
import Summary from '../../field-estimate/summary/summary';

import './edit.module.scss';
import { format } from 'date-fns';


interface PathParams {
    blockId: string;
}

/* eslint-disable-next-line */
export interface EditProps extends RouteComponentProps<PathParams> {
    filter: RecordFilter;
    filterOptions: RecordFilterOptions;
    loadRecords(page: number, recordsPerPage: number): Promise<void>;
    records: Record[];
    recordOptions: RecordOptions;
    setCurrentRecordIndex(currentRecordIndex: number): unknown;
    setError(message: string): void;
    setSuccess(message: string): void;
    totalRecords: number;
    updateFilter(filter: RecordFilter): void;
    user?: User | null;
}

export class Edit extends Component<EditProps> {
    props: Readonly<EditProps>;

    state: {
        activeRecord?: Record;
        error: string;
        updates: Record;
    } = {
        error: '',
        updates: {},
    };

    componentDidMount(): void {
        this.loadPage();
    }
    
    componentDidUpdate(oldProps: EditProps): void {
        if (this.props.match.params.blockId !== oldProps.match.params.blockId) {
            this.loadPage();
        }
    }

    loadPage = async (): Promise<void> => {
        const { loadRecords } = this.props;
        const { blockId } = this.props.match.params;
        
        if (!this.props.records || this.props.records.length === 0)
            await loadRecords(1, 10000);

        const { records } = this.props;

        if (records && records.length > 0) {
            this.setState({
                activeRecord: records.find((b) => b.id?.toString() === blockId),
                updates: {},
            });
        }
    };

    saveChanges = async (): Promise<Record | null> => {
        const { setError, setSuccess } = this.props;
        const { updates, activeRecord } = this.state;

        try {
            const record = await Api.records.save({
                id: activeRecord?.id,
                ...updates,
            });
            setSuccess('Saved Successfully');
            this.props.history.goBack();
            return record;
        } catch (error) {
            setError(error);
            return null;
        }
    };

    setUpdates = (updates: Record): void => {
        this.setState({ updates });
    };

    openDelete = async (): Promise<void> => {
        if (
            window.confirm('Are you sure you want to delete this record?') &&
            this.state.activeRecord &&
            this.state.activeRecord.id
        ) {
            await Api.records.delete(this.state.activeRecord.id);
            this.props.history.goBack();
        }
    };

    newEstimate = (recordIndex?: number): void => {
        if (recordIndex) {
            this.props.history.push(
                `/records/${recordIndex}/edit/new-estimate`,
            );
        }
    };

    render(): ReactElement {
        const {
            records,
            recordOptions,
        } = this.props;

        const {
            activeRecord
        } = this.state;

        
        return (
            <div className="block">
                <div className="block-header block-header-default">
                    <h3 className="block-title">
                        {activeRecord?.packingHouse?.packing_house_name}
                    </h3>
                </div>
                {(activeRecord && !activeRecord?.fieldEstimate) ? (
                    <div className="block-content flex justify-between items-center mb-4 pt-0">
                        <p>
                            <strong>Field Estimate</strong>
                        </p>
                        {(!activeRecord.trees || activeRecord.trees <= 0) && <p className='text-red-600'>
                            Trees is required to enter a new estimate    
                        </p>}
                        <div className="w-full sm:w-auto mb-4">
                            <div className="flex justify-between items-center">
                                <Link
                                    to={!activeRecord.trees ? '#' : `/estimates/recordId/${activeRecord.id}/new`}
                                    className={
                                        !activeRecord.trees ?
                                        "cursor-not-allowed align-middle inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md border-transparent text-white bg-blue-300 px-4 float-right ml-4"
                                        :
                                        "cursor-pointer align-middle inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 border-transparent text-white bg-blue-800 hover:bg-blue-900 px-4 float-right ml-4"
                                    }
                                >
                                    <div>New Estimate</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                ) : (activeRecord && (
                    <div className="block-content flex-col justify-between items-center mb-4 pt-0">
                        <div className='flex justify-between items-center mb-4 pt-0'>
                        <p>
                            <strong>Field Estimate Summary</strong>
                        </p>
                        <div className="w-full sm:w-auto mb-4">
                            <div className="flex justify-between items-center">
                                <div>
                                    Last Update:   {activeRecord.fieldEstimate?.last_updated
                                    ? format(
                                          new Date(
                                              activeRecord.fieldEstimate?.last_updated,
                                          ),
                                          'MM/dd/yyyy',
                                      )
                                    : activeRecord.fieldEstimate?.date_created ? format(
                                        new Date(
                                            activeRecord.fieldEstimate?.date_created,
                                        ),
                                        'MM/dd/yyyy',
                                    ) : ''}
                                </div>
                                <Link
                                    to={`/estimates/recordId/${activeRecord.id}/edit`}
                                    className="cursor-pointer align-middle inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 border-transparent text-white bg-blue-800 hover:bg-blue-900 px-4 float-right ml-4"
                                >
                                    <div>Update Estimate</div>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <Summary
                            record={activeRecord}
                            cartonsPerBin={23}
                            fruitSizes={Api.fieldEstimates.fruitSizes(activeRecord.crop?.crop)}
                        />
                    </div>
                ))}
                
                {activeRecord && (
                    <RecordForm
                        openDelete={this.openDelete}
                        record={activeRecord}
                        recordOptions={recordOptions}
                        saveChanges={this.saveChanges}
                        setUpdates={this.setUpdates}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(Edit);

import React, { ReactElement } from 'react';
import { Component } from 'react';

import { Modal, Button } from '@citrus-tracker/ui';

import './image-upload.module.scss';

export class ImageUpload extends Component {
    props: Readonly<{
        upload(file: File): Promise<boolean>;
        cancel(): void;
    }>;
    state: {
        file: File;
    } = {
        file: {} as File,
    };

    saveFile = (e: any) => {
        this.setState({ file: e.target.files[0] });
    };

    uploadFile = async () => {
        this.props.upload(this.state.file);
    };

    render(): ReactElement {
        return (
            <Modal
                title="Upload a new profile image"
                show={true}
                close={(): void =>
                    this.setState({
                        file: {} as File,
                    })
                }
                content={
                    <div className="App">
                        <input
                            className="mr-10 w-full outline-none"
                            type="file"
                            onChange={this.saveFile}
                        />

                        <div>
                            <Button
                                onClick={(): void => {
                                    this.uploadFile();
                                }}
                                type="button"
                                className="float-right mt-4"
                            >
                                Upload
                            </Button>
                            <Button
                                type="button"
                                onClick={(): void => this.props.cancel()}
                                variant="secondary"
                                className="mr-4 float-right mt-4"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default ImageUpload;

import { Api } from '@citrus-tracker/api';
import { Grower, GrowerAccount, User } from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';
import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import DatePicker from "react-datepicker";
import Multiselect from 'multiselect-react-dropdown';

import "react-datepicker/dist/react-datepicker.css";

interface PathParams {
    userId: string;
    id: string;
}
export interface GrowerAccountProps extends RouteComponentProps<PathParams> {
    user?: User | null;
}

export class GrowerAccountEditComponent extends Component<GrowerAccountProps> {
    props: Readonly<GrowerAccountProps>;

    state: {
        userId: string;
        growerAccountId: string;
        growerAccount: GrowerAccount;
        formatSSN: boolean;
        users: Grower[];
        usersForList: any[];
    } = {
        userId: '',
        growerAccountId: '',
        growerAccount: {} as GrowerAccount,
        formatSSN: false,
        users: [],
        usersForList: [],
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;
        const userId = this.props.match.params.userId;

        this.loadUsers();
        this.setState({ userId });
        if (id !== 'new') {
            this.setState({ growerAccountId: id });
            this.loadGrowerAccount(id.toString());
        }
    }

    loadUsers = async (): Promise<void> => {
        const users = await Api.growers.getAll();
        users.sort((a,b) => a.name.toString().localeCompare(b.name.toString()));
        const usersAsList = [];

        for (const user of users) {
            usersAsList.push({name: user.name, id: user.id})
        }

        this.setState({
            users,
            usersForList: usersAsList,
        });
    };

    loadGrowerAccount = async (growerAccountId: string): Promise<void> => {
        const growerAccount = await Api.growerAccounts.get(growerAccountId);

        growerAccount.growerMemberSince = new Date(growerAccount.growerMemberSince as unknown as string);
        this.setState({
            growerAccount: growerAccount,
        });
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    //Added an override for the race condition on the radio buttons. The state is not set before this is called.
    setValue = (
        property: keyof GrowerAccount,
        value: unknown,
    ): void => {
        const growerAccount = {
            ...this.state.growerAccount,
        };

        const newGrowerAccount = {
            ...growerAccount,
            [property]: value,
        };

        this.setGrowerAccount(newGrowerAccount);
    };

    setGrowerAccount = (growerAccount: GrowerAccount): void => {
        this.setState({
            growerAccount,
        });
    };

    delete = async (): Promise<void> => {
        if (
            window.confirm(
                `Are you sure you want to delete ${this.state.growerAccount.name}`,
            ) === true
        ) {
            await Api.growerAccounts.delete(this.state.growerAccount.id);
            this.props.history.goBack();
        }
    };

    saveChanges = async (): Promise<void> => {
        const growerAccount = {
            ...this.state.growerAccount,
        };

        this.setGrowerAccount(growerAccount);

        const selectedGrowers: Grower[] = [];
        for(const grower of growerAccount.growers) {
            const growerObject = this.state.users.find((g) => g.id === grower.id);
            if(growerObject)
                selectedGrowers.push(growerObject);
        }

        growerAccount.growers = selectedGrowers;

        await Api.growerAccounts.save(growerAccount);
        this.props.history.goBack();
    };

    phoneMask = (value: any): string => {
        if (!value) return value;

        const phone = value.replace(/[^\d]/g, '');

        if (phone.length < 4) return phone;
        if (phone.length < 7) {
            return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
        }
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
            6,
            10,
        )}`;
    };

    render(): ReactElement {
        const { growerAccount, growerAccountId, usersForList: users } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <div className="text-4xl font-bold text-black">Grower Account</div>
                </div>
                <div>
                    <form
                        className="w-full max-w-xlg rounded bg-white p-8"
                        id="growerAccount_form"
                    >
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="name"
                                >
                                    Name*
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="name"
                                    type="text"
                                    placeholder="Grower Account Name"
                                    value={growerAccount.name}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'name',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="email"
                                >
                                    Email*
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    value={growerAccount.email}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'email',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="primaryNumber"
                                >
                                    Primary Phone #*
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="primaryNumber"
                                    type="tel"
                                    placeholder="Phone #"
                                    value={growerAccount.primaryNumber}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'primaryNumber',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="secondaryNumber"
                                >
                                    Secondary Phone #
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="secondaryNumber"
                                    type="tel"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    placeholder="Alt Phone #"
                                    value={growerAccount.secondaryNumber}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'secondaryNumber',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="address"
                                >
                                    Address*
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="address"
                                    type="text"
                                    placeholder="Street address or P.O. Box"
                                    value={growerAccount.address}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'address',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="address2"
                                    type="text"
                                    placeholder="Apt, Suite, Unit, Building (optional)"
                                    value={growerAccount.address2}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'address2',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="city"
                                >
                                    City*
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="city"
                                    type="text"
                                    placeholder="City"
                                    value={growerAccount.city}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'city',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="state"
                                >
                                    State*
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="state"
                                    type="text"
                                    placeholder="State"
                                    value={growerAccount.state}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'state',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="zipCode"
                                >
                                    Zip*
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="zipCode"
                                    type="text"
                                    placeholder="Zip Code"
                                    value={growerAccount.zipCode}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'zipCode',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="memberSince"
                                >
                                    Grower Member Since*
                                </label>
                                <DatePicker 
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="memberSince"
                                    placeholderText='Click to enter a date'
                                    selected={growerAccount.growerMemberSince} 
                                    onChange={(date):void => {
                                        this.setValue(
                                            'growerMemberSince',
                                            date,
                                        )
                                }} />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="growers"
                                >
                                    Grower Entities*
                                </label>
                                <Multiselect
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    options={users}
                                    selectedValues={growerAccount.growers}
                                    placeholder='Select Grower Entities'
                                    onSelect={(values): void =>
                                        this.setValue(
                                            'growers',
                                            values,
                                        )}
                                    onRemove={(values): void =>
                                        this.setValue(
                                            'growers',
                                            values,
                                        )}
                                    displayValue="name"
                                />
                            </div>
                        </div>

                        <div>
                            <h1 className="text-lg mb-1 text-gray-900 font-medium">Secondary Contact</h1>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactName"
                                    >
                                        Name
                                    </label>
                                    <input
                                        required
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactName"
                                        type="text"
                                        placeholder="Grower Account Name"
                                        value={growerAccount.secondaryContactName}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactName',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactEmail"
                                    >
                                        Email
                                    </label>
                                    <input
                                        required
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactEmail"
                                        type="text"
                                        placeholder="Email"
                                        value={growerAccount.secondaryContactEmail}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactEmail',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactNumber"
                                    >
                                        Phone #
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactNumber"
                                        type="tel"
                                        placeholder="Phone #"
                                        value={growerAccount.secondaryContactNumber}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactNumber',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactAddress"
                                    >
                                        Address
                                    </label>
                                    <input
                                        required
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactAddress"
                                        type="text"
                                        placeholder="Street address or P.O. Box"
                                        value={growerAccount.secondaryContactAddress}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactAddress',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactAddress2"
                                        type="text"
                                        placeholder="Apt, Suite, Unit, Building (optional)"
                                        value={growerAccount.secondaryContactAddress2}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactAddress2',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactCity"
                                    >
                                        City
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactCity"
                                        type="text"
                                        placeholder="City"
                                        value={growerAccount.secondaryContactCity}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactCity',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactState"
                                    >
                                        State
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactState"
                                        type="text"
                                        placeholder="State"
                                        value={growerAccount.secondaryContactState}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactState',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="secondaryContactZipCode"
                                    >
                                        Zip
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="secondaryContactZipCode"
                                        type="text"
                                        placeholder="Zip Code"
                                        value={growerAccount.secondaryContactZipCode}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'secondaryContactZipCode',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="py-2">
                        <Button
                            className="float-right"
                            onClick={this.saveChanges}
                            type="button"
                            disabled={!growerAccount.name || !growerAccount.email || !growerAccount.primaryNumber || !growerAccount.address || 
                                !growerAccount.city || !growerAccount.state || !growerAccount.zipCode || !growerAccount.growerMemberSince ||
                                !growerAccount.growers
                            }
                        >
                            Save Changes
                        </Button>
                        {growerAccountId && growerAccountId !== 'new' && (
                            <Button
                                className="mx-2 float-right"
                                onClick={this.delete}
                                type="button"
                                variant="delete"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GrowerAccountEditComponent);

import React, { Component, Fragment, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import { Api } from '@citrus-tracker/api';
import { PackingHouse, Role, User } from '@citrus-tracker/types';

import { Button } from '@citrus-tracker/ui';

interface PathParams {
    id: string;
}

/* eslint-disable-next-line */
export interface UserEditProps extends RouteComponentProps<PathParams> {
    userId: string;
}

export class UserEditComponent extends Component<UserEditProps> {
    props: Readonly<UserEditProps>;

    state: {
        userId: string;
        user: User;
        roles: Role[];
        packingHouses: PackingHouse[];
    } = {
        userId: '',
        user: {} as User,
        roles: [],
        packingHouses: [],
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;

        this.loadRoles();
        this.loadPackingHouses();

        if (id !== 'new') {
            this.setState({ userId: id });
            this.loadUser(id);
        }
    }

    loadRoles = async (): Promise<void> => {
        const roles = await Api.users.listRoles();

        this.setState({
            roles,
        });
    };

    loadPackingHouses = async (): Promise<void> => {
        const packingHouses = await Api.users.listPackingHouse();

        this.setState({
            packingHouses,
        });
    };

    loadUser = async (userId: string): Promise<void> => {
        const user = await Api.users.get(userId);

        this.setState({
            user: user,
        });
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    setValue = (property: keyof User, value: unknown): void => {
        const user = {
            ...this.state.user,
        };

        const newUser = {
            ...user,
            [property]: value,
            isAdmin: function (): boolean {
                throw new Error('Function not implemented.');
            },
            isGrower: function (): boolean {
                throw new Error('Function not implemented.');
            },
            isPackingHouseUser: function (): boolean {
                throw new Error('Function not implemented.');
            },
            isUser: function (): boolean {
                throw new Error('Function not implemented.');
            },
        };

        if (typeof value === 'string' && property === 'roles')
            newUser.roles = [
                this.state.roles.find((role) => role.role === value) ||
                    ({} as Role),
            ];
        if (typeof value === 'number' && property === 'packingHouse')
            newUser.packingHouse =
                this.state.packingHouses.find((ph) => ph.id === value) ||
                ({} as PackingHouse);

        this.setUser(newUser);
    };

    setUser = (user: User): void => {
        if (
            user &&
            !user.id &&
            (!user.title || user.title === '') &&
            user.roles &&
            user.roles[0].role === 'ROLE_GROWER'
        ) {
            user.title = 'Grower';
        }

        this.setState({
            user,
        });
    };

    delete = async (): Promise<void> => {
        if (
            this.state.user.id &&
            window.confirm(
                `Are you sure you want to delete ${this.state.user.firstName} ${this.state.user.lastName}`,
            ) === true
        ) {
            await Api.users.delete(this.state.user.id);
            this.props.history.push(`/users`);
        }
    };

    saveChanges = async (): Promise<void> => {
        if (this.state.user.roles && this.state.user.roles.length > 0) {
            const newUser = await Api.users.save(this.state.user);

            this.props.history.push(`/users`);

            if (this.state.user.roles[0].role === 'ROLE_GROWER') {
                this.props.history.push(`/users/${newUser.id}`);
            }
        }
    };

    render(): ReactElement {
        const { user, userId, roles, packingHouses } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                User
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            <div>
                                {user.id &&
                                    user.roles[0] &&
                                    user.roles[0].role === 'ROLE_GROWER' &&
                                    user.growers &&
                                    user.growers.length > 0 &&
                                    user.growers[0].ranches &&
                                    user.growers[0].ranches.length > 0 && (
                                        <Link
                                            to={`/blocks/${user.id}`}
                                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                        >
                                            Blocks
                                        </Link>
                                    )}

                                {user.id &&
                                    user.roles[0] &&
                                    user.roles[0].role === 'ROLE_GROWER' &&
                                    user.growers &&
                                    user.growers.length > 0 && (
                                        <Link
                                            to={`/ranches/${user.id}`}
                                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                        >
                                            Ranches
                                        </Link>
                                    )}

                                {user.id &&
                                    user.roles[0] &&
                                    user.roles[0].role === 'ROLE_GROWER' && (
                                        <Link
                                            to={`/growers/${user.id}`}
                                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                        >
                                            Entities
                                        </Link>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <form
                        className="w-full max-w-xlg rounded bg-white p-8"
                        id="form"
                    >
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="firstName"
                                >
                                    First Name
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="firstName"
                                    type="text"
                                    placeholder="First Name"
                                    value={user.firstName}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'firstName',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="lastName"
                                >
                                    Last Name
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="lastName"
                                    type="text"
                                    placeholder="Last Name"
                                    value={user.lastName}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'lastName',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="role"
                                >
                                    Role
                                </label>
                                <select
                                    required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="role"
                                    value={user.roles ? user.roles[0].role : ''}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'roles',
                                            e.currentTarget.value,
                                        )
                                    }
                                >
                                    <option value="">Select a Role</option>
                                    {roles.map((x) => (
                                        <option value={x.role}>{x.role}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            {user.roles &&
                                user.roles.length > 0 &&
                                user.roles[0].role !== 'ROLE_GROWER' && (
                                    <div className="w-full px-3">
                                        <label
                                            className="font-bold text-black"
                                            htmlFor="title"
                                        >
                                            Title
                                        </label>
                                        <input
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="title"
                                            type="text"
                                            placeholder="Title"
                                            value={user.title}
                                            onChange={(e): void =>
                                                this.setValue(
                                                    'title',
                                                    e.currentTarget.value,
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            {user.roles &&
                                user.roles.length > 0 &&
                                user.roles[0].role === 'ROLE_GROWER' && (
                                    <Fragment>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label
                                                className="font-bold text-black"
                                                htmlFor="title"
                                            >
                                                Title
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="title"
                                                type="text"
                                                placeholder="Title"
                                                value={user.title}
                                                onChange={(e): void =>
                                                    this.setValue(
                                                        'title',
                                                        e.currentTarget.value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label
                                                className="font-bold text-black"
                                                htmlFor="agreementNum"
                                            >
                                                Grower Agreement #
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="agreementNum"
                                                type="text"
                                                placeholder="Grower Agreement #"
                                                value={user.agreementNum}
                                                onChange={(e): void =>
                                                    this.setValue(
                                                        'agreementNum',
                                                        e.currentTarget.value,
                                                    )
                                                }
                                            />
                                        </div>
                                    </Fragment>
                                )}
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    required
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    value={user.email}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'email',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>

                        {user.roles &&
                            user.roles.length > 0 &&
                            user.roles[0].role === 'ROLE_PACKINGHOUSE' && (
                                <div className="flex flex-wrap -mx-3 mb-2">
                                    <div className="w-full px-3">
                                        <label
                                            className="font-bold text-black"
                                            htmlFor="packingHouse"
                                        >
                                            Packing House
                                        </label>
                                        <select
                                            required
                                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="packingHouse"
                                            value={
                                                user.packingHouse
                                                    ? user.packingHouse.id
                                                    : ''
                                            }
                                            onChange={(e): void =>
                                                this.setValue(
                                                    'packingHouse',
                                                    e.currentTarget.value,
                                                )
                                            }
                                        >
                                            <option value="">
                                                Select a Packing House
                                            </option>
                                            {packingHouses.map((x) => (
                                                <option value={x.id}>
                                                    {x.packing_house_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                    </form>

                    <div className="py-2">
                        <Link
                            to={'/users/'}
                            className="float-left cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right"
                        >
                            Back
                        </Link>
                        <Button
                            className="float-right"
                            onClick={this.saveChanges}
                            type="button"
                        >
                            Save Changes
                        </Button>
                        {userId && userId !== 'new' && (
                            <Button
                                className="mx-2 float-right"
                                onClick={this.delete}
                                type="button"
                                variant="delete"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UserEditComponent);

import React, { Component, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { Api } from '@citrus-tracker/api';
import {
    Block,
    BlockFilter,
    BlockFilterOptions,
    BlockOptions,
    User,
} from '@citrus-tracker/types';
import BlockForm from './form';
import Filter from '../filter/filter';

import './edit.module.scss';
import { Button } from '@citrus-tracker/ui';

interface PathParams {
    blockId: string;
}

/* eslint-disable-next-line */
export interface EditProps extends RouteComponentProps<PathParams> {
    growerId: string;
    filter: BlockFilter;
    filterOptions: BlockFilterOptions;
    loadBlocks(
        growerId: string,
        page: number,
        blocksPerPage: number,
    ): Promise<void>;
    blocks: Block[];
    blockOptions: BlockOptions;
    setError(message: string): void;
    setSuccess(message: string): void;
    totalBlocks: number;
    updateFilter(filter: BlockFilter): void;
    user?: User | null;
}

export class Edit extends Component<EditProps> {
    props: Readonly<EditProps>;

    state: {
        activeBlock?: Block;
        error: string;
        updates: Block;
    } = {
        error: '',
        updates: {} as Block,
    };

    componentDidMount(): void {
        this.loadPage();
    }

    componentDidUpdate(oldProps: EditProps): void {
        if (this.props.match.params.blockId !== oldProps.match.params.blockId) {
            this.loadPage();
        }
    }

    loadPage = async (): Promise<void> => {
        const { loadBlocks, growerId } = this.props;
        const { blockId } = this.props.match.params;

        if (!this.props.blocks || this.props.blocks.length === 0)
            await loadBlocks(growerId, 1, 10000);

        const { blocks } = this.props;

        if (blocks && blocks.length > 0) {
            this.setState({
                activeBlock: blocks.find((b) => b.id.toString() === blockId),
                updates: {},
            });
        }
    };

    saveChanges = async (): Promise<Block | null> => {
        const { setError, setSuccess } = this.props;
        const { updates, activeBlock } = this.state;
        if (!activeBlock) {
            return null;
        }

        try {
            updates.id = activeBlock.id;
            const block = await Api.blocks.save(updates);
            setSuccess('Saved Successfully');
            this.loadPage();
            this.props.history.goBack();
            return block;
        } catch (error) {
            setError(error as string);
            return null;
        }
    };

    setUpdates = (updates: Block): void => {
        this.setState({ updates });
    };

    openDelete = async (): Promise<void> => {
        if (
            window.confirm('Are you sure you want to delete this block?') &&
            this.state.activeBlock
        ) {
            await Api.blocks.delete(this.state.activeBlock.id);
            this.props.history.goBack();
        }
    };

    render(): ReactElement {
        const {
            filter,
            filterOptions,
            blocks,
            blockOptions,
            updateFilter,
            user,
        } = this.props;
        const { activeBlock } = this.state;

        return (
            <div className="block">
                <div className="block-header block-header-default">
                    <h3 className="block-title">{activeBlock?.grower?.name}</h3>
                </div>
                <div className="block-content">
                    <Filter
                        activeBlock={activeBlock}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                        isAdmin={user?.isAdmin()}
                    />
                </div>

                {activeBlock && (
                    <BlockForm
                        openDelete={this.openDelete}
                        block={activeBlock}
                        blockOptions={blockOptions}
                        saveChanges={this.saveChanges}
                        setUpdates={this.setUpdates}
                        user={user ?? undefined}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(Edit);

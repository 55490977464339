import React, {
    Component,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { Api } from '@citrus-tracker/api';
import {
    User,
    UserFilter,
    UserFilterOptions,
    UserOptions,
} from '@citrus-tracker/types';
import Filter, { FilterColumn } from './filter';
import { Table as UITable } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentUserIndex: number;
    filter: UserFilter;
    filterOptions: UserFilterOptions;
    Users: User[];
    UserOptions: UserOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalUsers: number;
    loadUsers(page: number, UsersPerPage: number): Promise<void>;
    updateFilter(filter: UserFilter): void;
    user?: User;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        page: number;
        UsersPerPage: number;
        userVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        page: 1,
        UsersPerPage: 15,
        userVisibleColumns: {
            name: true,
            title: true,
            email: true,
            role: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentUser();

        const columnString = localStorage.getItem('userVisibleColumns');
        if (columnString) {
            this.setState({ userVisibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );

        const page = localStorage.getItem('usersPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentUserIndex } = this.props;

        if (
            currentUserIndex &&
            (!oldProps.Users ||
                currentUserIndex !== oldProps.currentUserIndex)
        ) {
            this.goToCurrentUser();
        }
    }

    goToCurrentUser = (): void => {
        const { currentUserIndex, Users } = this.props;

        if (Users) {
            if (currentUserIndex) {
                this.goToUser(currentUserIndex);
            } else {
                this.goToUser(1);
            }
        }
    };

    goToPage = (newPage: number): void => {
        const { loadUsers } = this.props;
        const { UsersPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadUsers(newPage, UsersPerPage);
        
        //Store page in session so we can persist across page reloads
        localStorage.setItem('usersPage', JSON.stringify(newPage));
    };

    goToUser = (UserIndex: number): void => {
        const { UsersPerPage } = this.state;

        if (UsersPerPage) {
            const page = localStorage.getItem('usersPage') ?? Math.ceil(UserIndex / UsersPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    openEdit = (UserIndex?: number): void => {
        if (UserIndex) {
            this.props.history.push(`/Users/${UserIndex}/edit`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    toggleColumnVisibility = (property: keyof User): void => {
        const userVisibleColumns = {
            ...this.state.userVisibleColumns,
            [property]: !this.state.userVisibleColumns[property],
        };

        this.setState({
            userVisibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('userVisibleColumns', JSON.stringify(userVisibleColumns));
    };

    renderForDisplay = (
        user: User,
        property: keyof User,
    ): ReactElement => {
        switch (property) {
            case 'name':
                return <span>{user.firstName} {user.lastName}</span>;
            case 'role':
                return <span>{user.roles[0].role}</span>
            default:
                return <span>{user[property]}</span>;
        }
    };

    render(): ReactElement {
        const {
            Users,
            totalUsers,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            user,
        } = this.props;
        const {
            page,
            UsersPerPage,
            userVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalUsers / UsersPerPage);

        const indexBase = (page - 1) * UsersPerPage;

        const allColumns: {
            property: keyof User;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'name' as keyof User,
                title: 'Name',
                filter: true,
                sort: 'user.firstName',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'title' as keyof User,
                title: 'Title',
                filter: true,
                sort: 'user.title',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'email' as keyof User,
                title: 'Email',
                filter: true,
                sort: 'user.email',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'role' as keyof User,
                title: 'Role',
                filter: true,
                sort: 'roles.role',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
        ];

        const columns = allColumns.filter(
            (column) => userVisibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        const start = (page * UsersPerPage) - (UsersPerPage - 1);
        const end = Math.min(start + UsersPerPage - 1, totalUsers);

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={user?.isAdmin()}
                        visibleColumns={userVisibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={Users}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalUsers} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Table);

import React, { Component, ReactElement } from 'react';

import './form.module.scss';

import { Form } from '@citrus-tracker/layout/form';
import {
    FieldEstimate,
    FieldEstimateOptions,
    getFlatEstimateValue,
} from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';

/* eslint-disable-next-line */
export interface FormProps {
  onCancel?(): unknown;
  openDelete?(): void;
  estimate: FieldEstimate;
  estimateOptions: FieldEstimateOptions;
  saveChanges(): Promise<unknown>;
  setUpdates?(updates: FieldEstimate): void;
  fruitSizes: number[];
}

export class FieldEstimateForm extends Component<FormProps> {
  props: Readonly<FormProps>;

  state: {
    updates: FieldEstimate;
    radio: string;
  } = {
      updates: {} as FieldEstimate,
      radio: 'bins',
    };



  componentDidUpdate(oldProps: FormProps): void {
    if (this.props.estimate?.id !== oldProps.estimate?.id) {
      this.setUpdates({} as FieldEstimate);
    }
  }


  setUpdates = (updates: FieldEstimate): void => {
    const { setUpdates } = this.props;

    this.setState({
      updates,
    });

    if (setUpdates) {
      setUpdates(updates);
    }
  };



  setValue = (property: keyof FieldEstimate, value: unknown): void => {
    const updates = {
      ...this.state.updates,
    };

    if (
      property === 'total_bins' ||
      property === 'trees_per_bin' ||
      property === 'cartons_per_tree' ||
      property === 'fancy_percent' ||
      property === 'choice_percent' ||
      property === 'fruit_size_1' ||
      property === 'fruit_size_2' ||
      property === 'fruit_size_3' ||
      property === 'fruit_size_4' ||
      property === 'fruit_size_5' ||
      property === 'fruit_size_6' ||
      property === 'fruit_size_7' ||
      property === 'fruit_size_8' ||
      property === 'fruit_size_9' ||
      property === 'fruit_size_10' ||
      property === 'gibbed' ||
      property === 'priority'
    ) {
      if (typeof value === 'string') {
        value = parseInt(value);
      }
    }

    const newUpdates = {
      ...updates,
      [property]: value,
    };

    this.setUpdates(newUpdates);
  };

  getValue = (property: keyof FieldEstimate): string | number => {
    const { estimate } = this.props;


    const { updates } = this.state;

    return updates[property]
      ? getFlatEstimateValue(updates, property)
      : getFlatEstimateValue(estimate, property);
  };



  saveChanges = async (e?: { preventDefault(): void }): Promise<unknown> => {
    const { saveChanges } = this.props;

    if (e) {
      e.preventDefault();
    }

    return saveChanges();
  };


  render(): ReactElement {
    const { onCancel, openDelete, estimate, fruitSizes } = this.props;
    const { updates } = this.state


    return (
      <Form id="wizardForm" onSubmit={this.saveChanges}>
        <div className="flex flex-wrap bg-white mb-1 rounded -mt-10">
          <div className='w-full form-check form-check-inline float-right mb-2'>
            <label
              className='form-check-label text-gray-700 font-medium'
              htmlFor='reason'
            >
              Reason For Update
            </label>
            <input
              className='pl-2 w-full form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              value={this.getValue('reason')}
              onChange={(e): void =>
                this.setValue('reason', e.target.value)}
            >
            </input>
          </div>
          <div className='w-full mb-2'>
            <small className='text-gray-500 font-semibold'>ESTIMATE</small>
          </div>          
          <div className='mb-2 w-full flex flex-col'>
            <div className='inline-flex items-center mb-2'>
              <input
                className='form-radio text-green-600 min-w-max mr-2 w-4 h-4'
                type='radio'
                name='Estimate'
                onClick={(e): void =>
                  this.setState({radio: 'bins'})}                
              />
              <label
                className='text-xs mr-2'
                htmlFor='bins'
              >
                Bins
              </label>
              <input
                className='form-radio text-green-600 min-w-max mr-2 w-4 h-4'
                type='radio'
                name='Estimate'
                id='trees_per_bin'
                onClick={(e): void =>
                  this.setState({radio: 'trees_per_bin'})}
              />
              <label
                className='text-xs mr-2'
                htmlFor='trees_per_bin'
              >
                Trees per Bin Season
              </label>
              <input
                className='form-radio text-green-600 min-w-max mr-2 w-4 h-4'
                type='radio'
                name='Estimate'
                id='raw_cartons'
                onClick={(e): void =>
                  this.setState({radio: 'raw_cartons'})}
              />
              <label
                className='text-xs mr-2'
                htmlFor='raw_cartons'
              >
                Raw Cartons Per Tree Season
              </label>
            </div>
            <div className='mb-3'>
              { this.state.radio === 'bins' &&
              <input
                className='w-1/2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
                placeholder='Enter'
                checked
                value={estimate.total_bins}
              />
              }
              { this.state.radio === 'trees_per_bin' &&
              <input
                className='w-1/2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
                placeholder='Enter'
                value={estimate.trees_per_bin}
              />
              }
              { this.state.radio === 'raw_cartons' &&
              <input
                className='w-1/2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
                placeholder='Enter'
                value={estimate.cartons_per_tree}
              />
              }
            </div>            
          </div>
          <div className='w-full flex flex-row'>
            <small className='text-sm w-1/2 mr-2'>Utilization</small>
          </div>
          <div className='flex flex-row w-full mb-2'>
            <input
              className='pl-2 mr-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('utilization', e.target.value)}
            >
            </input>
          </div> 
          <div className='w-full'>
            <small className='text-gray-500 font-semibold'>MARKET: CARTONS</small>
          </div>
          <div className='w-full flex flex-row'>
            <small className='text-sm w-1/2 mr-2'>Export</small>
            <small className='text-sm w-1/2'>Domestic</small>
          </div>
          <div className='flex flex-row w-full mb-2'>
            <input
              className='pl-2 mr-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('export_percent', e.target.value)}
            >
            </input>
            <span
              className='pl-4 pt-2.5 form-check-input text-gray-700 leading-tight'
            >
              {100 - (updates.export_percent ?? 0)}%
            </span>
          </div> 
          <div className='w-full'>
            <small className='text-gray-500 font-semibold'>GRADE: CARTONS</small>
          </div>
          <div className='w-full flex flex-row'>
            <small className='text-sm w-1/2 mr-2'>Fancy</small>
            <small className='text-sm w-1/2'>Choice</small>
          </div>
          <div className='flex flex-row w-full mb-2'>
            <input
              className='pl-2 mr-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fancy_percent', e.target.value)}
            >
            </input>
            <span
              className='pl-4 pt-2.5 form-check-input text-gray-700 leading-tight'
            >
              {100 - (updates.fancy_percent ?? 0)}%
            </span>
          </div> 
          <div className='w-full'>
            <small className='text-gray-500 font-semibold'>FRUIT SIZE</small>
          </div>
          <div className='w-full flex flex-row'>
            <small className='text-sm w-1/4 mr-2'>{fruitSizes[0]}</small>
            <small className='text-sm w-1/4 mr-2'>{fruitSizes[1]}</small>
            <small className='text-sm w-1/4 mr-2'>{fruitSizes[2]}</small>
            <small className='text-sm w-1/4'>{fruitSizes[3]}</small>
          </div>
          <div className='w-full flex flex-row mb-2'>
            <input
              className='w-1/4 mr-2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_1', e.target.value)}
            />
            <input
              className='w-1/4 mr-2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_2', e.target.value)}
            />
            <input
              className='w-1/4 mr-2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_3', e.target.value)}
            />
            <input
              className='w-1/4 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void => 
                this.setValue('fruit_size_4', e.target.value)}
            />
          </div>
          <div className='w-full flex flex-row'>
            <small className='text-sm w-1/4 mr-2'>{fruitSizes[4]}</small>
            <small className='text-sm w-1/4 mr-2'>{fruitSizes[5]}</small>
            <small className='text-sm w-1/4 mr-2'>{fruitSizes[6]}</small>
            <small className='text-sm w-1/4'>{fruitSizes[7]}</small>
          </div>
          <div className='w-full flex flex-row mb-3'>
            <input
              className='w-1/4 mr-2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_5', e.target.value)}
            />
            <input
              className='w-1/4 mr-2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void => 
                this.setValue('fruit_size_6', e.target.value)}
            />
            <input
              className='w-1/4 mr-2 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_7', e.target.value)}
            />
            <input
              className='w-1/4 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_8', e.target.value)}
            />
            <input
              className='w-1/4 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_9', e.target.value)}
            />
            <input
              className='w-1/4 pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              type='number'
              max={100}
              onChange={(e): void =>
                this.setValue('fruit_size_10', e.target.value)}
            />
          </div>
          <div className='w-full'>
            <small className='text-gray-500 font-semibold'>HARVEST READINESS</small>
          </div>
          <div className='flex flex-row mb-2 w-1/2'>
            <div className='flex flex-row items-center w-1/2'>
              <label
                className='text-sm w-1/4'
                htmlFor='gibbed'
              >
                Gibbed:
              </label>
              <input
                className='form-check-input w-1/4'
                type='checkbox'
                name='gibbed'
                id='gibbed'
                onChange={(): void =>
                  this.setValue('gibbed', true)}
              />
            </div>
            <div className='flex flex-col w-1/2'>
              <small>Priority</small>
              <input
                className='pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
                placeholder='Enter'
                onChange={(e): void =>
                  this.setValue('priority', e.target.value)}
              />
            </div>
          </div>
          <div className='flex flex-col w-full mb-2'>
            <small className='text-sm'>Field Notes</small>
            <input
              className='w-full pl-2 form-check-input bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-gray-300 h-10'
              placeholder='Enter'
              onChange={(e): void =>
                this.setValue('notes', e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-12 text-right mt-1.5 w-full">
          {onCancel && (
            <Button
              type="button"
              onClick={onCancel}
              data-dismiss="modal"
              style={{ marginRight: 10 }}
              variant="secondary"
              className='font-bold'
            >
              Cancel
            </Button>
          )}
          <Button className="ml-2 float-right font-bold" type="submit">
            Save
          </Button>

          {openDelete && (
            <Button
              className="ml-2 float-right font-bold"
              onClick={openDelete}
              type="button"
              variant="delete"
            >
              Delete
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

export default FieldEstimateForm;

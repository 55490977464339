import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { NextLabel } from './users-table/nextLabel';
import { PreviousLabel } from './users-table/previousLabel';
import { Api } from '@citrus-tracker/api';
import { User, UserOptions, UserFilter, UserFilterOptions } from '@citrus-tracker/types';

import Table from './table';

import './user.module.scss';
const usersPerPage = 15;

/* eslint-disable-next-line */
export interface UserProps extends RouteComponentProps<{}> {
    user?: User | null;
}

export class UserComponent extends Component<UserProps> {
    props: Readonly<UserProps>;

    state: {
        currentUserIndex: number;
        errorAlert: string;
        filter: UserFilter;
        filterOptions: UserFilterOptions;
        users: User[];
        userOptions: UserOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalUsers: number;
        page: number;
    } = {
        currentUserIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        users: [],
        userOptions: {},
        sort: 'user.id',
        sortDirection: 'DESC',
        successAlert: '',
        totalUsers: 0,
        page: 1,
    };

    componentDidMount(): void {
        this.updateFilter({});

        this.loadUsers();
    }

    loadUsers = async (page = 1, usersPerPage = 15): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const users = await Api.users.list(
            page,
            usersPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalUsers: users.total,
            users: users.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadUsers();
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadUsers();
            });
        }
    };
    setCurrentUserIndex = (currentUserIndex: number): void => {
        this.setState({ currentUserIndex });
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadUsers();
            });
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.loadUsers(data.selected + 1, usersPerPage);
        this.setState({ page: data.selected + 1 });
    };

    updateFilter = (filter: UserFilter): void => {
        window.localStorage.setItem('userFilters', JSON.stringify(filter));

        this.setState({ filter }, () => {
            this.updateFilterOptions();
        });
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter } = this.state;
        const filterOptions = await Api.users.getFilterOptions(filter);
        this.setState({ filterOptions }, () => {
            this.loadUsers();
        });
    };

    render(): ReactElement {
        const {
            currentUserIndex,
            errorAlert,
            filter,
            filterOptions,
            users,
            userOptions,
            sort,
            sortDirection,
            successAlert,
            totalUsers,
        } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Users &amp; Groups
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            <Link
                                to="/users/new"
                                className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right ml-4"
                            >
                                <div className="mr-3">+</div>
                                <div>New</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <Table
                        currentUserIndex={currentUserIndex}
                        filter={filter}
                        filterOptions={filterOptions}
                        loadUsers={this.loadUsers}
                        Users={users}
                        UserOptions={userOptions}
                        reverseSort={this.reverseSort}
                        setSort={this.setSort}
                        sort={sort}
                        sortDirection={sortDirection}
                        totalUsers={totalUsers}
                        updateFilter={this.updateFilter}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(UserComponent);

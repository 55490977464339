import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './fruit-table.scss';
import { Record, FieldEstimate } from '@citrus-tracker/types';

export interface TableProps extends RouteComponentProps {
    record: Record;
    cartonsPerBin: number;
    fruitSizes: number[];
    updateActiveRecord(property: keyof FieldEstimate, value: unknown): void;
}

export class FruitTable extends Component<TableProps> {
    props: Readonly<TableProps>;

    state: {
        editingRecord: Record;
    } = {
        editingRecord: {},
    };
    
    componentDidMount(): void {
        this.loadPage();
    }

    componentDidUpdate(oldProps: TableProps): void {
        if (
            this.props.record !==
            oldProps.record
        ) {
            this.loadPage();
        }
    }

    loadPage(): void {
        this.setState({ editingRecord: this.props.record });
    }

    updateActiveRecord = (
        property: keyof FieldEstimate,
        value: unknown,
    ): void => {
        const updates = {
            ...this.state.editingRecord,
        };

        const newUpdates = {
            ...updates,
        };

        if (
            property === 'fruit_size_1' ||
            property === 'fruit_size_2' ||
            property === 'fruit_size_3' ||
            property === 'fruit_size_4' ||
            property === 'fruit_size_5' ||
            property === 'fruit_size_6' ||
            property === 'fruit_size_7' ||
            property === 'fruit_size_8' ||
            property === 'fruit_size_9' ||
            property === 'fruit_size_10' 
          ) {
            if (typeof value === 'string') {
              value = parseInt(value === '' || !value ? '0' : value);
            }
          }

        if (newUpdates.fieldEstimate)
            (newUpdates.fieldEstimate[property] as unknown) = value;

        this.setState({
            editingRecord: newUpdates,
        });

        this.props.updateActiveRecord(property, value);
    };

    calcTotal(): number {
        const { editingRecord } = this.state;
        return (
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_1 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_2 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_3 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_4 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_5 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_6 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_7 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_8 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_9 ?? 0).toString(),
                ) +
                parseInt(
                    (editingRecord.fieldEstimate?.fruit_size_10 ?? 0).toString(),
                )
        );
    }

    render(): ReactElement {
        const { editingRecord } = this.state;
        const { cartonsPerBin, fruitSizes } = this.props;

        return (
            <div className="block" style={{ height: 435 }}>
                <div
                    className="block-content overflow-hidden"
                    style={{ height: 435 }}
                >
                    <p>
                        <strong>Fruit Size</strong>
                    </p>
                    <table className="table table-fixed divide-y divide-gray-200">
                        <thead className="bg-gray-50 text-xs text-gray-500 h-10">
                            <tr>
                                <th className="text-left">FRUIT SIZE</th>
                                <th className="text-center w-40">PERCENTAGE</th>
                                <th className="text-right w-60" />
                                <th
                                    className="text-right"
                                    style={{ width: 50 }}
                                >
                                    REMAINING FRESH CARTONS
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[0]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_1"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_1',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_1
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right mx-5">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_1 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_1 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[1]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_2"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_2',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_2
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_2 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_2 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[2]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_3"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_3',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_3
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_3 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_3 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[3]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_4"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_4',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_4
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_4 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_4 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[4]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_5"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_5',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_5
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_5 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_5 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[5]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_6"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_6',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_6
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_6 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_6 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[6]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_7"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_7',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_7
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_7 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_7 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[7]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_8"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_8',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_8
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_8 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_8 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[8]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_9"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_9',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_9
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_9 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_9 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            { fruitSizes[9] && <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    {fruitSizes[9]}
                                </td>
                                <td className="bg-white text-center">
                                    <input
                                        id="fruit_size_10"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fruit_size_10',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fruit_size_10
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white text-right">
                                    <div className="w-full bg-gray-200 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 rounded-l-full h-3"
                                            style={{
                                                width:
                                                    240 *
                                                    ((editingRecord
                                                        .fieldEstimate
                                                        ?.fruit_size_10 ?? 0) /
                                                        100),
                                            }}
                                        ></div>
                                    </div>
                                </td>
                                <td className="bg-white text-right right-0">
                                    {(((((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.fruit_size_10 ?? 0)) /
                                        100) *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr> }
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white text-left">
                                    <strong>Total</strong>
                                </td>
                                <td
                                    className={
                                        'bg-white text-right text-wrap-break-word px-5 ' +
                                        (this.calcTotal() !== 100
                                            ? 'text-red-600'
                                            : '')
                                    }
                                >
                                    {this.calcTotal()}%
                                </td>
                                <td className="bg-white text-right"></td>
                                <td className="bg-white text-right right-0">
                                    {(((editingRecord.fieldEstimate
                                        ?.total_bins ?? 0) *
                                        cartonsPerBin *
                                        (editingRecord.fieldEstimate
                                            ?.utilization ?? 0)) /
                                        100).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr />
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(FruitTable);

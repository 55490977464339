import { Component } from 'react';

import './logout.module.scss';

export class Logout extends Component {
    props: Readonly<{
        logout(): void;
    }>;
    componentDidMount(): void {
        this.props.logout();
    }
    render(): null {
        return null;
    }
}

export default Logout;

import * as React from 'react';
import { Component, ReactElement, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import {
    BrowserRouter,
    Route,
    Link,
    Redirect,
    NavLink,
    useHistory,
} from 'react-router-dom';

import { Api } from '@citrus-tracker/api';
import { User } from '@citrus-tracker/types';
import Logo from './sunkistheaderlogo.png';
import Default from './default.jpg';
import { Login } from './login/login';
import { Logout } from './logout/logout';
import { PasswordReset } from './password-reset/password-reset';
import { CreateAccount } from './create-account/create-account';
import RecordComponent from './record/record';
import { environment } from '../environments/environment';
import Agreements from './agreements/agreements';
import AgreementDetail from './agreements/agreementDetail/agreementDetail';
import GrowerComponent from './grower/growers';
import GrowerEditComponent from './grower/edit/grower-edit';
import RanchComponent from './ranch/ranches';
import RanchEditComponent from './ranch/edit/ranch-edit';
import EstimateComponent from './field-estimate/field-estimate';
import { UserComponent } from './user/user';
import { UserEditComponent } from './user/edit/user-edit';
import { CreateAgreement } from './agreements/createAgreement.tsx/createAgreement';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import BlockComponent from './block/block';
import ImageUpload from './image-upload/image-upload';
import PackinghouseComponent from './packinghouse/packinghouse';
import PackinghouseEditComponent from './packinghouse/edit/packinghouse-edit';
import GrowerAccountComponent from './grower-accounts/grower-accounts';
import GrowerAccountEditComponent from './grower-accounts/edit/grower-account-edit';

export class App extends Component {
    state: {
        forgotEmail: string;
        createToken: string;
        resetToken: string;
        newPassword: string;
        email: string;
        password: string;
        user: User | null;
        loadingUser: boolean;
        // Manually handling the small number of bootstrap javascript needed.  Replace this with a reusable component when re-designing.
        pagesOpen: boolean;
        navOpen: boolean;
        navOpenMobile: boolean;
    } = {
        forgotEmail: '',
        createToken: '',
        resetToken: '',
        newPassword: '',
        email: '',
        password: '',
        user: null,
        loadingUser: true,
        pagesOpen: false,
        navOpen: true,
        navOpenMobile: true,
    };

    componentDidMount(): void {
        this.loadUser();
    }

    setParentState = (passedState: { [key: string]: unknown }): void => {
        this.setState(passedState);
    };

    loadUser = async (): Promise<boolean> => {
        try {
            const user = await Api.users.me();

            this.setState({
                user,
                loadingUser: false,
            });
        } catch (error) {
            this.setState({ user: false, loadingUser: false });
            return false;
        }
        return true;
    };

    submitForgot = (): void => {
        Api.auth.password.forgot(this.state.forgotEmail);
    };

    submitReset = async (): Promise<boolean> => {
        await Api.auth.password.reset(
            this.state.resetToken,
            this.state.newPassword,
        );
        return this.loadUser();
    };

    submitCreate = async (): Promise<boolean> => {
        await Api.users.create(this.state.createToken, this.state.newPassword);
        return this.loadUser();
    };

    submitLogin = async (): Promise<boolean> => {
        await Api.auth.login(this.state.email, this.state.password);
        window.location.href = '/agreements';
        return this.loadUser();
    };

    logout = async (): Promise<boolean> => {
        Api.auth.logout();

        window.localStorage.clear();

        return this.loadUser();
    };

    upload = async (file: File): Promise<boolean> => {
        if (this.state.user && this.state.user.id) {
            Api.users.upload(this.state.user.id, file);
        }

        window.history.back();

        setTimeout(() => {
            window.location.reload();
        }, 100);

        return this.loadUser();
    };

    cancelUpload = (): void => {
        window.history.back();
    };

    toggleNav = (e: { preventDefault(): void }): void => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { navOpen } = this.state;
        this.setState({ navOpen: !navOpen });
    };

    togglePages = (e: { preventDefault(): void }): void => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { pagesOpen } = this.state;
        this.setState({ pagesOpen: !pagesOpen });
    };

    navigation = [
        { name: 'Agreements', href: '/agreements', role: 'ROLE_ADMIN' },
        { name: 'Agreements', href: '/agreements', role: 'ROLE_GROWER' },
        { name: 'Agreements', href: '/agreements', role: 'ROLE_USER' },
        { name: 'Agreements', href: '/agreements', role: 'ROLE_PACKINGHOUSE' },
        { name: 'Packing House Blocks', href: '/records', role: 'ROLE_ADMIN' },
        { name: 'Blocks', href: '/records', role: 'ROLE_PACKINGHOUSE' },
        { name: 'Blocks', href: '/records', role: 'ROLE_USER' },
        { name: 'Grower Blocks', href: '/blocks', role: 'ROLE_ADMIN' },
        { name: 'Grower Blocks', href: '/blocks', role: 'ROLE_PACKINGHOUSE' },
        { name: 'Field Estimates', href: '/estimates', role: 'ROLE_ADMIN' },
        { name: 'Field Estimates', href: '/estimates', role: 'ROLE_USER' },
        { name: 'Field Estimates', href: '/estimates', role: 'ROLE_PACKINGHOUSE' },
        { name: 'Users', href: '/users', role: 'ROLE_ADMIN' },
        { name: 'Ranches', href: '/ranches', role: 'ROLE_GROWER' },
        { name: 'Ranches', href: '/ranches', role: 'ROLE_ADMIN' },
        { name: 'Ranches', href: '/ranches', role: 'ROLE_PACKINGHOUSE' },
        { name: 'Blocks', href: '/blocks', role: 'ROLE_GROWER' },
        { name: 'Entities', href: '/growers', role: 'ROLE_GROWER' },
        { name: 'Entities', href: '/growers', role: 'ROLE_ADMIN' },
        { name: 'Entities', href: '/growers', role: 'ROLE_PACKINGHOUSE' },
        { name: 'Packing Houses', href: '/packinghouses', role: 'ROLE_ADMIN' },
        { name: 'Grower Accounts', href: '/growerAccounts', role: 'ROLE_ADMIN' },
    ];
    userNavigation = [
        { name: 'Update Image', href: '/image' },
        { name: 'Sign out', href: '/logout' },
    ];

    classNames = (...classes: any[]): string => {
        return classes.filter(Boolean).join(' ');
    };

    render(): ReactElement {
        const { user, loadingUser } = this.state;
        let profileImage = '';
        if (user?.image) {
            const arrayBufferView = new Uint8Array(user?.image.data);
            const blob = new Blob([arrayBufferView], {
                type: 'image/jpeg',
            });
            const urlCreator = window.URL || window.webkitURL;
            profileImage = urlCreator.createObjectURL(blob);
        }

        return (
            <BrowserRouter>
                <div id="page-container" className="bg-gray-100">
                    <Helmet>
                        <title>{environment.title}</title>
                    </Helmet>

                    {!user &&
                        !loadingUser &&
                        window.location.pathname !== '/password/create' &&
                        window.location.pathname !== '/password/reset' && (
                            <Redirect to="/login" />
                        )}

                    {user &&
                        !loadingUser &&
                        this.state.user?.roles[0].role ===
                            'ROLE_PACKINGHOUSE' &&
                        window.location.pathname === '/' && (
                            <Redirect to="/agreements" />
                        )}

                    {user && (
                        <div>
                            <div className="min-h-screen bg-gray-100">
                                <Disclosure
                                    as="nav"
                                    className="bg-white shadow-sm"
                                >
                                    {({ open }) => (
                                        <div>
                                            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                                                <div className="flex justify-between h-16">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0 flex items-center">
                                                            <Link to="/records">
                                                                <img
                                                                    alt="CitrusTracker"
                                                                    src={Logo}
                                                                    width="165"
                                                                    height="43.25"
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                                            {this.navigation.map(
                                                                (item) => {
                                                                    if (
                                                                        item.role ===
                                                                            undefined ||
                                                                        item.role ===
                                                                            user
                                                                                .roles[0]
                                                                                .role
                                                                    ) {
                                                                        return (
                                                                            <NavLink
                                                                                key={
                                                                                    item.name
                                                                                }
                                                                                to={
                                                                                    item.href
                                                                                }
                                                                                activeClassName="border-indigo-500 text-gray-900"
                                                                                className={this.classNames(
                                                                                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                                                    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                                                                                )}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </NavLink>
                                                                        );
                                                                    }
                                                                    return '';
                                                                },
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                                        {/* Profile dropdown */}
                                                        <Menu
                                                            as="div"
                                                            className="ml-3 relative"
                                                        >
                                                            {({ open }) => (
                                                                <>
                                                                    <div>
                                                                        <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                                            <span className="sr-only">
                                                                                Open
                                                                                user
                                                                                menu
                                                                            </span>
                                                                            <img
                                                                                className="h-12 w-12 rounded-full"
                                                                                src={
                                                                                    profileImage ||
                                                                                    Default
                                                                                }
                                                                                alt=""
                                                                                title={`${user.firstName} ${user.lastName} \n${user.email}`}
                                                                            />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        show={
                                                                            open
                                                                        }
                                                                        as={
                                                                            Fragment
                                                                        }
                                                                        enter="transition ease-out duration-200"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items
                                                                            static
                                                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                        >
                                                                            {this.userNavigation.map(
                                                                                (
                                                                                    item,
                                                                                ) => (
                                                                                    <Menu.Item
                                                                                        key={
                                                                                            item.name
                                                                                        }
                                                                                    >
                                                                                        {({
                                                                                            active,
                                                                                        }) => (
                                                                                            <Link
                                                                                                to={
                                                                                                    item.href
                                                                                                }
                                                                                                className={this.classNames(
                                                                                                    active
                                                                                                        ? 'bg-gray-100'
                                                                                                        : '',
                                                                                                    'block px-4 py-2 text-sm text-gray-700',
                                                                                                )}
                                                                                            >
                                                                                                {
                                                                                                    item.name
                                                                                                }
                                                                                            </Link>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                ),
                                                                            )}
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </>
                                                            )}
                                                        </Menu>
                                                    </div>
                                                    <div className="-mr-2 flex items-center sm:hidden">
                                                        {/* Mobile menu button */}
                                                        <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                            <span className="sr-only">
                                                                Open main menu
                                                            </span>
                                                            {open ? (
                                                                <XIcon
                                                                    className="block h-6 w-6"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <MenuIcon
                                                                    className="block h-6 w-6"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </Disclosure.Button>
                                                    </div>
                                                </div>
                                            </div>

                                            <Disclosure.Panel className="sm:hidden">
                                                <div className="pt-2 pb-3 space-y-1">
                                                    {this.navigation.map(
                                                        (item) => {
                                                            if (
                                                                item.role ===
                                                                    undefined ||
                                                                item.role ===
                                                                    user
                                                                        .roles[0]
                                                                        .role
                                                            ) {
                                                                return <Link
                                                                    key={item.name}
                                                                    to={item.href}
                                                                    className={this.classNames(
                                                                        'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            }
                                                            
                                                            return '';
                                                        },
                                                    )}
                                                </div>
                                                <div className="pt-4 pb-3 border-t border-gray-200">
                                                    <div className="flex items-center px-4">
                                                        <div className="flex-shrink-0">
                                                            <img
                                                                className="h-10 w-10 rounded-full"
                                                                src={
                                                                    profileImage ||
                                                                    Default
                                                                }
                                                                alt=""
                                                                title={`${user.firstName} ${user.lastName} \n${user.email}`}
                                                            />
                                                        </div>
                                                        <div className="ml-3">
                                                            <div className="text-base font-medium text-gray-800">
                                                                {user.firstName}{' '}
                                                                {user.lastName}
                                                            </div>
                                                            <div className="text-sm font-medium text-gray-500">
                                                                {user.email}
                                                            </div>
                                                        </div>
                                                        <button className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                            <span className="sr-only">
                                                                View
                                                                notifications
                                                            </span>
                                                            <BellIcon
                                                                className="h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div className="mt-3 space-y-1">
                                                        {this.userNavigation.map(
                                                            (item) => (
                                                                <Link
                                                                    key={
                                                                        item.name
                                                                    }
                                                                    to={
                                                                        item.href
                                                                    }
                                                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            </Disclosure.Panel>
                                        </div>
                                    )}
                                </Disclosure>

                                <div className="py-10">
                                    <main id="main-container">
                                        <div className="content">
                                            <Route
                                                path="/records"
                                                render={(): ReactElement => (
                                                    <RecordComponent
                                                        user={user}
                                                    />
                                                )}
                                            />

                                            <Route
                                                path="/blocks"
                                                render={(): ReactElement => (
                                                    <BlockComponent
                                                        user={user}
                                                    />
                                                )}
                                            />

                                            <Route
                                                path="/estimates"
                                                render={(): ReactElement => (
                                                    <EstimateComponent
                                                        user={user}
                                                    />
                                                )}
                                            />

                                            <Route
                                                path="/users"
                                                exact
                                                component={UserComponent}
                                            />

                                            <Route
                                                path="/users/:id"
                                                component={UserEditComponent}
                                            />

                                            <Route
                                                path="/logout"
                                                render={(): ReactElement => (
                                                    <Logout
                                                        logout={this.logout}
                                                    />
                                                )}
                                            />

                                            <Route
                                                path="/image"
                                                render={(): ReactElement => (
                                                    <ImageUpload
                                                        upload={this.upload}
                                                        cancel={
                                                            this.cancelUpload
                                                        }
                                                    />
                                                )}
                                            />

                                            {this.state.user?.roles[0].role ===
                                                'ROLE_GROWER' &&
                                                (!this.state.user?.growers ||
                                                    this.state.user?.growers
                                                        .length === 0) && (
                                                    <Route path="/">
                                                        <Redirect to="/growers/new" />
                                                    </Route>
                                                )}

                                            <Route path="/agreements" exact>
                                                <Agreements user={user} />
                                            </Route>

                                            <Route
                                                path="/agreements/add"
                                                component={CreateAgreement}
                                                exact
                                            />

                                            <Route
                                                path="/agreements/add/:growerId"
                                                component={CreateAgreement}
                                                exact
                                            />

                                            <Route
                                                path="/agreements/details/:id"
                                                exact
                                            >
                                                <AgreementDetail user={user} />
                                            </Route>

                                            <Route path="/growers" exact>
                                                <GrowerComponent user={user} />
                                            </Route>
                                            <Route path="/growers/:id" exact>
                                                <GrowerComponent user={user} />
                                            </Route>

                                            <Route path="/growerAccounts" exact>
                                                <GrowerAccountComponent user={user} />
                                            </Route>
                                            <Route path="/growerAccounts/:id" exact>
                                                <GrowerAccountEditComponent user={user} />
                                            </Route>

                                            <Route path="/packinghouses" exact>
                                                <PackinghouseComponent user={user} />
                                            </Route>
                                            <Route path="/packinghouses/new" exact>
                                                <PackinghouseEditComponent user={user} />
                                            </Route>
                                            <Route path="/packinghouses/:id/edit" exact>
                                                <PackinghouseEditComponent user={user} />
                                            </Route>
                                            <Route
                                                path="/growers/:userId/edit/:id"
                                                exact
                                            >
                                                <GrowerEditComponent
                                                    user={user}
                                                />
                                            </Route>

                                            <Route path="/ranches" exact>
                                                <RanchComponent user={user} />
                                            </Route>
                                            <Route path="/ranches/:growerId" exact>
                                                <RanchComponent user={user} />
                                            </Route>
                                            <Route
                                                path="/ranches/:userId/edit/:id"
                                                exact
                                            >
                                                <RanchEditComponent
                                                    user={user}
                                                />
                                            </Route>
                                        </div>
                                    </main>
                                </div>
                            </div>

                            <header id="page-header">
                                <div className="content-header">
                                    <div className="content-header-section">
                                        <button
                                            type="button"
                                            className="btn btn-circle btn-dual-secondary"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                            onClick={this.toggleNav}
                                        >
                                            <i className="fa fa-navicon"></i>
                                        </button>
                                    </div>
                                </div>
                            </header>

                            <footer id="page-footer" className="opacity-0">
                                <div className="content py-20 font-size-xs clearfix">
                                    <div className="float-left">
                                        <a className="font-w600" href="#">
                                            CitrusTracker
                                        </a>{' '}
                                        &copy;{' '}
                                        <span className="js-year-copy"></span>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    )}
                    {!user && (
                        <div>
                            <main id="main-container">
                                <Route
                                    path="/login"
                                    render={(): ReactElement => (
                                        <Login
                                            submitLogin={this.submitLogin}
                                            setParentState={this.setParentState}
                                            user={user}
                                        />
                                    )}
                                />
                                <Route
                                    path="/password/reset"
                                    render={(): ReactElement => (
                                        <PasswordReset
                                            setParentState={this.setParentState}
                                            submitReset={this.submitReset}
                                            submitForgot={this.submitForgot}
                                        />
                                    )}
                                />
                                <Route
                                    path="/password/create"
                                    render={(): ReactElement => (
                                        <CreateAccount
                                            setParentState={this.setParentState}
                                            submitCreate={this.submitCreate}
                                        />
                                    )}
                                />
                            </main>
                        </div>
                    )}
                </div>
            </BrowserRouter>
        );
    }
}

export default App;

import {
    Crop,
    PackingHouse,
    PackingHouseFilter,
    PackingHouseFilterOptions,
} from '@citrus-tracker/types';
import React, { Component, ReactElement, RefObject } from 'react';
import {
    Button,
    DropdownMenu,
    DynamicIcon,
    DynamicOutlineIcon,
} from '@citrus-tracker/ui';

/* eslint-disable-next-line */
export interface FilterProps {
    activePackingHouse?: PackingHouse;
    filter: PackingHouseFilter;
    filterOptions: PackingHouseFilterOptions;
    nextPage?(): void;
    prevPage?(): void;
    updateFilter(newOptions: PackingHouseFilter): void;
    columns?: {
        property: keyof PackingHouse;
        title: string;
    }[];
    toggleColumnVisibility?(property: keyof PackingHouse): unknown;
    isAdmin?: boolean;
    visibleColumns?: {
        [key: string]: boolean;
    };
    Api?: any;
}

export class Filter extends Component<FilterProps> {
    columnDropdownRef: RefObject<HTMLDivElement>;
    columnDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<FilterProps>;

    constructor(props: FilterProps) {
        super(props);

        this.columnDropdownRef = React.createRef();
        this.columnDropdownTriggerRef = React.createRef();
    }

    clearFilters = (): void => {
        const { updateFilter } = this.props;
        updateFilter({});
        
        localStorage.setItem('packinghousesPage', JSON.stringify(1));
    };

    setFilterOption = (
        property: string,
        value: string | number | PackingHouse | object | undefined,
    ): void => {
        const { filter, updateFilter } = this.props;

        updateFilter({
            ...filter,
            [property]: value,
        });
    };

    render(): ReactElement {
        const {
            filter,
            columns,
            toggleColumnVisibility,
            visibleColumns,
        } = this.props;

        return (
            <div className="grid grid-cols-3 gap-4 mb-4 items-end">
                <div className="col-span-2">
                    <div className="grid grid-cols-3 gap-4">
                        
                    </div>
                </div>

                <div className="col-span-1 text-right">
                    {toggleColumnVisibility && (
                        <div className="relative float-right">
                            <DropdownMenu
                                icon="CogIcon"
                                items={columns?.map((column) => (
                                    <label
                                        key={column.property}
                                        htmlFor={`${column.property}Visible`}
                                        className="pr-4 block whitespace-nowrap"
                                    >
                                        <input
                                            type="checkbox"
                                            id={`${column.property}Visible`}
                                            checked={
                                                visibleColumns &&
                                                visibleColumns[column.property]
                                            }
                                            onChange={(): unknown =>
                                                toggleColumnVisibility(
                                                    column.property,
                                                )
                                            }
                                        />{' '}
                                        {column.title}
                                    </label>
                                ))}
                                showArrow={false}
                            />
                        </div>
                    )}

                    {(Object.keys(filter).length > 0 || ( localStorage.getItem('packinghousesPage') !== undefined && parseInt(localStorage.getItem('packinghousesPage')?.toString() ?? '0') > 1 )) && (
                        <Button
                            className="float-right ml-4"
                            type="submit"
                            onClick={this.clearFilters}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

/* eslint-disable-next-line */
export interface FilterColumnProps {
    column: { property: string };
    filter: PackingHouseFilter;
    filterOptions: PackingHouseFilterOptions;
    updateFilter(newOptions: PackingHouseFilter): void;
}

export class FilterColumn extends Component<FilterColumnProps> {
    columnDropdownRef: RefObject<HTMLDivElement>;
    columnDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<FilterColumnProps>;

    state: {
        filterOpen: boolean;
    } = {
        filterOpen: false,
    };

    constructor(props: FilterColumnProps) {
        super(props);

        this.columnDropdownRef = React.createRef();
        this.columnDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    openFilter = () => {
        this.setState({ filterOpen: true });
    };

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.columnDropdownRef &&
            !this.columnDropdownRef.current?.contains(e.target as Node) &&
            this.columnDropdownTriggerRef &&
            !this.columnDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ filterOpen: false });
        }
    };

    setFilterOption = (
        property: string,
        value: string | number | Crop | PackingHouse | object | undefined,
    ): void => {
        const { filter, updateFilter } = this.props;

        this.setState({ filterOpen: false });

        updateFilter({
            ...filter,
            [property]: value,
        });
    };

    render(): ReactElement {
        const { column, filter, filterOptions } = this.props;
        const { filterOpen } = this.state;

        return (
            <div
                className={
                        filter
                        ? 'filtered inline-block w-5 h-5 align-middle'
                        : 'inline-block w-5 h-5 align-middle'
                }
            >
                <label
                    htmlFor={column.property + 'Filter'}
                    onClick={this.openFilter}
                    className="w-5 h-5"
                >
                    { // @ts-ignore 
                        filter[column.property] ? (
                        <DynamicIcon icon="FilterIcon" />
                    ) : (
                        <DynamicOutlineIcon icon="FilterIcon" />
                    )}
                </label>
                {filterOpen && (
                    <div className="filterWrapper relative">
                        <select
                            // @ts-ignore
                            size={filterOptions[column.property]?.length + 1}
                            // @ts-ignore
                            ref={this.columnDropdownRef}
                            className="form-control"
                            style={{ maxHeight: 600 }}
                            id={column.property + 'Filter'}
                            name={column.property + 'Filter'}
                            onChange={(e): void => {
                                this.setFilterOption(
                                    column.property,
                                    e.target.value,
                                );
                            }}
                            value={
                                 // @ts-ignore
                                filter[column.property]
                            }
                        >
                            <option value="">All</option>
                            {
                                // @ts-ignore
                                filterOptions[column.property]?.map(function (
                                    // @ts-ignore
                                    key,
                                    // @ts-ignore
                                    index,
                                ) {
                                    return (
                                        <option
                                            key={index}
                                            value={
                                                column.property === 'district'
                                                    ? key.value
                                                    : key.value.id
                                            }
                                        >
                                            {key.label}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>
                )}
            </div>
        );
    }
}

export default Filter;

import { Api } from '@citrus-tracker/api';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Agreement } from '@citrus-tracker/types';
import iconTick from '../assets/iconTick.png';
import './table.scss';
import iconRight from '../assets/iconRight.png';
import { Block } from '../block';
import { Button, Table as UITable } from '@citrus-tracker/ui';
import { PreviousLabel } from '../../../table/previousLabel';
import { NextLabel } from '../../../table/nextLabel';

export type Column = {
    title: string;
    sortable: boolean;
};
export type Row = {
    line: RowDetail[];
    rowId: string;
};
export type RowDetail = {
    information: string;
    onClickAction: (value?: any, id?: any) => any;
};
export interface ITable {
    resultsPerPage: number;
    totalResults: number;
    paginationClick: any;
    columnTitles: Column[];
    rows: Row[];
}
export const BlocksTable = (parameters: ITable) => {
    const page = 1;
    const [toAdd, setToAdd] = useState<number>(0);

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-middle inline-block min-w-full">
                    <div className="shadow bg-white overflow-hidden border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {parameters.columnTitles.map((col, i) => (
                                        <th
                                            className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider position-relative whitespace-nowrap"
                                            key={i}
                                        >
                                            {col.title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {parameters.rows.map((el, i) => (
                                    <tr
                                        className={
                                            i % 2 === 0
                                                ? 'bg-white'
                                                : 'bg-gray-50'
                                        }
                                        key={i}
                                    >
                                        {el.line.map((detail, i) => {
                                            return detail.information ===
                                                'AddAcres' ? (
                                                <td
                                                    key={i}
                                                    className="px-6 py-4 whitespace-nowrap text-gray-500"
                                                >
                                                    <div>
                                                        <input
                                                            type="number"
                                                            step={0.01}
                                                            placeholder="Enter a number to add"
                                                            onChange={(e) =>
                                                                setToAdd(
                                                                    +e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <button
                                                            className="ml-1"
                                                            onClick={() => {
                                                                if (
                                                                    toAdd &&
                                                                    toAdd > 0
                                                                ) {
                                                                    detail.onClickAction(
                                                                        toAdd,
                                                                        el.rowId,
                                                                    );
                                                                    setToAdd(0);
                                                                }
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td
                                                    className="px-6 py-4 whitespace-nowrap text-gray-500"
                                                    key={i}
                                                    onClick={
                                                        detail.onClickAction
                                                    }
                                                >
                                                    {detail.information}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pt-3 border-t-2 flex flex-col items-center m-0 mt-4 sm:space-x-4 sm:m-4 sm:flex-row ">
                            <div className="flex-1">
                                Showing{' '}
                                {(page - 1) *
                                    (parameters.totalResults > 0
                                        ? parameters.resultsPerPage + 1
                                        : 0)}{' '}
                                to{' '}
                                {parameters.totalResults >
                                parameters.resultsPerPage
                                    ? page * parameters.resultsPerPage
                                    : parameters.totalResults}{' '}
                                of {parameters.totalResults} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={<PreviousLabel />}
                                        nextLabel={<NextLabel />}
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={
                                            parameters.totalResults > 0
                                                ? Math.ceil(
                                                      parameters.totalResults /
                                                          parameters.resultsPerPage,
                                                  )
                                                : 1
                                        }
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={
                                            parameters.paginationClick
                                        }
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

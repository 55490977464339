import React, { Component, ReactElement } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Agreement, User, AgreementOptions, AgreementFilter, AgreementFilterOptions } from '@citrus-tracker/types';
import Table from './table';

import { Api } from '@citrus-tracker/api';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface AgreementProps extends RouteComponentProps<{}> {
    user?: User | null;
}

export class Agreements extends Component<AgreementProps> {
    props: Readonly<AgreementProps>;

    state: {
        agreementId: string;
        currentAgreementIndex: number;
        errorAlert: string;
        filter: AgreementFilter;
        filterOptions: AgreementFilterOptions;
        agreements: Agreement[];
        agreementOptions: AgreementOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalAgreements: number;
        page: number;
    } = {
        agreementId: '',
        currentAgreementIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        agreements: [],
        agreementOptions: {},
        sort: 'agreement.id',
        sortDirection: 'DESC',
        successAlert: '',
        totalAgreements: 0,
        page: 1,
    };

    componentDidMount(): void {
        this.loadAgreements();

        
        this.updateFilter({});
    }

    loadAgreements = async (
        // agreementId = '',
        page = 1,
        agreementsPerPage = 15,
    ): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const agreements = await Api.agreements.list(
            page,
            agreementsPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalAgreements: agreements.total,
            agreements: agreements.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadAgreements();
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadAgreements();
            });
        }
    };
    setCurrentAgreementIndex = (currentAgreementIndex: number): void => {
        this.setState({ currentAgreementIndex });
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadAgreements();
            });
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.loadAgreements(
            // this.state.agreementId,
            data.selected + 1,
            15,
        );
        this.setState({ page: data.selected + 1 });
    };

    updateFilter = (filter: AgreementFilter): void => {
        if(filter){
            window.localStorage.setItem('agreementFilters', JSON.stringify(filter));

            this.setState({ filter }, () => {
                this.updateFilterOptions();
            });   
        }
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter } = this.state;
        const filterOptions = await Api.agreements.getFilterOptions(filter);
        this.setState({ filterOptions }, () => {
            this.loadAgreements();
        });
    };

    render(): ReactElement {
        const { agreements, totalAgreements, agreementId, currentAgreementIndex, 
            filter,
            filterOptions,
            agreementOptions,
            sort,
            sortDirection,
        } = this.state;
        const { user } = this.props;
        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Agreements
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            {(user?.isPackingHouseUser() ||
                                user?.isAdmin()) && (
                                <Link to={'/agreements/add'}>
                                    <div className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right ml-4">
                                        <div className="mr-3">+</div>
                                        <div>New</div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                <Table
                        currentAgreementIndex={currentAgreementIndex}
                        filter={filter}
                        filterOptions={filterOptions}
                        loadAgreements={this.loadAgreements}
                        agreements={agreements}
                        agreementOptions={agreementOptions}
                        reverseSort={this.reverseSort}
                        setSort={this.setSort}
                        sort={sort}
                        sortDirection={sortDirection}
                        totalAgreements={totalAgreements}
                        updateFilter={this.updateFilter}
                        user={user ?? undefined}
                        agreementId={agreementId}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(Agreements);

import React, { Component, ReactElement } from 'react';
import {
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
    Link,
} from 'react-router-dom';

import { Api } from '@citrus-tracker/api';
import {
    Block,
    BlockFilter,
    BlockFilterOptions,
    BlockOptions,
    User,
} from '@citrus-tracker/types';
import Edit from './edit/edit';
import Table from './table/table';

import './block.module.scss';
import NewBlock from './edit/new-block';

interface PathParams {
    id: string;
}

/* eslint-disable-next-line */
export interface BlockProps extends RouteComponentProps<PathParams> {
    user?: User | null;
}

export class BlockComponent extends Component<BlockProps> {
    props: Readonly<BlockProps>;

    state: {
        growerId: string;
        currentBlockIndex: number;
        errorAlert: string;
        filter: BlockFilter;
        filterOptions: BlockFilterOptions;
        blocks: Block[];
        blockOptions: BlockOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalBlocks: number;
    } = {
        growerId: '',
        currentBlockIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        blocks: [],
        blockOptions: {
            crops: [],
            districts: [],
            organic: [],
            plantAges: [],
            ranches: [],
            rootstocks: [],
            getSelectOptions: () => [],
        },
        sort: 'block.id',
        sortDirection: 'DESC',
        successAlert: '',
        totalBlocks: 0,
    };

    componentDidMount(): void {
        this.updateFilter({});

        this.setupBlocks();
    }

    componentDidUpdate(oldProps: BlockProps): void {
        const { user } = this.props;

        if (user !== oldProps.user) {
            if (user) {
                this.setupBlocks();
            } else {
                this.setState({ blocks: [] });
            }
        }
    }

    getGrowerIdFromUrl(): string | undefined {
        const urlPath = this.props.location.pathname.split('/');
        let id = undefined;
        if (urlPath.length > 2 && !isNaN(parseInt(urlPath[2]))) {
            id = urlPath[2];
        }

        return id;
    }

    setupBlocks(): void {
        const id = this.getGrowerIdFromUrl();

        Api.blocks
            .getOptions(id ?? this.props.user?.id?.toString())
            .then((blockOptions) => {
                this.setState({ blockOptions });
            });

        if (id) {
            this.setState({ growerId: id });
            this.loadBlocks(id.toString());
        } else {
            this.loadBlocks();
        }
    }

    loadBlocks = async (
        growerId = '',
        page = 1,
        blocksPerPage = 15,
    ): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const blocks = await Api.blocks.list(
            growerId,
            page,
            blocksPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalBlocks: blocks.total,
            blocks: blocks.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadBlocks(this.state.growerId);
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadBlocks(this.state.growerId);
            });
        }
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadBlocks(this.state.growerId);
            });
        }
    };

    setSuccess = (successAlert: string): void => {
        this.setState({ successAlert });

        setTimeout(() => {
            this.setState({ successAlert: '' });
        }, 2000);
    };

    updateFilter = (filter: BlockFilter): void => {
        this.setState({ filter }, () => {
            this.updateFilterOptions();
        });
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter, growerId } = this.state;
        const filterOptions = await Api.blocks.getFilterOptions(filter);
        this.setState({ filterOptions }, () => {
            this.loadBlocks(growerId);
        });
    };

    render(): ReactElement {
        const { user } = this.props;
        const { url } = this.props.match;
        const {
            currentBlockIndex,
            errorAlert,
            filter,
            filterOptions,
            blocks,
            blockOptions,
            sort,
            sortDirection,
            successAlert,
            totalBlocks,
            growerId,
        } = this.state;

        return (
            <div>
                {errorAlert && (
                    <div className="alert alert-error text-center">
                        <strong>{errorAlert}</strong>
                    </div>
                )}
                {successAlert && (
                    <div className="alert alert-success text-center">
                        <strong>
                            {successAlert} <i className="si si-check"></i>
                        </strong>
                    </div>
                )}
                <div className="flex justify-between items-center flex-col mb-2 sm:flex-row">
                    <header>
                        <div className="mx-auto px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Blocks
                            </h1>
                        </div>
                    </header>

                    <div className="w-full sm:w-auto mx-4">
                        <div className="flex justify-between items-center">
                            {growerId && user?.isAdmin() && (
                                <div>
                                    {growerId && blocks && blocks.length > 0 && (
                                        <Link
                                            to={`/agreements/add/${growerId}`}
                                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right mr-4"
                                        >
                                            New Agreement
                                        </Link>
                                    )}
                                    <Link
                                        to={`/ranches/${growerId}`}
                                        className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right mr-4"
                                    >
                                        Ranches
                                    </Link>

                                    <Link
                                        to={`/growers/${growerId}`}
                                        className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right mr-4"
                                    >
                                        Entities
                                    </Link>
                                </div>
                            )}

                            { !window.location.pathname.includes('edit') && (
                                <Link
                                    to={`/blocks/${
                                        growerId ? growerId : user?.id
                                    }/edit/new`}
                                    className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right mr-4"
                                >
                                    <div className="mr-3">+</div>
                                    New
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

                <main>
                    <div className="mx-auto sm:px-6 lg:px-8">
                        <Switch>
                            <Route
                                path={`${url}/:userId/edit/new`}
                                exact
                                render={(): ReactElement => (
                                    <NewBlock
                                        growerId={growerId}
                                        blockOptions={blockOptions}
                                        user={user}
                                    />
                                )}
                            />

                            <Route
                                path={`${url}/edit/:blockId`}
                                exact
                                render={(): ReactElement => (
                                    <Edit
                                        growerId={growerId}
                                        setError={this.setError}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadBlocks={this.loadBlocks}
                                        blocks={blocks}
                                        blockOptions={blockOptions}
                                        setSuccess={this.setSuccess}
                                        totalBlocks={totalBlocks}
                                        updateFilter={this.updateFilter}
                                        // @ts-ignore
                                        user={user}
                                    />
                                )}
                            />
                            <Route
                                path={`${url}/:userId/edit/:blockId`}
                                exact
                                render={(): ReactElement => (
                                    <Edit
                                        growerId={growerId}
                                        setError={this.setError}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadBlocks={this.loadBlocks}
                                        blocks={blocks}
                                        blockOptions={blockOptions}
                                        setSuccess={this.setSuccess}
                                        totalBlocks={totalBlocks}
                                        updateFilter={this.updateFilter}
                                        // @ts-ignore
                                        user={user}
                                    />
                                )}
                            />
                            <Route
                                path={`${url}`}
                                exact
                                render={(): ReactElement => (
                                    <Table
                                        growerId={growerId}
                                        currentBlockIndex={currentBlockIndex}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadBlocks={this.loadBlocks}
                                        blocks={blocks}
                                        blockOptions={blockOptions}
                                        reverseSort={this.reverseSort}
                                        setSort={this.setSort}
                                        sort={sort}
                                        sortDirection={sortDirection}
                                        totalBlocks={totalBlocks}
                                        updateFilter={this.updateFilter}
                                        // @ts-ignore
                                        user={user}
                                    />
                                )}
                            />
                            <Route
                                path={`${url}/:id`}
                                exact
                                render={(): ReactElement => (
                                    <Table
                                        growerId={growerId}
                                        currentBlockIndex={currentBlockIndex}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadBlocks={this.loadBlocks}
                                        blocks={blocks}
                                        blockOptions={blockOptions}
                                        reverseSort={this.reverseSort}
                                        setSort={this.setSort}
                                        sort={sort}
                                        sortDirection={sortDirection}
                                        totalBlocks={totalBlocks}
                                        updateFilter={this.updateFilter}
                                        // @ts-ignore
                                        user={user}
                                    />
                                )}
                            />
                        </Switch>

                        {growerId && (
                            <div className="my-2 float-left relative -top-2">
                                <Link
                                    to={`/users/${growerId}`}
                                    className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right"
                                >
                                    Back to User
                                </Link>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(BlockComponent);

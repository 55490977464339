import React, { Component, Fragment, ReactElement } from 'react';

import './bulk-edit.module.scss';

import { Api } from '@citrus-tracker/api';
import {
  FieldEstimate,
  FieldEstimateOptions,
} from '@citrus-tracker/types';
import FieldEstimateForm from './form';

/* eslint-disable-next-line */
export interface BulkEditProps {
  estimates: FieldEstimate[];
  onFinished(): void;
  fieldEstimateOptions: FieldEstimateOptions;
  fruitSizes: number[];
}

export class BulkEditFieldEstimate extends Component<BulkEditProps> {
  props: Readonly<BulkEditProps>;

  state: {
    error: string;
    updates: FieldEstimate;
  } = {
    error: '',
    updates: {} as FieldEstimate,
  };

  componentDidMount(): void {
    this.setState({
      updates: {},
    });
  }

  saveChanges = async (): Promise<FieldEstimate[] | null> => {
    const { onFinished, estimates } = this.props;
    const { updates } = this.state;

    this.setState({ error: '' });

    if (
        !updates.export_percent ||
        updates.export_percent > 100
    ) {
        window.alert('Export Percent must be between 0 - 100%.');
        return null;
    }

    if (
        !updates.fancy_percent ||
        updates.fancy_percent > 100
    ) {
        window.alert('Fancy Percent must be between 0 - 100%.');
        return null;
    }

    if (
        !updates.utilization ||
        updates.utilization > 100
    ) {
        window.alert('Utilization Percent must be between 0 - 100%.');
        return null;
    }

    if (
        parseInt(
            (updates.fruit_size_1 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_2 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_3 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_4 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_5 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_6 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_7 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_8 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_9 ?? 0).toString(),
        ) +
        parseInt(
            (updates.fruit_size_10 ?? 0).toString(),
        ) !== 100
    ) {
        window.alert('Fruit size percentage must equal 100%.');
        return null;
    }

    if(window.confirm('Please be advised that all records selected will be updated with Bulk Edit feature.')){
      const promises: Promise<FieldEstimate>[] = estimates.map((record) =>
        Api.fieldEstimates.save({
          ...record.fieldEstimate,
          ...updates,
        }),
      );

      try {
        const FieldEstimate = await Promise.all(promises);
        onFinished();
        return FieldEstimate;
      } catch (error) {
        this.setState({ error });
        return null;
      }
    }else{
      return null;
    }
  };

  setUpdates = (updates: FieldEstimate): void => {
    this.setState({
      updates,
    });
  };

  render(): ReactElement {
    const { onFinished, fieldEstimateOptions, fruitSizes } = this.props;
    const { error } = this.state;

    
    const combinedFieldEstimate: FieldEstimate = {} as FieldEstimate;

    return (
      <Fragment>
        {error && (
          <div className="alert alert-error text-center">
            <strong>{error}</strong>
          </div>
        )}
        <FieldEstimateForm
            onCancel={onFinished}
            estimate={combinedFieldEstimate}
            estimateOptions={fieldEstimateOptions}
            saveChanges={this.saveChanges}
            setUpdates={this.setUpdates}
            fruitSizes={fruitSizes}
        />
      </Fragment>
    );
  }
}

export default BulkEditFieldEstimate;

import { ListRequest } from '..';
import { FieldEstimateFilter } from './filter';
import { User } from './user';
import { FieldEstimate } from './fieldEstimate';

export interface FieldEstimateHistory {
    id: number;

    field_estimate: FieldEstimate;

    field_rep: User;

    block: number;

    estimate_date: Date;

    reason: string;

    block_cleaned: boolean;

    total_bins: number;

    trees_per_bin: number;

    cartons_per_tree: number;

    picked_to_date: number;

    export_percent: number;

    fancy_percent: number;

    choice_percent: number;

    gibbed: boolean;

    gibbed_rate: string;

    priority: string;

    harvest_phase: string;

    condition?: string;

    color?: string;

    market?: string;

    status?: string;

    certification_bins?: number;

    certification_reason?: string;

    fruit_size_1?: number;

    fruit_size_2?: number;

    fruit_size_3?: number;

    fruit_size_4?: number;

    fruit_size_5?: number;

    fruit_size_6?: number;

    fruit_size_7?: number;

    fruit_size_8?: number;

    fruit_size_9?: number;

    fruit_size_10?: number;

    utilization: number;

    notes: string;

    updated_by?: string;

    bulkUpdate: boolean;
}

export function getFlatEstimateValue(
    record?: FieldEstimate | null,
    property?: keyof FieldEstimate,
): string | number {
    if (record && property) {
        const value = record[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        }
    }
    return '';
}

export interface FieldEstimateListRequest extends ListRequest {
    blockId?: number;
    filter?: FieldEstimateFilter;
    sort?: string;
    sortDirection?: string;
}

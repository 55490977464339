import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Api } from '@citrus-tracker/api';
import { PackingHouse, PackingHouseOptions, PackingHouseFilter, PackingHouseFilterOptions, User } from '@citrus-tracker/types';

import Table from './table';

import './packinghouse.module.scss';
const packinghousesPerPage = 15;

/* eslint-disable-next-line */
export interface PackingHouseProps extends RouteComponentProps<{}> {
    packinghouse?: PackingHouse | null;
    user?: User | null;
}

export class PackingHouseComponent extends Component<PackingHouseProps> {
    props: Readonly<PackingHouseProps>;

    state: {
        currentPackingHouseIndex: number;
        errorAlert: string;
        filter: PackingHouseFilter;
        filterOptions: PackingHouseFilterOptions;
        packinghouses: PackingHouse[];
        packinghouseOptions: PackingHouseOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalPackingHouses: number;
        page: number;
    } = {
        currentPackingHouseIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        packinghouses: [],
        packinghouseOptions: {},
        sort: 'packinghouse.id',
        sortDirection: 'DESC',
        successAlert: '',
        totalPackingHouses: 0,
        page: 1,
    };

    componentDidMount(): void {
        this.updateFilter({});

        this.loadPackingHouses();
    }

    loadPackingHouses = async (page = 1, packinghousesPerPage = 15): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const packinghouses = await Api.packinghouses.list(
            page,
            packinghousesPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalPackingHouses: packinghouses.total,
            packinghouses: packinghouses.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadPackingHouses();
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadPackingHouses();
            });
        }
    };
    setCurrentPackingHouseIndex = (currentPackingHouseIndex: number): void => {
        this.setState({ currentPackingHouseIndex });
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadPackingHouses();
            });
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.loadPackingHouses(data.selected + 1, packinghousesPerPage);
        this.setState({ page: data.selected + 1 });
    };

    updateFilter = (filter: PackingHouseFilter): void => {
        window.localStorage.setItem('packinghouseFilters', JSON.stringify(filter));

        this.setState({ filter }, () => {
            this.updateFilterOptions();
        });
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter } = this.state;
        const filterOptions = await Api.packinghouses.getFilterOptions(filter);
        this.setState({ filterOptions }, () => {
            this.loadPackingHouses();
        });
    };

    render(): ReactElement {
        const {
            currentPackingHouseIndex,
            filter,
            filterOptions,
            packinghouses,
            packinghouseOptions,
            sort,
            sortDirection,
            totalPackingHouses,
        } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Packing Houses
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            <Link
                                to="/packinghouses/new"
                                className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right ml-4"
                            >
                                <div className="mr-3">+</div>
                                <div>New</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <Table
                        currentPackingHouseIndex={currentPackingHouseIndex}
                        filter={filter}
                        filterOptions={filterOptions}
                        loadPackingHouses={this.loadPackingHouses}
                        PackingHouses={packinghouses}
                        PackingHouseOptions={packinghouseOptions}
                        reverseSort={this.reverseSort}
                        setSort={this.setSort}
                        sort={sort}
                        sortDirection={sortDirection}
                        totalPackingHouses={totalPackingHouses}
                        updateFilter={this.updateFilter}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(PackingHouseComponent);

import React, { Component, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { Api } from '@citrus-tracker/api';
import {
    FieldEstimate,
    Record,
    RecordFilter,
    RecordFilterOptions,
    RecordOptions,
    User,
} from '@citrus-tracker/types';
import EstimateTable from '../estimate-table/estimate-table';
import FruitTable from '../fruit-table/fruit-table';
import Filter from '../filter/filter';

import './edit.module.scss';
import { Button } from '@citrus-tracker/ui';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { format } from 'date-fns';
import EstimateHistoryTable from '../estimate-history-table/estimate-history-table';

enum Options {
    estimate,
    history,
}

interface PathParams {
    indexInFilteredList: string;
}

/* eslint-disable-next-line */
export interface EditProps extends RouteComponentProps<PathParams> {
    filter: RecordFilter;
    filterOptions: RecordFilterOptions;
    loadRecords(page: number, recordsPerPage: number): Promise<void>;
    records: Record[];
    recordOptions: RecordOptions;
    setCurrentRecordIndex(currentRecordIndex: number): unknown;
    setError(message: string): void;
    setSuccess(message: string): void;
    totalRecords: number;
    updateFilter(filter: RecordFilter): void;
    user?: User | null;
}

export class Edit extends Component<EditProps> {
    props: Readonly<EditProps>;

    state: {
        activeRecord: Record;
        preseasonRecord: Record;
        error: string;
        updates: Record;
        openPanel: boolean;
        selectedOption: Options;
        reps: User[];
    } = {
        error: '',
        activeRecord: {},
        preseasonRecord: {},
        updates: {},
        openPanel: false,
        selectedOption: Options.estimate,
        reps: [],
    };

    componentDidMount(): void {
        this.loadPage();
    }

    componentDidUpdate(oldProps: EditProps): void {
        if (
            this.props.match.params.indexInFilteredList !==
            oldProps.match.params.indexInFilteredList
        ) {
            this.loadPage();
        }
    }

    setOpenPanel(isOpen: boolean): void {
        this.setState({
            openPanel: isOpen,
        });
    }

    goToPage = async (newPage: number, saveFirst = false): Promise<void> => {
        const { filter } = this.props;
        const { indexInFilteredList } = this.props.match.params;

        if (saveFirst) {
            const savedRecord = await this.saveChanges('next');

            if (!savedRecord) {
                return;
            }

            if (newPage === parseInt(indexInFilteredList) + 1) {
                const recordsAtIndex = await Api.records.list(
                    parseInt(indexInFilteredList),
                    1,
                    filter,
                );
                if (
                    recordsAtIndex.results.length > 0 &&
                    recordsAtIndex.results[0].id !== savedRecord.id
                ) {
                    this.loadPage();
                    return;
                }
            }
        }
        this.props.history.push(`/estimates/${newPage}/edit`);
    };

    loadPage = async (): Promise<void> => {
        const { loadRecords, setCurrentRecordIndex } = this.props;
        let { indexInFilteredList } = this.props.match.params;

        if (indexInFilteredList === undefined) {
            indexInFilteredList = '1';
        }

        setCurrentRecordIndex(parseInt(indexInFilteredList));

        await loadRecords(parseInt(indexInFilteredList), 1);

        const { records } = this.props;

        let activeRecord = records[parseInt(indexInFilteredList) - 1];
        if (!activeRecord.fieldEstimate) {
            activeRecord.fieldEstimate = {} as FieldEstimate;
        }
    
        const users = (activeRecord.packingHouse ? await Api.users.listGrowersByPackingHouse(activeRecord.packingHouse?.id.toString()) : []);

        if(activeRecord.id && activeRecord.fieldEstimate?.id && (!activeRecord.fieldEstimate.fieldEstimateHistory || activeRecord.fieldEstimate.fieldEstimateHistory?.length === 0)) {
            activeRecord = await Api.records.get( activeRecord.id );
        }

        const preseasonRecord = {...activeRecord, fieldEstimate: {...activeRecord.fieldEstimate as FieldEstimate}};

        if (records && records.length > 0) {
            this.setState({
                preseasonRecord,
                activeRecord,
                updates: {},
                reps: users
            });
        }
    };

    saveChanges = async (routeType: string): Promise<Record | null> => {
        const { setSuccess } = this.props;
        const { activeRecord } = this.state;

        if (
            !activeRecord.fieldEstimate?.export_percent ||
            activeRecord.fieldEstimate?.export_percent > 100
        ) {
            window.alert('Export Percent must be between 0 - 100%.');
            return null;
        }

        if (
            !activeRecord.fieldEstimate?.fancy_percent ||
            activeRecord.fieldEstimate?.fancy_percent > 100
        ) {
            window.alert('Fancy Percent must be between 0 - 100%.');
            return null;
        }

        if (
            !activeRecord.fieldEstimate?.utilization ||
            activeRecord.fieldEstimate?.utilization > 100
        ) {
            window.alert('Utilization Percent must be between 0 - 100%.');
            return null;
        }

        if (
            parseInt(
                (activeRecord.fieldEstimate?.fruit_size_1 ?? 0).toString(),
            ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_2 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_3 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_4 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_5 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_6 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_7 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_8 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_9 ?? 0).toString(),
                ) +
                parseInt(
                    (activeRecord.fieldEstimate?.fruit_size_10 ?? 0).toString(),
                ) !== 100
        ) {
            window.alert('Fruit size percentage must equal 100%.');
            return null;
        }

        if (!activeRecord.fieldEstimate?.reason ||
          activeRecord.fieldEstimate?.reason === null
        ) {
          window.alert('Reason for Update is required.');
          return null;
        }

        if (activeRecord.fieldEstimate && activeRecord.id) {
            try {
                activeRecord.fieldEstimate.block = activeRecord.id;
                await Api.fieldEstimates.save(activeRecord.fieldEstimate);
                setSuccess('Saved Successfully');
                if (routeType === 'exit') {
                    this.props.history.push(`/estimates/`);
                } else {
                    const { indexInFilteredList } = this.props.match.params;
                    const page = parseInt(indexInFilteredList);

                    this.goToPage(page + 1);
                }

                return activeRecord;
            } catch (error) {
                return null;
            }
        } else {
            return null;
        }
    };

    setUpdates = (updates: Record): void => {
        this.setState({ updates });
    };

    exitPage = (): void => {
        this.props.history.push(`/estimates/`);
    };

    openDelete = async (): Promise<void> => {
        if (
            window.confirm('Are you sure you want to delete this estimate?') &&
            this.state.activeRecord &&
            this.state.activeRecord.id
        ) {
            await Api.records.delete(this.state.activeRecord.id);
            this.props.history.push(`/estimates/`);
        }
    };

    updateActiveRecord = (
        property: keyof FieldEstimate,
        value: unknown,
    ): void => {
        const updates = {
            ...this.state.activeRecord,
        };

        const fieldEstimateUpdates = {
            ...this.state.activeRecord.fieldEstimate,
        };

        if(property === 'user'){
            this.state.reps.forEach(( user ) => {
                if(user.id == value && updates.fieldEstimate){
                    updates.fieldEstimate.user = user;
                    return this.setState({
                        activeRecord:  {
                            ...updates
                        }
                    });
                }
            });
        }

        const newUpdates = {
            ...fieldEstimateUpdates,
            [property]: value,
        };

        if( newUpdates ){
            updates.fieldEstimate = newUpdates as FieldEstimate;

            this.setState({
                activeRecord: updates,
            });
        }

    };

    setSelected = (isSelected: boolean): string => {
        if (isSelected) return 'detailButton bg-gray-200';
        return 'detailButton';
    };

    setSelectedOption = (option: Options): void => {
        this.setState({
            selectedOption: option,
        });
    };

    render(): ReactElement {
        const {
            filter,
            filterOptions,
            totalRecords,
            updateFilter,
            user,
        } = this.props;
        const { openPanel, activeRecord, preseasonRecord, selectedOption, reps } = this.state;
        const { indexInFilteredList } = this.props.match.params;

        const page = parseInt(indexInFilteredList);


          let estimateDate = activeRecord.fieldEstimate?.estimate_date;
          let estimateDateAsString = '';
          if (estimateDate && estimateDate.toString() !== '') {
            estimateDate = new Date(estimateDate);
            const userTimeZoneOffset = estimateDate.getTimezoneOffset() * 60000;
            const formattedDate = new Date(estimateDate.getTime() + userTimeZoneOffset);
            estimateDateAsString = formattedDate.toLocaleDateString();
          }

        return (
            <div>
                <div className="flex h-8">
                    <div className="justify-self-start">
                        <header>
                            <h1 className="text-xl font-bold leading-tight text-gray-900">
                                Field Estimate
                            </h1>
                        </header>
                    </div>
                    {activeRecord && (
                        <div className="flex justify-evenly self-center text-xs w-3/4">
                            <p className="normal-case">
                                Grower: {activeRecord.grower_name}
                            </p>
                            <p className="normal-case">
                                Block ID: {activeRecord.block}
                            </p>
                            <p className="normal-case">
                                Acres: {activeRecord.acres}
                            </p>
                            <p className="normal-case">
                                Variety: {activeRecord.variety?.variety}
                            </p>
                            <p className="normal-case">
                                Sub Variety:{' '}
                                {activeRecord.subVariety?.sub_variety}
                            </p>
                        </div>
                    )}
                    {activeRecord.fieldEstimate?.fieldEstimateHistory !==
                        undefined && (
                        <div className="h-12 flex justify-center items-center sm:justify-start absolute right-0 top-20">
                            <div
                                className={this.setSelected(
                                    selectedOption === Options.estimate,
                                )}
                                onClick={(): void =>
                                    this.setSelectedOption(Options.estimate)
                                }
                            >
                                Estimate
                            </div>
                            <div
                                className={this.setSelected(
                                    selectedOption === Options.history,
                                )}
                                onClick={(): void =>
                                    this.setSelectedOption(Options.history)
                                }
                            >
                                History
                            </div>
                        </div>
                    )}
                </div>
                {selectedOption === Options.history &&
                    activeRecord !== undefined && (
                        <div className="block">
                            <div className="block-content">
                                <EstimateHistoryTable record={activeRecord} />
                            </div>
                        </div>
                    )}

                <div className="block grid grid-cols-5 grid-rows-9 gap-3">
                    {selectedOption === Options.estimate && (
                        <>
                            <div className="block col-start-1 col-span-6 row-start-1">
                                <div className="block-content flex">
                                    <Filter
                                        // activeRecord={activeRecord}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        nextPage={
                                            page < totalRecords
                                                ? (): void => {
                                                      this.goToPage(
                                                          page + 1,
                                                          true,
                                                      );
                                                  }
                                                : undefined
                                        }
                                        prevPage={
                                            page > 1
                                                ? (): void => {
                                                      this.goToPage(page - 1);
                                                  }
                                                : undefined
                                        }
                                        updateFilter={updateFilter}
                                        isAdmin={user?.isAdmin()}
                                    />
                                </div>
                            </div>
                            <div
                                className="block col-start-1 col-span-5 row-start-2"
                                style={{ height: 65 }}
                            >
                                <div
                                    className="block-content grid grid-cols-5 grid-rows-2 gap-x-4 gap-y-1 py-0"
                                    style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        height: 65,
                                    }}
                                >
                                    <p
                                        className="col-start-1 self-end text-sm"
                                        style={{ color: '#374151' }}
                                    >
                                        Field Rep:
                                    </p>
                                    <p
                                        className="col-start-2 self-end text-sm"
                                        style={{ color: '#374151' }}
                                    >
                                        New Estimate Date:
                                    </p>
                                    <p
                                        className="col-start-3 self-end text-sm"
                                        style={{ color: '#374151' }}
                                    >
                                        Current Estimate:
                                    </p>
                                    <p
                                        className="col-start-4 self-end text-sm"
                                        style={{ color: '#374151' }}
                                    >
                                        Reason for Update:
                                    </p>
                                    <select
                                        className="col-start-1 h-6 placeholder-gray-500 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:border-gray-500"
                                        id="repFilter"
                                        name="repFilter"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'user',
                                                e.target.value,
                                            )
                                        }
                                        value={activeRecord.fieldEstimate?.user?.id}
                                    >
                                        <option value="">
                                            Select a Field Rep
                                        </option>
                                        {reps.length > 0 && reps.map((x) => (
                                            <option value={x.id}>
                                                {x.firstName} {x.lastName}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        id="new_estimate_date"
                                        className="text-left text-sm px-2 h-6 placeholder-gray-500 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="date"
                                        min="1900"
                                        max={new Date().getFullYear()}
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'estimate_date',
                                                e.target.value,
                                            )
                                        }
                                        required
                                    />
                                    <p className="text-sm">
                                        {estimateDateAsString}
                                    </p>
                                    <input
                                        id="reason"
                                        className="text-left text-sm px-2 h-6 placeholder-gray-500 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'reason',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate?.reason
                                        }
                                        placeholder="Required"
                                        required
                                    />
                                    <div className="self-center row-start-1 row-span-2 col-start-5">
                                        <Button
                                            type="button"
                                            style={{
                                                color: '#4F46E5',
                                                borderColor: '#4F46E5',
                                                fontSize: 14,
                                            }}
                                            onClick={(): void =>
                                                this.setOpenPanel(true)
                                            }
                                            variant="secondary"
                                            className="mr-4 float-right"
                                        >
                                            {' '}
                                            View Block Details
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="block row-start-3 col-span-3 row-span-3 justify-self-start">
                                {activeRecord.id && (
                                    <EstimateTable
                                        updateActiveRecord={
                                            this.updateActiveRecord
                                        }
                                        filter={filter}
                                        updateFilter={updateFilter}
                                        cartonsPerBin={23}
                                        record={activeRecord}
                                        preseasonRecord={preseasonRecord}
                                    />
                                )}
                            </div>
                            <div className="block col-start-1 row-start-6 col-span-3 bg-white">
                                <div
                                    className="block-content"
                                    style={{ paddingTop: 10 }}
                                >
                                    <p className="justify-self-start self-center">
                                        <strong>Field Notes</strong>
                                    </p>
                                    <input
                                        id="notes"
                                        className="self-center w-full px-2 h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'notes',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate?.notes
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-start-4 row-start-3 col-span-2">
                                {activeRecord.id && (
                                    <FruitTable
                                        record={activeRecord}
                                        updateActiveRecord={
                                            this.updateActiveRecord
                                        }
                                        cartonsPerBin={23}
                                        fruitSizes={Api.fieldEstimates.fruitSizes(activeRecord.crop?.crop)}
                                    />
                                )}
                            </div>
                            <div className="block col-start-4 row-start-4 col-span-2 bg-white">
                                <div
                                    className="block-content grid grid-flow-col auto-cols-min grid-rows-2 gap-x-2 gap-y-1 py-0"
                                    style={{ paddingTop: 10 }}
                                >
                                    <p className="col-start-1 row-start-1 col-span-3 justify-self-start self-center">
                                        <strong>Harvest Readiness</strong>
                                    </p>
                                    <label className="text-xs col-start-5 row-start-1 justify-self-center self-center">
                                        Gibbed:
                                    </label>
                                    <input
                                        id="gibbed"
                                        type="checkbox"
                                        className="col-start-6 row-start-1 self-center justify-self-end"
                                        onChange={(e): void => {
                                            this.updateActiveRecord(
                                                'gibbed',
                                                e.target.checked,
                                            );}
                                        }
                                        checked={
                                            activeRecord.fieldEstimate?.gibbed
                                        }
                                    ></input>
                                    <label className="text-xs col-start-1 row-start-2 self-center">
                                        Priority:
                                    </label>
                                    <input
                                        id="priority"
                                        className="text-xs col-start-2 row-start-2 self-center text-right px-2 h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'priority',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate?.priority
                                        }
                                    />
                                    <label className="col-start-3 row-start-2 text-xs text-center self-center">
                                        Harvest Phase:
                                    </label>
                                    <input
                                        id="harvest_phase"
                                        className="text-xs col-start-4 row-start-2 self-center text-right h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'harvest_phase',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate
                                                ?.harvest_phase
                                        }
                                    />
                                    <label className="col-start-5 row-start-2 text-xs self-center text-center">
                                        Gibb Rate:
                                    </label>
                                    <input
                                        id="gibb_rate"
                                        className="text-xs col-start-6 row-start-2 self-center text-right h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="number"
                                        min="0"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'gibbed_rate',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate
                                                ?.gibbed_rate
                                        }
                                    />
                                </div>
                            </div>
                            <div className="block col-start-4 row-start-5 col-span-2 bg-white">
                                <div
                                    className="block-content grid grid-flow-col auto-cols-min grid-rows-2 gap-x-2 gap-y-1 py-0"
                                    style={{ paddingTop: 10 }}
                                >
                                    <p className="col-start-1 row-start-1 col-span-3 justify-self-start self-center">
                                        <strong>Quality</strong>
                                    </p>
                                    <label className="text-xs col-start-1 row-start-2 self-center">
                                        Condition:
                                    </label>
                                    <input
                                        id="condition"
                                        className="text-xs col-start-2 row-start-2 self-center text-right px-2 h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'condition',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate
                                                ?.condition
                                        }
                                    />
                                    <label className="col-start-3 row-start-2 text-xs text-center self-center">
                                        Color:
                                    </label>
                                    <input
                                        id="color"
                                        className="text-xs col-start-4 row-start-2 self-center text-right h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'color',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate?.color
                                        }
                                    />
                                    <label className="col-start-5 row-start-2 text-xs self-center text-center">
                                        Market:
                                    </label>
                                    <input
                                        id="market"
                                        className="text-xs col-start-6 row-start-2 self-center text-right h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'market',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate?.market
                                        }
                                    />
                                </div>
                            </div>
                            <div className="block col-start-4 row-start-6 col-span-2 bg-white">
                                <div
                                    className="block-content grid grid-flow-col auto-cols-min grid-rows-2 gap-x-2 gap-y-1 py-0"
                                    style={{ paddingTop: 10 }}
                                >
                                    <p className="col-start-1 row-start-1 col-span-3 justify-self-start self-center">
                                        <strong>Certification</strong>
                                    </p>
                                    <label className="text-xs col-start-1 row-start-2 self-center">
                                        Bins:
                                    </label>
                                    <input
                                        id="certification_bins"
                                        className="text-xs col-start-2 row-start-2 self-center text-right px-2 h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        style={{ width: 10 }}
                                        type="number"
                                        min="0"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'certification_bins',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate
                                                ?.certification_bins
                                        }
                                    />
                                    <label className="col-start-3 row-start-2 text-xs text-center self-center">
                                        Reason:
                                    </label>
                                    <input
                                        id="certification_reason"
                                        className="text-xs col-start-4 row-start-2 self-center text-right h-6 placeholder-gray-700 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="input"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'certification_reason',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            activeRecord.fieldEstimate
                                                ?.certification_reason
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {this.props.records.length > 1 && (
                        <div className="block col-start-1 col-span-5 w-full">
                            <div className="float-right">
                                {page !== this.props.records.length && (
                                    <Button
                                        onClick={(): Promise<Record | null> =>
                                            this.saveChanges('next')
                                        }
                                    >
                                        SAVE and Next
                                    </Button>
                                )}
                                <Button
                                    onClick={(): Promise<Record | null> =>
                                        this.saveChanges('exit')
                                    }
                                    style={{
                                        color: '#4F46E5',
                                        borderColor: '#4F46E5',
                                        backgroundColor: '#ffffff',
                                        marginLeft: '10px',
                                    }}
                                >
                                    SAVE and Exit
                                </Button>
                            </div>
                            <div className="float-left">
                                {page !== this.props.records.length && (
                                    <Button
                                        onClick={(): Promise<void> =>
                                            this.goToPage(page + 1)
                                        }
                                        className="justify-items-start"
                                        variant="delete"
                                    >
                                        NEXT No Save
                                    </Button>
                                )}
                                <Button
                                    variant="delete"
                                    onClick={this.exitPage}
                                    style={{
                                        color: '#DC2626',
                                        borderColor: '#DC2626',
                                        backgroundColor: '#ffffff',
                                        marginLeft: '10px',
                                    }}>
                                    EXIT No Save
                                </Button>
                            </div>
                        </div>
                    )}
                    {this.props.records.length === 1 && (
                        <div className="block col-start-1 col-span-5 w-full">
                            <div className="float-right">
                                <Button
                                    onClick={(): Promise<Record | null> =>
                                        this.saveChanges('exit')
                                    }
                                >
                                    SAVE
                                </Button>
                            </div>
                            <div>
                                <Button variant="delete" onClick={this.exitPage}>EXIT</Button>
                            </div>
                        </div>
                    )}
                </div>
                <SlidingPane
                    closeIcon={'Close'}
                    isOpen={openPanel}
                    from="right"
                    width="350px"
                    onRequestClose={(): void => this.setOpenPanel(false)}
                >
                    {activeRecord && (
                        <div>
                            <h1 className="text-lg font-bold">Block Details</h1>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Grower:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.grower_name}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Block:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.block_description_provided}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Grower ID:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.grower_id}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Block ID:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.block}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    District:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.district}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Planted Acres:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.acres}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Variety:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.variety?.variety}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Sub-Variety:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.subVariety?.sub_variety}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Rootstock:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.rootstock?.rootstock}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Plant Year:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.plant_year}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Trees per Acre:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.trees_per_acre}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Tree Count:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.trees}
                                </span>
                            </div>

                            <div className="w-full py-5">
                                <label className="float-left font-medium text-sm leading-4">
                                    Lot #:
                                </label>
                                <span className="float-right text-sm leading-4">
                                    {activeRecord.block_name}
                                </span>
                            </div>
                        </div>
                    )}
                </SlidingPane>
            </div>
        );
    }
}

export default withRouter(Edit);

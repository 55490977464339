import { Crop } from './crop';
import { PlantAge } from './plantAge';
import { Rootstock } from './rootstock';
import { Variety } from './variety';

export class RecordOptions {
    crops: Crop[] = [];

    districts = ['1', '2', '3', '9'];

    organic = ['Yes', 'No'];

    plantAges: PlantAge[] = [];

    rootstocks: Rootstock[] = [];

    selectedCrop?: Crop;
    selectedVariety?: Variety;

    getSelectOptions(
        property:
            | 'crop'
            | 'district'
            | 'organic'
            | 'plantAge'
            | 'rootstock'
            | 'subVariety'
            | 'variety',
    ): {
        label: string;
        value: string;
    }[] {
        switch (property) {
            case 'crop':
                return this.crops.map((crop) => ({
                    label: crop.crop || '',
                    value: crop.id.toString(),
                }));
            case 'district':
                return this.districts.map((district) => ({
                    label: district,
                    value: district,
                }));
            case 'organic':
                return this.organic.map((value) => ({
                    label: value,
                    value: value,
                }));
            case 'plantAge':
                return this.plantAges.map((plantAge) => ({
                    label: plantAge.age || '',
                    value: plantAge.id.toString(),
                }));
            case 'rootstock':
                return this.rootstocks.map((rootstock) => ({
                    label: rootstock.rootstock || '',
                    value: rootstock.id.toString(),
                }));
            case 'subVariety':
                return (
                    this.selectedVariety?.subVarieties?.map((subVariety) => ({
                        label: subVariety.sub_variety || '',
                        value: subVariety.id.toString(),
                    })) || []
                );
            case 'variety':
                return (
                    this.selectedCrop?.varieties?.map((variety) => ({
                        label: variety.variety || '',
                        value: variety.id.toString(),
                    })) || []
                );
            default:
                return [];
        }
    }
}

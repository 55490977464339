import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';

import {
    Agreement,
    AgreementBlocks,
    AuthenticationTokens,
    Block,
    BlockFilter,
    BlockFilterOptions,
    BlockListRequest,
    BlockOptions,
    ForgotPasswordDto,
    Grower,
    GrowerListRequest,
    ListResponse,
    LoginDto,
    PackingHouse,
    PackingHouseFilter,
    PackingHouseFilterOptions,
    PackingHouseListRequest,
    Ranch,
    RanchListRequest,
    Record,
    RecordFilter,
    RecordFilterOptions,
    RecordListRequest,
    AgreementBlocksListRequest,
    RecordOptions,
    ResetPasswordDto,
    Role,
    User,
    UserListRequest,
    FieldEstimate,
    UserFilter,
    UserFilterOptions,
    RanchFilter,
    RanchFilterOptions,
    GrowerFilter,
    GrowerFilterOptions,
    AgreementFilter,
    AgreementFilterOptions,
    GrowerAccount,
    GrowerAccountFilter,
    GrowerAccountFilterOptions,
    GrowerAccountListRequest,
} from '@citrus-tracker/types';

//Todo, Figure out DB
const firstFruitSizes: string[] = ['Lemon','Orange','Grapefruit','Minneola','Gold Nugget','Mandarin','Pummelo','Hybrid','Other'];
const fruitSizeValues: any[][] = [
                                        [63,75,95,115,140,165,200,235,285],
                                        [36,40,48,56,72,88,113,138,163,180],
                                        [18,23,27,32,36,40,48,56,64,80],
                                        [40,48,56,64,80,100,125,150,180],
                                        ['SCL','COL','MAM','JMB','LGE','MED','SML','PNY','IRR'],
                                        [12,15,18,21,24,28,32,36,40,44],
                                        [4,6,8,10,12,14,18,23,27,32],
                                        [14,18,23,27,32,36,40,48,56],
                                        [36,40,48,56,72,88,113,138,163,180],
                                    ];
export class CitrusTrackerApiConnection {
    /* This is the public interface */
    auth = {
        login: async (email: string, password: string): Promise<boolean> => {
            const tokens = <AuthenticationTokens>await this.post('auth/login', {
                email,
                password,
            });
            if (tokens && tokens.access_token) {
                this.storeTokens(tokens);
                return true;
            }
            return false;
        },
        logout: (): void => {
            this.deleteTokens();
        },
        password: {
            forgot: async (email: string): Promise<void> => {
                await this.post('auth/password/forgot', {
                    email,
                });
            },
            reset: async (
                token: string,
                password: string,
            ): Promise<boolean> => {
                const tokens = <AuthenticationTokens>await this.post(
                    'auth/password/reset',
                    {
                        token,
                        password,
                    },
                );
                if (tokens && tokens.access_token) {
                    this.storeTokens(tokens);
                    return true;
                }
                return false;
            },
        },
    };

    records = {
        buildRecordOptions: (optionsData: RecordOptions): RecordOptions => {
            return Object.assign(new RecordOptions(), optionsData);
        },
        delete: async (recordId: number): Promise<unknown> => {
            return this.delete(`records/${recordId}`);
        },
        export: async (includeDeleted = false): Promise<void> => {
            const csvData = <string>(
                await this.get('records/export.csv', { includeDeleted })
            );
            fileDownload(csvData, 'export.csv');
        },
        get: async (recordId: number): Promise<Record> => {
            return <Promise<Record>>this.get(`records/${recordId}`);
        },
        getFilterOptions: async (
            filter?: RecordFilter,
        ): Promise<RecordFilterOptions> => {
            return <Promise<RecordFilterOptions>>(
                this.post('records/filterOptions', { filter })
            );
        },
        getOptions: async (): Promise<RecordOptions> => {
            const optionsData = await (<Promise<RecordOptions>>(
                this.get(`records/options`)
            ));

            return this.records.buildRecordOptions(optionsData);
        },
        import: async (file: File, deleteMissing = false): Promise<boolean> => {
            const formData = new FormData();
            formData.append('file', file);
            if (deleteMissing) {
                formData.append('deleteMissing', '1');
            }

            return <Promise<boolean>>this.post('records/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        },
        list: async (
            page = 1,
            perPage = 15,
            filter?: RecordFilter,
            sort = 'record.id',
            sortDirection = 'ASC',
            packingHouseId?: number,
        ): Promise<ListResponse<Record>> => {
            const records = <ListResponse<Record>>await this.post(
                'records/list',
                {
                    page,
                    perPage,
                    sort,
                    sortDirection,
                    packingHouseId,
                    filter,
                },
            );

            return records;
        },
        listForFieldEstimates: async (
            page = 1,
            perPage = 15,
            filter?: RecordFilter,
            sort = 'record.id',
            sortDirection = 'ASC',
            packingHouseId?: number,
        ): Promise<ListResponse<Record>> => {
            const records = <ListResponse<Record>>await this.post(
                'records/list',
                {
                    page,
                    perPage,
                    sort,
                    sortDirection,
                    packingHouseId,
                    filter,
                    fieldEstimates: true,
                },
            );

            return records;
        },
        save: async (record: Record): Promise<Record> => {
            if (record.id) {
                return <Promise<Record>>(
                    this.put(`records/${record.id}`, record)
                );
            } else {
                return <Promise<Record>>this.post('records', record);
            }
        },
    };

    agreementBlocks = {
        list: async (
            agreementId: number,
            page = 1,
            perPage = 15,
            filter?: RecordFilter,
            sort = 'agreement.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<AgreementBlocks>> => {
            const agreementBlocks = <ListResponse<AgreementBlocks>>(
                await this.post('agreementBlocks/list', {
                    agreementId,
                    page,
                    perPage,
                    filter,
                    sort,
                    sortDirection,
                })
            );

            return agreementBlocks;
        },
        get: async (agreementId: string): Promise<AgreementBlocks> => {
            return <Promise<AgreementBlocks>>(
                this.get(`agreementBlocks/${agreementId}`)
            );
        },
        save: async (
            agreementBlock: AgreementBlocks,
        ): Promise<AgreementBlocks> => {
            return <Promise<AgreementBlocks>>(
                this.post('agreementBlocks', agreementBlock)
            );
        },
        releaseBlock: async (agreementBlockId: string): Promise<boolean> => {
            return <Promise<boolean>>(
                this.delete(`agreementBlocks/${agreementBlockId}`)
            );
        },
    };

    blocks = {
        buildBlockOptions: (optionsData: BlockOptions): BlockOptions => {
            return Object.assign(new BlockOptions(), optionsData);
        },
        delete: async (blockId: number): Promise<unknown> => {
            return this.delete(`blocks/${blockId}`);
        },
        export: async (includeDeleted = false): Promise<void> => {
            const csvData = <string>(
                await this.get('blocks/export.csv', { includeDeleted })
            );
            fileDownload(csvData, 'export.csv');
        },
        get: async (blockId: number): Promise<Block> => {
            return <Promise<Block>>this.get(`blocks/${blockId}`);
        },
        getFilterOptions: async (
            filter?: BlockFilter,
        ): Promise<BlockFilterOptions> => {
            return <Promise<BlockFilterOptions>>(
                this.post('blocks/filterOptions', { filter })
            );
        },
        getOptions: async (
            userId: string | undefined,
        ): Promise<BlockOptions> => {
            const optionsData = await (<Promise<BlockOptions>>(
                this.get(`blocks/options/${userId}`)
            ));

            return this.blocks.buildBlockOptions(optionsData);
        },
        getRanchesByGrower: async (
            growerId: number | undefined,
        ): Promise<Ranch[]> => {
            return <Promise<Ranch[]>>(
                this.get(`blocks/options/ranches/${growerId}`)
            );
        },
        import: async (file: File, deleteMissing = false): Promise<boolean> => {
            const formData = new FormData();
            formData.append('file', file);
            if (deleteMissing) {
                formData.append('deleteMissing', '1');
            }

            return <Promise<boolean>>this.post('blocks/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        },
        getByAgreementId: async (agreementId: number): Promise<Block[]> => {
            return <Promise<Block[]>>(
                this.get(`blocks/agreement/${agreementId}`)
            );
        },
        list: async (
            growerId = '',
            page = 1,
            perPage = 15,
            filter?: BlockFilter,
            sort = 'block.id',
            sortDirection = 'ASC',
            packingHouseId?: number,
        ): Promise<ListResponse<Block>> => {
            const blocks = <ListResponse<Block>>await this.post('blocks/list', {
                growerId,
                page,
                perPage,
                sort,
                sortDirection,
                packingHouseId,
                filter,
            });

            return blocks;
        },
        save: async (block: Block): Promise<Block> => {
            if (block.id) {
                return <Promise<Block>>this.put(`blocks/${block.id}`, block);
            } else {
                return <Promise<Block>>this.post('blocks', block);
            }
        },
    };

    agreements = {
        list: async (
            page = 1,
            perPage = 15,
            filter?: AgreementFilter,
            sort = 'agreement.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<Agreement>> => {
            const agreements = <ListResponse<Agreement>>await this.post(
                'agreements/list',
                {
                    page,
                    perPage,
                    filter,
                    sort,
                    sortDirection,
                },
            );

            return agreements;
        },
        get: async (agreementId: number): Promise<Agreement> => {
            return <Promise<Agreement>>this.get(`agreements/${agreementId}`);
        },
        save: async (agreement: Agreement): Promise<Agreement> => {
            if (agreement.id) {
                return <Promise<Agreement>>(
                    this.put(`agreements/${agreement.id}`, agreement)
                );
            } else {
                return <Promise<Agreement>>this.post('agreements', agreement);
            }
        },
        delete: async (agreementId: number): Promise<unknown> => {
            return this.delete(`agreements/${agreementId}`);
        },
        getFilterOptions: async (
            filter?: AgreementFilter,
        ): Promise<AgreementFilterOptions> => {
            return <Promise<AgreementFilterOptions>>(
                this.post('agreements/filterOptions', { filter })
            );
        },
    };

    users = {
        create: async (token: string, password: string): Promise<boolean> => {
            const tokens = <AuthenticationTokens>await this.post(
                'auth/password/create',
                {
                    token,
                    password,
                },
            );
            if (tokens && tokens.access_token) {
                this.storeTokens(tokens);
                return true;
            }
            return false;
        },
        list: async (
            page = 1,
            perPage = 15,
            filter?: UserFilter,
            sort = 'user.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<User>> => {
            const users = <ListResponse<User>>await this.post('users/list', {
                page,
                perPage,
                filter,
                sort,
                sortDirection,
            });

            return users;
        },
        get: async (userId: string): Promise<User> => {
            return <Promise<User>>this.get(`users/${userId}`);
        },
        listGrowers: async (): Promise<User[]> => {
            return <Promise<User[]>>this.get(`users/growers`);
        },
        listGrowersByPackingHouse: async (packinghouseId: string): Promise<User[]> => {
            return <Promise<User[]>>this.get(`users/growers/${packinghouseId}`);
        },
        listRoles: async (): Promise<Role[]> => {
            return <Promise<Role[]>>this.get(`users/roles`);
        },
        listPackingHouse: async (): Promise<PackingHouse[]> => {
            return <Promise<PackingHouse[]>>this.get(`users/packinghouses`);
        },
        listPackingHouseUsers: async (packinghouseId: string): Promise<PackingHouse[]> => {
            return <Promise<PackingHouse[]>>this.get(`users/packinghouses/${packinghouseId}`);
        },
        save: async (user: User): Promise<User> => {
            if (user.id) {
                return <Promise<User>>this.put(`users/${user.id}`, user);
            } else {
                return <Promise<User>>this.post('auth/user', user);
            }
        },
        upload: async (userId: number, file: File): Promise<User> => {
            const formData = new FormData();
            formData.append('file', file);

            return <Promise<User>>this.post(
                `users/upload/${userId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
        },
        delete: async (userId: number): Promise<unknown> => {
            return this.delete(`users/${userId}`);
        },
        getFilterOptions: async (
            filter?: UserFilter,
        ): Promise<UserFilterOptions> => {
            return <Promise<UserFilterOptions>>(
                this.post('users/filterOptions', { filter })
            );
        },
        me: async (): Promise<User> => {
            const userData = await this.get('users/me');
            const user = new User();
            Object.assign(user, userData);

            return user;
        },
    };

    growers = {
        list: async (
            growerId = '',
            page = 1,
            perPage = 15,
            filter?: GrowerFilter,
            sort = 'grower.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<Grower>> => {
            const growers = <ListResponse<Grower>>await this.post(
                'growers/list',
                {
                    growerId,
                    page,
                    perPage,
                    filter,
                    sort,
                    sortDirection,
                },
            );

            return growers;
        },
        getAll: async (): Promise<Grower[]> => {
            return <Promise<Grower[]>>this.get(`growers/all`);
        },
        get: async (growerId: string): Promise<Grower> => {
            return <Promise<Grower>>this.get(`growers/${growerId}`);
        },
        getAllByUserId: async (userId: string): Promise<Grower[]> => {
            return <Promise<Grower[]>>this.get(`growers/byUser/${userId}`);
        },
        save: async (grower: Grower): Promise<Grower> => {
            if (grower.id) {
                return <Promise<Grower>>(
                    this.put(`growers/${grower.id}`, grower)
                );
            } else {
                return <Promise<Grower>>this.post('growers', grower);
            }
        },
        delete: async (growerId: number): Promise<unknown> => {
            return this.delete(`growers/${growerId}`);
        },
        getFilterOptions: async (
            filter?: GrowerFilter,
            growerId?: string,
        ): Promise<GrowerFilterOptions> => {
            return <Promise<GrowerFilterOptions>>(
                this.post('growers/filterOptions', { filter, growerId })
            );
        },
    };

    growerAccounts = {
        list: async (
            growerAccountId = '',
            page = 1,
            perPage = 15,
            filter?: GrowerAccountFilter,
            sort = 'growerAccount.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<GrowerAccount>> => {
            const growers = <ListResponse<GrowerAccount>>await this.post(
                'growerAccounts/list',
                {
                    growerAccountId,
                    page,
                    perPage,
                    filter,
                    sort,
                    sortDirection,
                },
            );

            return growers;
        },
        getAll: async (): Promise<GrowerAccount[]> => {
            return <Promise<GrowerAccount[]>>this.get(`growerAccounts/all`);
        },
        get: async (growerId: string): Promise<GrowerAccount> => {
            return <Promise<GrowerAccount>>this.get(`growerAccounts/${growerId}`);
        },
        save: async (grower: GrowerAccount): Promise<GrowerAccount> => {
            if (grower.id) {
                return <Promise<GrowerAccount>>(
                    this.put(`growerAccounts/${grower.id}`, grower)
                );
            } else {
                return <Promise<GrowerAccount>>this.post('growerAccounts', grower);
            }
        },
        delete: async (growerId: number): Promise<unknown> => {
            return this.delete(`growerAccounts/${growerId}`);
        },
        getFilterOptions: async (
            filter?: GrowerAccountFilter,
            growerId?: string,
        ): Promise<GrowerAccountFilterOptions> => {
            return <Promise<GrowerAccountFilterOptions>>(
                this.post('growerAccounts/filterOptions', { filter, growerId })
            );
        },
    };

    ranches = {
        list: async (
            growerId = '',
            page = 1,
            perPage = 15,
            filter?: RanchFilter,
            sort = 'ranch.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<Ranch>> => {
            const ranches = <ListResponse<Ranch>>await this.post(
                'ranches/list',
                {
                    growerId,
                    page,
                    perPage,
                    filter,
                    sort,
                    sortDirection,
                },
            );

            return ranches;
        },
        get: async (ranchId: string): Promise<Ranch> => {
            return <Promise<Ranch>>this.get(`ranches/${ranchId}`);
        },
        save: async (ranch: Ranch): Promise<Ranch> => {
            if (ranch.id) {
                return <Promise<Ranch>>this.put(`ranches/${ranch.id}`, ranch);
            } else {
                return <Promise<Ranch>>this.post('ranches', ranch);
            }
        },
        delete: async (ranchId: number): Promise<unknown> => {
            return this.delete(`ranches/${ranchId}`);
        },
        getFilterOptions: async (
            filter?: RanchFilter,
            growerId?: string,
        ): Promise<RanchFilterOptions> => {
            return <Promise<RanchFilterOptions>>(
                this.post('ranches/filterOptions', { filter, growerId })
            );
        },
    };

    fieldEstimates = {
        save: async (fieldEstimate: FieldEstimate): Promise<FieldEstimate> => {
            if (fieldEstimate.id) {
                return <Promise<FieldEstimate>>(
                    this.put(
                        `fieldEstimates/${fieldEstimate.id}`,
                        fieldEstimate,
                    )
                );
            } else {
                return <Promise<FieldEstimate>>(
                    this.post('fieldEstimates', fieldEstimate)
                );
            }
        },
        fruitSizes: (fruitType: string | undefined): number[] => {
            if(fruitType && firstFruitSizes.indexOf(fruitType)){
                return fruitSizeValues[firstFruitSizes.indexOf(fruitType)];
            }else{
                return fruitSizeValues[0];
            }
        }

    };

    packinghouses = {
        create: async (packing_house: PackingHouse): Promise<PackingHouse> => {
            if (packing_house.id) {
                return <Promise<PackingHouse>>(
                    this.put(
                        `packinghouses/${packing_house.id}`,
                        packing_house,
                    )
                );
            } else {
                return <Promise<PackingHouse>>(
                    this.post('packinghouses', packing_house)
                );
            }
        },
        list: async (
            page = 1,
            perPage = 15,
            filter?: PackingHouseFilter,
            sort = 'packingHouse.id',
            sortDirection = 'ASC',
        ): Promise<ListResponse<PackingHouse>> => {
            const packinghouses = <ListResponse<PackingHouse>>await this.post('packinghouses/list', {
                page,
                perPage,
                filter,
                sort,
                sortDirection,
            });

            return packinghouses;
        },
        get: async (packingHouseId: string): Promise<PackingHouse> => {
            return <Promise<PackingHouse>>this.get(`packinghouses/${packingHouseId}`);
        },
        save: async (packingHouse: PackingHouse): Promise<PackingHouse> => {
            if (packingHouse.id) {
                return <Promise<PackingHouse>>this.put(`packinghouses/${packingHouse.id}`, packingHouse);
            } else {
                return <Promise<PackingHouse>>this.post('packinghouses', packingHouse);
            }
        },
        delete: async (packingHouseId: number): Promise<unknown> => {
            return this.delete(`packinghouses/${packingHouseId}`);
        },
        getFilterOptions: async (
            filter?: PackingHouseFilter,
        ): Promise<PackingHouseFilterOptions> => {
            return <Promise<PackingHouseFilterOptions>>(
                this.post('packinghouses/filterOptions', { filter })
            );
        },
    };


    /* These are all internal */

    axios: AxiosInstance | null = null;

    constructor() {
        this.createAxiosInstance();
    }

    parseAxiosResponse(response: { data: unknown }): unknown {
        if (response && response.data) {
            return response.data;
        }

        return null;
    }

    async delete(route: string): Promise<unknown> {
        if (!this.axios) {
            return null;
        }

        return this.parseAxiosResponse(await this.axios.delete(route));
    }

    async get(
        route: string,
        params?: { includeDeleted: boolean },
        config?: AxiosRequestConfig,
    ): Promise<unknown> {
        if (!this.axios) {
            return null;
        }

        return this.parseAxiosResponse(
            await this.axios.get(route, { ...config, params }),
        );
    }

    async post(
        route: string,
        data?:
            | ForgotPasswordDto
            | FormData
            | LoginDto
            | Record
            | User
            | ResetPasswordDto
            | RecordFilterOptions
            | RecordListRequest
            | UserListRequest
            | GrowerAccount
            | GrowerAccountListRequest
            | Grower
            | GrowerListRequest
            | Ranch
            | RanchListRequest
            | AgreementBlocksListRequest
            | BlockListRequest
            | AgreementBlocks
            | FieldEstimate
            | PackingHouse
            | PackingHouseListRequest,
        config?: AxiosRequestConfig,
    ): Promise<unknown> {
        if (!this.axios) {
            return null;
        }

        return this.parseAxiosResponse(
            await this.axios.post(route, data, config),
        );
    }

    async put(
        route: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Promise<unknown> {
        if (!this.axios) {
            return null;
        }

        return this.parseAxiosResponse(
            await this.axios.put(route, data, config),
        );
    }

    private createAxiosInstance(): void {
        const params: {
            baseURL: string;
            headers?: {
                Authorization?: string;
            };
        } = {
            baseURL: process.env.NX_BACKEND_URL || '/api/',
        };
        const tokens = this.getTokens();
        if (tokens) {
            params.headers = { Authorization: `Bearer ${tokens.access_token}` };
        }

        this.axios = axios.create(params);
    }
    private getTokens(): AuthenticationTokens | null {
        const tokenString = localStorage.getItem('authenticationTokens');
        if (tokenString) {
            return JSON.parse(tokenString);
        }
        return null;
    }
    private storeTokens(tokens: AuthenticationTokens): void {
        localStorage.setItem('authenticationTokens', JSON.stringify(tokens));
        this.createAxiosInstance();
    }
    private deleteTokens(): void {
        localStorage.removeItem('authenticationTokens');
        this.createAxiosInstance();
    }
}

export default new CitrusTrackerApiConnection();

import { Api } from '@citrus-tracker/api';
import { Agreement, AgreementBlocks, Block } from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';
import { useEffect, useState } from 'react';
import { BlocksTable, Row } from './blocksTable/blocksTable';
import {
    assignedBlocksHeaders,
    blockHeaders,
    getBlockRows,
    getAssignedBlocksRows,
} from './detailHelper';

export const BlocksDetails = ({
    agreement,
}: {
    agreement: Agreement | undefined;
}) => {
    const [totalBlocks, setTotalBlocks] = useState<number>();
    const [totalAssignedBlocks, setTotalAssignedBlocks] = useState<number>();
    const [blocksRow, setBlocksRow] = useState<Row[]>([]);
    const [assignedBlocksRow, setAssignedBlocksRow] = useState<Row[]>([]);

    const perPageResults = 15;
    const loadBlocks = async (): Promise<void> => {
        if (agreement && agreement.id) {
            const res: Block[] = await Api.blocks.getByAgreementId(
                agreement.id,
            );
            setTotalBlocks(res.length);
            setBlocksRow(getBlockRows(res, addAcres));
        }
    };

    const loadAssignedBlocks = async (): Promise<void> => {
        if (agreement && agreement.id) {
            const res = await Api.agreementBlocks.list(agreement.id);
            setTotalAssignedBlocks(res.results.length);
            setAssignedBlocksRow(
                getAssignedBlocksRows(res.results, releaseBlock),
            );
        }
    };

    const releaseBlock = async (agreementBlocksId: string): Promise<void> => {
        await Api.agreementBlocks.releaseBlock(agreementBlocksId);
        loadData();
    };

    const loadData = (): void => {
        if (agreement) {
            loadBlocks();
            loadAssignedBlocks();
        }
    };

    const addAcres = async (
        blockId: number,
        quantity: number,
    ): Promise<void> => {
        if (agreement && agreement.id) {
            const blocks: Block[] = await Api.blocks.getByAgreementId(
                agreement.id,
            );
            const block = blocks.find((b) => (b.id = blockId));
            if (
                block &&
                agreement &&
                quantity > 0 &&
                block.total_acres &&
                (parseFloat(block.total_acres) ??
                    0 -
                        (block.assigned_acres
                            ? parseFloat(block.assigned_acres)
                            : 0)) >= quantity
            ) {
                const agreementBlock: AgreementBlocks = ({
                    startDate: new Date(),
                    status: 'active',
                    blocksAllocated: quantity,
                    agreement: agreement.id,
                    block: block.id,
                } as unknown) as AgreementBlocks;
                await Api.agreementBlocks.save(agreementBlock);
                loadData();
            }
        }
    };

    const save = (): void => {
        window.location.reload();
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <div>
                <div className="text-2xl pb-4">
                    Blocks assigned to the agreement
                </div>
                <BlocksTable
                    resultsPerPage={perPageResults}
                    totalResults={totalAssignedBlocks || 0}
                    paginationClick={() => console.log('clicked')}
                    columnTitles={assignedBlocksHeaders}
                    rows={assignedBlocksRow}
                />
            </div>
            <br />
            <div>
                <div className="text-2xl pb-4">Blocks available </div>
                <BlocksTable
                    resultsPerPage={perPageResults}
                    totalResults={totalBlocks || 0}
                    paginationClick={() => console.log('clicked')}
                    columnTitles={blockHeaders}
                    rows={blocksRow}
                />
            </div>
            { agreement?.status !== 'Active' && 
                <Button className="ml-2 my-5 float-right" onClick={(): void => save()}>
                    Save Changes
                </Button>
            }
        </div>
    );
};

import React, { ReactElement } from 'react';
import { Component, FormEvent } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button } from '@citrus-tracker/ui';

import './password-reset.module.scss';

export class PasswordReset extends Component {
    props: Readonly<{
        submitReset(): Promise<boolean>;
        submitForgot(): void;
        setParentState(passedState: { [key: string]: unknown }): void;
    }>;
    state: {
        successMessage: string;
        errorMessage: string;
        step: number;
    } = {
        successMessage: '',
        errorMessage: '',
        step: 1,
    };

    componentDidMount(): void {
        const resetToken = new URLSearchParams(window.location.search).get(
            'token',
        );
        if (resetToken) {
            this.props.setParentState({
                resetToken: resetToken,
            });
            this.setState({ step: 2 });
        }
    }

    submitReset = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.props.submitReset();
        this.setState({
            step: 3,
        });
    };
    submitForgot = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.props.submitForgot();
        this.setState({
            successMessage: 'Check your email to reset your password',
        });
    };

    render(): ReactElement {
        const { setParentState } = this.props;
        const { successMessage, errorMessage, step } = this.state;

        if (step === 3) {
            return <Redirect to="/" />;
        }

        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Welcome
                        </h2>
                        {successMessage && (
                            <div className="alert alert-success text-center">
                                <strong>{successMessage}</strong>
                            </div>
                        )}
                        {errorMessage && (
                            <div className="alert alert-danger text-center">
                                <strong>{errorMessage}</strong>
                            </div>
                        )}
                    </div>
                    {step === 1 && (
                        <form
                            className="mt-8 space-y-6"
                            onSubmit={this.submitForgot}
                        >
                            <div>
                                <p className="mt-2 text-center text-sm text-gray-600">
                                    Reset Password
                                </p>
                            </div>

                            <input
                                type="hidden"
                                name="remember"
                                defaultValue="true"
                            />
                            <div className="rounded-md shadow-sm -space-y-px">
                                <div>
                                    <label
                                        htmlFor="login-username"
                                        className="sr-only"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        id="login-username"
                                        type="email"
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        onChange={(event): void =>
                                            setParentState({
                                                forgotEmail:
                                                    event.currentTarget.value,
                                            })
                                        }
                                        placeholder="Email Address"
                                    />
                                </div>
                            </div>

                            <div>
                                <Button icon="LockClosedIcon" type="submit">
                                    Send Reset Email
                                </Button>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <Link
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                        to="/login"
                                    >
                                        Login
                                    </Link>
                                </div>
                            </div>
                        </form>
                    )}
                    {step === 2 && (
                        <form
                            className="mt-8 space-y-6"
                            onSubmit={this.submitReset}
                        >
                            <div>
                                <p className="mt-2 text-center text-sm text-gray-600">
                                    Reset Password
                                </p>
                            </div>

                            <input
                                type="hidden"
                                name="remember"
                                defaultValue="true"
                            />
                            <div className="rounded-md shadow-sm -space-y-px">
                                <div>
                                    <label
                                        htmlFor="login-password"
                                        className="sr-only"
                                    >
                                        New Password
                                    </label>
                                    <input
                                        id="login-password"
                                        type="password"
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        onChange={(event): void =>
                                            setParentState({
                                                newPassword:
                                                    event.currentTarget.value,
                                            })
                                        }
                                        placeholder="New Password"
                                    />
                                </div>
                            </div>

                            <div>
                                <Button icon="LockClosedIcon" type="submit">
                                    Reset Password
                                </Button>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <Link
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                        to="/login"
                                    >
                                        Login
                                    </Link>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}

export default PasswordReset;

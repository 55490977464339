import { Ranch } from './ranch';
import { ListRequest } from './listRequest';
import { User } from './user';
import { Block } from './block';
import { GrowerFilter } from './filter';
import { GrowerAccount } from './growerAccount';

export interface Grower {
    id: number;

    name: string;

    ein: string;

    user?: User;

    primaryNumber: string;

    secondaryNumber?: string;

    address: string;

    address2?: string;

    city: string;

    state: string;

    zipCode: string;

    ranches: Ranch[];

    blocks: Block[];

    growerAccounts: GrowerAccount[];

    last_updated?: Date;

    date_created?: Date;

    deleted: boolean;

    packingHouseId?: number;

    packingHouseUserId?: number;
}

export interface GrowerListRequest extends ListRequest {
    filter?: GrowerFilter;
    growerId?: string;
    sort?: string;
    sortDirection?: string;
}

export function getFlatGrowerValue(
    grower?: Grower | null,
    property?: keyof Grower,
): string | number {
    if (grower && property) {
        const value = grower[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        }
    }

    return '';
}
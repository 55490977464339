import React, { Component, Fragment, ReactElement } from 'react';

import './bulk-edit.module.scss';

import { Api } from '@citrus-tracker/api';
import {
    getFlatRecordValue,
    Record,
    RecordOptions,
} from '@citrus-tracker/types';
import RecordForm from './form';

/* eslint-disable-next-line */
export interface BulkEditProps {
    onFinished(): void;
    records: Record[];
    recordOptions: RecordOptions;
}

export class BulkEditRecord extends Component<BulkEditProps> {
    props: Readonly<BulkEditProps>;

    state: {
        error: string;
        updates: Record;
    } = {
        error: '',
        updates: {},
    };

    componentDidMount(): void {
        this.setState({
            updates: {},
        });
    }

    saveChanges = async (): Promise<Record[] | null> => {
        const { onFinished, records } = this.props;
        const { updates } = this.state;

        this.setState({ error: '' });

        const promises: Promise<Record>[] = records.map((record) =>
            Api.records.save({
                id: record.id,
                ...updates,
            }),
        );

        try {
            const record = await Promise.all(promises);
            onFinished();
            return record;
        } catch (error) {
            this.setState({ error });
            return null;
        }
    };

    setUpdates = (updates: Record): void => {
        this.setState({
            updates,
        });
    };

    render(): ReactElement {
        const { onFinished, records, recordOptions } = this.props;
        const { error, updates } = this.state;

        const combinedRecord: Record = {};

        // All free-form fields are disabled in bulk edit
        const disabledFields: (keyof Record)[] = [
            'acres',
            'block',
            'block_name',
            'grower_id',
            'grower_name',
            'plant_year',
            'trees',
            'trees_per_acre',
        ];

        const populatedProperties: (keyof Record)[] = [];
        for (const record of records) {
            let property: keyof Record;
            for (property in record) {
                if (
                    record[property] &&
                    populatedProperties.indexOf(property) < 0
                ) {
                    populatedProperties.push(property);
                }
            }
        }

        for (const property of populatedProperties) {
            if (Object.keys(updates).indexOf(property) >= 0) {
                // If we've already updated, or a field has been reset by changing a parent field, we don't want to disable it
                continue;
            }

            const values: unknown[] = [];
            const flatValues: (string | number)[] = [];
            let allEqual = true;
            for (const record of records) {
                if (record[property]) {
                    const flatValue = getFlatRecordValue(record, property);
                    if (
                        flatValues.length > 0 &&
                        flatValues.indexOf(flatValue) < 0
                    ) {
                        // If two records have different values for a field, it cannot be changed
                        disabledFields.push(property);
                        allEqual = false;
                        break;
                    }
                    values.push(record[property]);
                    flatValues.push(flatValue);
                }
            }
            if (allEqual && values.length > 0) {
                Object.assign(combinedRecord, { [property]: values[0] });
            }
        }

        return (
            <Fragment>
                {error && (
                    <div className="alert alert-error text-center">
                        <strong>{error}</strong>
                    </div>
                )}
                <RecordForm
                    disabledFields={disabledFields}
                    onCancel={onFinished}
                    record={combinedRecord}
                    recordOptions={recordOptions}
                    saveChanges={this.saveChanges}
                    setUpdates={this.setUpdates}
                />
            </Fragment>
        );
    }
}

export default BulkEditRecord;

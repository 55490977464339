import React, { ReactElement } from 'react';
import { Component, FormEvent } from 'react';
import { Button } from '@citrus-tracker/ui';

import './create-account.module.scss';

export class CreateAccount extends Component {
    props: Readonly<{
        submitCreate(): Promise<boolean>;
        setParentState(passedState: { [key: string]: unknown }): void;
    }>;
    state: {
        successMessage: string;
        errorMessage: string;
    } = {
        successMessage: '',
        errorMessage: '',
    };

    componentDidMount(): void {
        const createToken = new URLSearchParams(window.location.search).get(
            'token',
        );
        if (createToken) {
            this.props.setParentState({
                createToken: createToken,
            });
        }
    }

    submitCreate = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.props.submitCreate();
    };

    render(): ReactElement {
        const { setParentState } = this.props;
        const { successMessage, errorMessage } = this.state;

        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Welcome
                        </h2>
                        {successMessage && (
                            <div className="alert alert-success text-center">
                                <strong>{successMessage}</strong>
                            </div>
                        )}
                        {errorMessage && (
                            <div className="alert alert-danger text-center">
                                <strong>{errorMessage}</strong>
                            </div>
                        )}
                    </div>
                    <form
                        className="mt-8 space-y-6"
                        onSubmit={this.submitCreate}
                    >
                        <div>
                            <p className="mt-2 text-center text-sm text-gray-600">
                                Create an Account Password
                            </p>
                        </div>

                        <input
                            type="hidden"
                            name="remember"
                            defaultValue="true"
                        />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label
                                    htmlFor="login-password"
                                    className="sr-only"
                                >
                                    New Password
                                </label>
                                <input
                                    id="login-password"
                                    type="password"
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    onChange={(event): void =>
                                        setParentState({
                                            newPassword:
                                                event.currentTarget.value,
                                        })
                                    }
                                    placeholder="New Password"
                                />
                            </div>
                        </div>

                        <div>
                            <Button icon="LockClosedIcon" type="submit">
                                Create
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default CreateAccount;

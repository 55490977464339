import React, {
    ChangeEvent,
    Component,
    Fragment,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import './table.scss';

import { Api } from '@citrus-tracker/api';
import { Input, Select } from '@citrus-tracker/layout/form';
import {
    getFlatRecordValue,
    Record,
    RecordFilter,
    RecordFilterOptions,
    RecordOptions,
    User,
} from '@citrus-tracker/types';
import BulkEditRecord from '../edit/bulk-edit';
import Filter, { FilterColumn } from '../filter/filter';
import { Button, Table as UITable, Modal } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentRecordIndex: number;
    filter: RecordFilter;
    filterOptions: RecordFilterOptions;
    records: Record[];
    recordOptions: RecordOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalRecords: number;
    loadRecords(page: number, recordsPerPage: number): Promise<void>;
    updateFilter(filter: RecordFilter): void;
    user?: User;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        bulkEditRecords: Record[];
        deleteOnImport: boolean;
        downloadDropdown: boolean;
        editingRecord?: Record | null;
        fileToImport?: File;
        importError: string;
        importLoading: boolean;
        page: number;
        recordsPerPage: number;
        selectedForBulkEdit: number[];
        showImport: boolean;
        visibleColumns: {
            [key: string]: boolean;
        };
    } = {
        bulkEditRecords: [],
        deleteOnImport: false,
        downloadDropdown: false,
        importError: '',
        importLoading: false,
        page: 1,
        recordsPerPage: 15,
        selectedForBulkEdit: [],
        showImport: false,
        visibleColumns: {
            grower_provided: false,
            block_description_provided: false,
            grower_id: true,
            grower_name: true,
            district: true,
            block: true,
            block_name: true,
            organic: true,
            acres: true,
            trees: true,
            trees_per_acre: true,
            plant_year: true,
            google_maps_url: true,
            status: true,
            updated_by: true,
            last_updated: true,
            date_created: true,
            packingHouse: true,
            crop: true,
            variety: true,
            subVariety: true,
            plantAge: true,
            rootstock: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentRecord();

        const columnString = localStorage.getItem('visibleColumns');
        if (columnString) {
            this.setState({ visibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );

        const page = localStorage.getItem('recordPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentRecordIndex } = this.props;

        if (
            currentRecordIndex &&
            (!oldProps.records ||
                currentRecordIndex !== oldProps.currentRecordIndex)
        ) {
            this.goToCurrentRecord();
        }
    }

    goToCurrentRecord = (): void => {
        const { currentRecordIndex, records } = this.props;

        if (records) {
            if (currentRecordIndex) {
                this.goToRecord(currentRecordIndex);
            } else {
                this.goToRecord(1);
            }
        }
    };

    confirmImport = async (): Promise<void> => {
        const { deleteOnImport, fileToImport } = this.state;

        if (!fileToImport) {
            return;
        }

        this.setState({ importError: '', importLoading: true });

        try {
            const success = await Api.records.import(
                fileToImport,
                deleteOnImport,
            );
            if (success) {
                this.setState(
                    {
                        fileToImport: null,
                        importLoading: false,
                        showImport: false,
                    },
                    () => {
                        const {
                            filter,
                            loadRecords,
                            updateFilter,
                        } = this.props;
                        const { page, recordsPerPage } = this.state;
                        loadRecords(page, recordsPerPage);
                        updateFilter(filter);
                    },
                );
            } else {
                this.setState({
                    importError: 'There was a problem parsing the file',
                    importLoading: false,
                });
            }
        } catch (error) {
            this.setState({ importError: error.message, importLoading: false });
        }
    };

    goToPage = (newPage: number): void => {
        const { loadRecords } = this.props;
        const { recordsPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadRecords(newPage, recordsPerPage);

        //Store page in session so we can persist across page reloads
        localStorage.setItem('recordPage', JSON.stringify(newPage));

    };

    goToRecord = (recordIndex: number): void => {
        const { recordsPerPage } = this.state;

        if (recordsPerPage) {
            const page = localStorage.getItem('recordPage') ?? Math.ceil(recordIndex / recordsPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    inlineEdit = async (recordId: number | null | undefined): Promise<void> => {
        const { loadRecords } = this.props;
        const { page, recordsPerPage } = this.state;

        await loadRecords(page, recordsPerPage);

        if (recordId) {
            const { records } = this.props;

            for (const record of records) {
                if (record.id === recordId) {
                    this.setState({ editingRecord: record });
                }
            }
        } else {
            this.setState({ editingRecord: null });
        }
    };

    inlineUpdate = async (
        recordId: number | undefined,
        property: keyof Record,
        newValue: string | number | { id: number } | null,
    ): Promise<void> => {
        if (!newValue) {
            newValue = null;
        }
        if (recordId && property) {
            await Api.records.save({ id: recordId, [property]: newValue });

            const { editingRecord } = this.state;
            if (editingRecord && editingRecord.id === recordId) {
                this.setState({
                    editingRecord: { ...editingRecord, [property]: newValue },
                });
            }
        }
    };

    openBulkEdit = (): void => {
        const { records } = this.props;
        const { selectedForBulkEdit } = this.state;

        const bulkEditRecords = records.filter(
            (record) =>
                record.id !== undefined &&
                selectedForBulkEdit.indexOf(record.id) >= 0,
        );
        this.setState({ bulkEditRecords });
    };

    closeBulkdEdit = (): void => {
        this.setState({ bulkEditRecords: [] });
    };

    openImport = (): void => {
        this.setState({
            importError: '',
            showImport: true,
        });
    };

    openEdit = (recordIndex?: number): void => {
        if (recordIndex) {
            this.props.history.push(`/records/${recordIndex}/edit`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    selectFileToImport = (e: ChangeEvent<HTMLInputElement>): void => {
        const files = e.target.files;

        if (files && files.length > 0) {
            this.setState({ fileToImport: files[0] });
        }
    };

    toggleColumnVisibility = (property: keyof Record): void => {
        const visibleColumns = {
            ...this.state.visibleColumns,
            [property]: !this.state.visibleColumns[property],
        };

        this.setState({
            visibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('visibleColumns', JSON.stringify(visibleColumns));
    };

    renderForDisplay = (
        record: Record,
        property: keyof Record,
    ): ReactElement => {
        switch (property) {
            case 'crop':
                return <span>{record.crop && record.crop.crop}</span>;
            case 'packingHouse':
                return (
                    <span title={record.packingHouse &&
                      record.packingHouse.packing_house_name}>
                        {record.packingHouse &&
                            record.packingHouse.packing_house_name}
                    </span>
                );
            case 'grower_id':
                return (
                  <span title={record.grower_id}>
                      {record.grower_id}
                  </span>
                );
            case 'grower_name':
              return (
                <span title={record.grower_name}>
                    {record.grower_name}
                </span>
              );
            case 'plantAge':
                return <span>{record.plantAge && record.plantAge.age}</span>;
            case 'rootstock':
                return (
                    <span>
                        {record.rootstock && record.rootstock.rootstock}
                    </span>
                );
            case 'subVariety':
                return (
                    <span>
                        {record.subVariety && record.subVariety.sub_variety}
                    </span>
                );
            case 'trees':
                return (
                    <span>
                      {
                        record.trees && new Intl.NumberFormat()
                          .format(Math.round(record.trees))
                          .toString()
                      }
                    </span>
                );
            case 'trees_per_acre':
                return (
                    <span>
                      {
                        record.trees_per_acre && new Intl.NumberFormat()
                          .format(Math.round(record.trees_per_acre))
                          .toString()
                      }
                    </span>
                );
            case 'trees_per_bin':
                return (
                    <span>
                      {
                        record.trees_per_bin && new Intl.NumberFormat()
                          .format(Math.round(record.trees_per_bin))
                          .toString()
                      }
                    </span>
                );
            case 'variety':
                return <span>{record.variety && record.variety.variety}</span>;
            default:
                return <span>{record[property]}</span>;
        }
    };

    renderForEdit = (record: Record, property: keyof Record): ReactElement => {
        const { recordOptions } = this.props;

        const selectedCropId =
            record && record.crop && record.crop.id ? record.crop.id : null;
        const selectedVarietyId =
            record && record.variety && record.variety.id
                ? record.variety.id
                : null;
        if (selectedCropId) {
            for (const crop of recordOptions.crops) {
                if (crop.id === selectedCropId) {
                    recordOptions.selectedCrop = crop;

                    if (
                        selectedVarietyId &&
                        recordOptions.selectedCrop.varieties
                    ) {
                        for (const variety of recordOptions.selectedCrop
                            .varieties) {
                            if (variety.id === selectedVarietyId) {
                                recordOptions.selectedVariety = variety;
                            }
                        }
                    }
                }
            }
        }

        switch (property) {
            case 'packingHouse':
                return (
                    <span>
                        {record.packingHouse &&
                            record.packingHouse.packing_house_name}
                    </span>
                );
            case 'grower_provided':
            case 'block_description_provided':
                return <span>{record[property]}</span>;
            case 'acres':
            case 'trees_per_acre':
                return (
                    <span>
                        <Input
                            id={property}
                            type="number"
                            step="0.01"
                            value={getFlatRecordValue(record, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    property,
                                    newValue,
                                )
                            }
                        />
                    </span>
                );
            case 'plant_year':
                return (
                    <span>
                        <Input
                            id="plant_year"
                            type="number"
                            min="1900"
                            max={new Date().getFullYear()}
                            value={getFlatRecordValue(record, 'plant_year')}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    'plant_year',
                                    newValue,
                                )
                            }
                        />
                    </span>
                );
            case 'trees':
                return (
                    <span>
                        <Input
                            id="trees"
                            disabled={!!record?.trees_per_acre}
                            type="number"
                            value={getFlatRecordValue(record, 'trees')}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(record?.id, 'trees', newValue)
                            }
                        />
                    </span>
                );
            // Selects
            case 'district':
            case 'organic':
                return (
                    <span>
                        <Select
                            id={property}
                            options={recordOptions.getSelectOptions(property)}
                            value={getFlatRecordValue(record, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    property,
                                    newValue,
                                )
                            }
                        />
                    </span>
                );
            // Related objects
            case 'crop':
            case 'variety':
            case 'subVariety':
            case 'rootstock':
                return (
                    <span>
                        <Select
                            id={property}
                            options={recordOptions.getSelectOptions(property)}
                            value={getFlatRecordValue(record, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    property,
                                    newValue
                                        ? {
                                              id: parseInt(newValue),
                                          }
                                        : null,
                                )
                            }
                        />
                    </span>
                );
            case 'plantAge':
                return (
                    <span>
                        <Select
                            id={property}
                            disabled={record.plant_year}
                            options={recordOptions.getSelectOptions(property)}
                            value={getFlatRecordValue(record, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    property,
                                    newValue
                                        ? {
                                              id: parseInt(newValue),
                                          }
                                        : null,
                                )
                            }
                        />
                    </span>
                );
            default:
                return (
                    <span>
                        <Input
                            id={property}
                            value={getFlatRecordValue(record, property)}
                            onChange={(newValue: string): unknown =>
                                this.inlineUpdate(
                                    record?.id,
                                    property,
                                    newValue,
                                )
                            }
                        />
                    </span>
                );
        }
    };

    toggleSelectedForBulkEdit = (recordId: number | undefined): void => {
        if (recordId === undefined) {
            return;
        }

        const { selectedForBulkEdit } = this.state;

        const i = selectedForBulkEdit.indexOf(recordId);
        if (i >= 0) {
            selectedForBulkEdit.splice(i, 1);
        } else {
            selectedForBulkEdit.push(recordId);
        }

        this.setState({ selectedForBulkEdit });
    };

    toggleAllSelectedForBulkEdit = (selected: boolean): void => {
        const { records } = this.props;

        let selectedForBulkEdit: number[] = [];
        if (selected) {
            selectedForBulkEdit = records.map((record) => record.id || 0);
        }

        this.setState({ selectedForBulkEdit });
    };

    bulkEditFinished = (): void => {
        const { loadRecords } = this.props;
        const { page, recordsPerPage } = this.state;

        loadRecords(page, recordsPerPage);

        this.setState({ bulkEditRecords: [] });
    };

    render(): ReactElement {
        const {
            records,
            recordOptions,
            totalRecords,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            user,
        } = this.props;
        const {
            bulkEditRecords,
            deleteOnImport,
            editingRecord,
            fileToImport,
            importError,
            importLoading,
            page,
            recordsPerPage,
            selectedForBulkEdit,
            showImport,
            visibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalRecords / recordsPerPage);

        const indexBase = (page - 1) * recordsPerPage;

        const allColumns: {
            property: keyof Record;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'packingHouse' as keyof Record,
                title: 'Packing House',
                filter: true,
                sort: 'packingHouse.packing_house_name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'grower_provided' as keyof Record,
                title: 'Grower Provided',
                sort: 'record.grower_provided',
            },
            {
                property: 'block_description_provided' as keyof Record,
                title: 'Block Description Provided',
                sort: 'record.block_description_provided',
            },
            {
                property: 'grower_id' as keyof Record,
                title: 'Grower ID',
                sort: 'record.grower_id',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'grower_name' as keyof Record,
                title: 'Grower',
                sort: 'record.grower_name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'acres' as keyof Record,
                title: 'Planted Acres',
                sort: 'record.acres',
            },
            {
                property: 'block' as keyof Record,
                title: 'Block ID',
                sort: 'record.block',
            },
            {
                property: 'block_name' as keyof Record,
                title: 'Block Description',
                sort: 'record.block_name',
            },
            {
                property: 'district' as keyof Record,
                title: 'District',
                filter: true,
                sort: 'record.district',
            },
            {
                property: 'crop' as keyof Record,
                title: 'Crop',
                filter: true,
                sort: 'crop.crop',
            },
            {
                property: 'variety' as keyof Record,
                title: 'Sunkist Marketing Variety',
                filter: true,
                sort: 'variety.variety',
            },
            {
                property: 'subVariety' as keyof Record,
                title: 'Sub-Variety',
                filter: true,
                sort: 'subVariety.sub_variety',
            },
            {
                property: 'organic' as keyof Record,
                title: 'Organic',
                sort: 'record.organic',
            },
            {
                property: 'plant_year' as keyof Record,
                title: 'Plant Year',
                sort: 'record.plant_year',
            },
            {
                property: 'plantAge' as keyof Record,
                title: 'Age Range',
                //for some reason record.plantAge and record.plant_age_id don't sore properly, this does
                sort: 'record.plant_year',
            },
            {
                property: 'rootstock' as keyof Record,
                title: 'Rootstock',
                filter: true,
                sort: 'record.rootstock',
            },
            {
                property: 'trees_per_acre' as keyof Record,
                title: 'Trees/Acre',
                sort: 'record.trees_per_acre',
            },
            {
                property: 'trees' as keyof Record,
                title: 'Trees',
                //for some reason record.trees does not sort in an expected way, but this seems to work
                sort: 'record.trees_per_acre * record.acres',
            },
        ];

        //Don't show Packing House column to non-admins
        if (!user?.isAdmin()) {
            allColumns.shift();
        }

        const columns = allColumns.filter(
            (column) => visibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        let start = (page * recordsPerPage) - (recordsPerPage - 1);
        let end = Math.min(start + recordsPerPage - 1, totalRecords);

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        openBulkEdit={
                            selectedForBulkEdit.length > 0
                                ? this.openBulkEdit
                                : undefined
                        }
                        openImport={this.openImport}
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={user?.isAdmin()}
                        visibleColumns={visibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={records}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                        //bulk edit
                        toggleSelectedForBulkEdit={
                            this.toggleSelectedForBulkEdit
                        }
                        toggleAllSelectedForBulkEdit={
                            this.toggleAllSelectedForBulkEdit
                        }
                        selectedForBulkEdit={selectedForBulkEdit}
                        //inline edit
                        inlineEdit={this.inlineEdit}
                        editingRow={editingRecord}
                        renderForEdit={this.renderForEdit}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalRecords} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    title="Import records from a .csv file"
                    show={showImport}
                    close={(): void =>
                        this.setState({
                            showImport: false,
                        })
                    }
                    content={
                        <Fragment>
                            {' '}
                            <div className="block-content">
                                <label htmlFor="deleteOnImport">
                                    <input
                                        type="checkbox"
                                        id="deleteOnImport"
                                        checked={deleteOnImport}
                                        onChange={(): void =>
                                            this.setState({
                                                deleteOnImport: !deleteOnImport,
                                            })
                                        }
                                    />{' '}
                                    Delete records not in the file
                                </label>

                                <input
                                    type="file"
                                    onChange={this.selectFileToImport}
                                    className="mt-4"
                                />
                            </div>
                            {!!importError && (
                                <div className="block-content">
                                    <div className="alert alert-error text-center">
                                        <strong>{importError}</strong>
                                    </div>
                                </div>
                            )}
                            {importLoading && (
                                <div className="block-content">Processing</div>
                            )}
                            <Button
                                onClick={(): void => {
                                    this.confirmImport();
                                }}
                                type="button"
                                data-dismiss="modal"
                                disabled={!fileToImport || importLoading}
                                className="float-right mt-4"
                            >
                                Import
                            </Button>
                            <Button
                                type="button"
                                onClick={(): void =>
                                    this.setState({
                                        showImport: false,
                                    })
                                }
                                data-dismiss="modal"
                                disabled={importLoading}
                                variant="secondary"
                                className="mr-4 float-right mt-4"
                            >
                                Cancel
                            </Button>
                        </Fragment>
                    }
                />
                <Modal
                    title="Edit all selected records"
                    show={bulkEditRecords.length > 0}
                    close={this.closeBulkdEdit}
                    content={
                        <Fragment>
                            {' '}
                            <div className="block-content">
                                <BulkEditRecord
                                    onFinished={this.bulkEditFinished}
                                    records={bulkEditRecords}
                                    recordOptions={recordOptions}
                                />
                            </div>
                        </Fragment>
                    }
                />
            </div>
        );
    }
}

export default withRouter(Table);

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './estimate-table.scss';

import {
    FieldEstimate,
    Record,
    RecordFilter,
    FieldEstimateFilter,
 } from '@citrus-tracker/types';

export interface TableProps extends RouteComponentProps {
    cartonsPerBin: number;
    filter: RecordFilter;
    record: Record;
    preseasonRecord: Record;
    updateActiveRecord(property: keyof FieldEstimate, value: unknown): void;
    updateFilter(filter: RecordFilter): void;
}
export class EstimateTable extends Component<TableProps> {
    props: Readonly<TableProps>;

    state: {
        editingRecord: Record;
        preseasonRecord: Record;
    } = {
        editingRecord: {},
        preseasonRecord: {},
    };

    componentDidMount(): void {
        this.loadPage();
    }

    componentDidUpdate(oldProps: TableProps): void {
        if (
            this.props.record !==
            oldProps.record
        ) {
            this.loadPage();
        }
    }

    loadPage(): void{
        this.setState({
            editingRecord: this.props.record,
            preseasonRecord: this.props.preseasonRecord,
        });
    }

    updateActiveRecord = (
        property: keyof FieldEstimate,
        value: unknown,
    ): void => {
        const updates = {
            ...this.state.editingRecord,
        };

        const newUpdates = {
            ...updates,
        };

        if (
            property === 'total_bins' &&
            newUpdates.fieldEstimate &&
            typeof value === 'string'
        ) {
            const calcValue = parseInt(value);
            newUpdates.fieldEstimate.trees_per_bin =
                parseFloat(((newUpdates.trees ?? 0) / (calcValue ?? 0)).toLocaleString(undefined, {maximumFractionDigits:2}));
            newUpdates.fieldEstimate.cartons_per_tree =
                parseFloat(((this.props.cartonsPerBin * (calcValue ?? 0)) /
                (newUpdates.trees ?? 0)).toLocaleString(undefined, {maximumFractionDigits:2}));
        } else if (
            property === 'trees_per_bin' &&
            newUpdates.fieldEstimate &&
            typeof value === 'string'
        ) {
            const calcValue = parseInt(value);
            newUpdates.fieldEstimate.total_bins =
                parseFloat(((newUpdates.trees ?? 0) / (calcValue ?? 0)).toLocaleString(undefined, {maximumFractionDigits:2}));
            newUpdates.fieldEstimate.cartons_per_tree =
                parseFloat(((this.props.cartonsPerBin *
                    (newUpdates.fieldEstimate.total_bins ?? 0)) /
                (newUpdates.trees ?? 0)).toLocaleString(undefined, {maximumFractionDigits:2}));
        } else if (
            property === 'cartons_per_tree' &&
            newUpdates.fieldEstimate &&
            typeof value === 'string'
        ) {
            const calcValue = parseInt(value);
            newUpdates.fieldEstimate.total_bins =
                parseFloat((((newUpdates.trees ?? 0) * (calcValue ?? 0)) /
                (this.props.cartonsPerBin ?? 0)).toLocaleString(undefined, {maximumFractionDigits:2}));
            newUpdates.fieldEstimate.trees_per_bin =
                parseFloat(((newUpdates.trees ?? 0) /
                (newUpdates.fieldEstimate.total_bins ?? 0)).toLocaleString(undefined, {maximumFractionDigits:2}));
        }

        if (newUpdates.fieldEstimate)
            (newUpdates.fieldEstimate[property] as unknown) = value;

        this.setState({
            editingRecord: newUpdates,
        });

        this.props.updateActiveRecord(property, value);
    };

    render(): ReactElement {
        const { editingRecord, preseasonRecord } = this.state;
        const { cartonsPerBin } = this.props;

        return (
            <div className="block" style={{ height: 581 }}>
                <div className="block-content" style={{ height: 581 }}>
                    <p>
                        <strong>Estimate</strong>
                    </p>
                    <table className="table-fixed divide-y divide-gray-200">
                        <thead className="bg-gray-50 text-xs text-gray-500 h-10">
                            <tr>
                                <th className="w-96 text-left">ESTIMATE</th>
                                <th></th>
                                <th className="text-right text-wrap-break-word">
                                    PICKED TO DATE
                                </th>
                                <th className="w-64 text-right">REMAINING</th>
                                <th className="w-64 text-right">SEASON</th>
                                <th className="w-64 text-right">PRE SEASON</th>
                                <th className="w-64 text-right">VARIANCE</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left whitespace-nowrap">
                                    <strong>Bins</strong>
                                </td>
                                <td></td>
                                <td className="bg-white w-14 text-right whitespace-nowrap">
                                    <input
                                        id="bins_picked_to_date"
                                        className="text-right pr-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'picked_to_date',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord?.fieldEstimate
                                                ?.picked_to_date
                                        }
                                        disabled={!preseasonRecord.fieldEstimate?.id}
                                        placeholder="0"
                                    />
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                        (editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits:2})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    <input
                                        id="total_bins"
                                        className="text-right h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'total_bins',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord?.fieldEstimate
                                            ?.total_bins
                                        }
                                        placeholder="0"
                                        step={1}
                                    />
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {parseInt((preseasonRecord.fieldEstimate?.total_bins ?? 0).toString()).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) -
                                        (preseasonRecord?.fieldEstimate
                                            ?.total_bins ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits:2})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Trees per Bin</strong>
                                </td>
                                <td></td>
                                <td className="bg-white w-14 text-right text-wrap-break-word"></td>
                                <td></td>
                                <td className="bg-white w-64 text-right">
                                    <input
                                        id="trees_per_bin"
                                        className="text-right h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'trees_per_bin',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            (editingRecord?.fieldEstimate?.trees_per_bin ?? 0)
                                          .toLocaleString(undefined, {maximumFractionDigits: 1})
                                        }
                                        placeholder="0"
                                    />
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (preseasonRecord.trees ?? 0) /
                                        (preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits: 1})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(editingRecord.fieldEstimate?.total_bins ??
                                        0) -
                                        (preseasonRecord?.fieldEstimate
                                            ?.total_bins ?? 0) !==
                                        0 &&
                                        (
                                            (preseasonRecord.trees ?? 0) /
                                            ((editingRecord.fieldEstimate
                                                ?.total_bins ?? 0) -
                                                (preseasonRecord?.fieldEstimate
                                                    ?.total_bins ?? 0))
                                        ).toLocaleString(undefined, {maximumFractionDigits:2})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left whitespace-nowrap">
                                    <strong>Raw Cartons per Tree</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word"></td>
                                <td></td>
                                <td></td>
                                <td className="bg-white w-64 text-right">
                                    <input
                                        id="cartons_per_tree"
                                        className="text-right h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'cartons_per_tree',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            (editingRecord.fieldEstimate
                                                ?.cartons_per_tree ?? 0
                                            ).toLocaleString(undefined, {maximumFractionDigits: 1})
                                        }
                                        placeholder="0"
                                    />
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin) /
                                        (preseasonRecord.trees ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits: 1})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin -
                                            (preseasonRecord.fieldEstimate
                                                ?.total_bins ?? 0) *
                                                cartonsPerBin) /
                                        (preseasonRecord.trees ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits:2})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Cartons per Bin</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word"></td>
                                <td className="bg-white w-64 text-right">
                                    {cartonsPerBin}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {cartonsPerBin}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {cartonsPerBin}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {cartonsPerBin}
                                </td>
                                <td className="bg-white w-64 text-right"></td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Estimated Cartons</strong>
                                </td>
                                <td className="bg-white w-64 text-right"></td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                        cartonsPerBin
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                        cartonsPerBin
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) * cartonsPerBin
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) * cartonsPerBin
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin -
                                        (preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Fresh Utilization</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word">
                                    <input
                                        id="utilization"
                                        className="text-right px-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'utilization',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.utilization
                                        }
                                        placeholder="0"
                                        required
                                    /> %
                                </td>
                                <td className="bg-white w-64 text-right"></td>
                                <td className="bg-white w-64 text-right"></td>
                                <td className="bg-white w-64 text-right"></td>
                                <td className="bg-white w-64 text-right"></td>
                                <td className="bg-white w-64 text-right"></td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Estimated Fresh Cartons</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word"></td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100 -
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-gray-50 text-xs text-gray-500 font-bold h-8">
                                <td className="text-left" colSpan={7}>
                                    MARKET: CARTONS
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Export</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word">
                                    <input
                                        id="export_percent"
                                        className="text-right px-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'export_percent',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.export_percent
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.export_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.export_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.export_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.export_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.export_percent ?? 0) /
                                                100) -
                                            (preseasonRecord.fieldEstimate
                                                ?.total_bins ?? 0) *
                                                cartonsPerBin *
                                                ((editingRecord.fieldEstimate
                                                    ?.export_percent ?? 0) /
                                                    100)) *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Domestic</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word">
                                    {(
                                        100 -
                                        (editingRecord.fieldEstimate
                                            ?.export_percent ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                    %
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.export_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.export_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.export_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.export_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.export_percent ?? 0)) /
                                                100) -
                                            (preseasonRecord.fieldEstimate
                                                ?.total_bins ?? 0) *
                                                cartonsPerBin *
                                                ((100 -
                                                    (editingRecord.fieldEstimate
                                                        ?.export_percent ??
                                                        0)) /
                                                    100)) *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Estimated Fresh Cartons</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word"></td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100 -
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-gray-50 text-xs font-medium text-gray-500 font-bold h-8">
                                <td className="w-80 text-left" colSpan={7}>
                                    GRADE: CARTONS
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Fancy</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word">
                                    <input
                                        id="fancy_percent"
                                        className="text-right px-2 h-6 placeholder-gray-700 appearance-none w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        type="number"
                                        min="0"
                                        max="100"
                                        onChange={(e): void =>
                                            this.updateActiveRecord(
                                                'fancy_percent',
                                                e.target.value,
                                            )
                                        }
                                        value={
                                            editingRecord.fieldEstimate
                                                ?.fancy_percent
                                        }
                                        placeholder="0"
                                    /> %
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.fancy_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.fancy_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.fancy_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.fancy_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.fancy_percent ?? 0) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100 -
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((editingRecord.fieldEstimate
                                                ?.fancy_percent ?? 0) /
                                                100) *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Choice</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word">
                                    {(
                                        100 -
                                        (editingRecord.fieldEstimate
                                            ?.fancy_percent ?? 0)
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                    %
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.fancy_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.fancy_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.fancy_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.fancy_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.fancy_percent ?? 0)) /
                                                100) *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100 -
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            ((100 -
                                                (editingRecord.fieldEstimate
                                                    ?.fancy_percent ?? 0)) /
                                                100) *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                            <tr className="bg-white text-xs h-8">
                                <td className="bg-white w-80 text-left">
                                    <strong>Estimated Fresh Cartons</strong>
                                </td>
                                <td className="bg-white w-14 text-right text-wrap-break-word"></td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord?.fieldEstimate
                                            ?.picked_to_date ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        (((editingRecord?.fieldEstimate
                                            ?.total_bins ?? 0) -
                                            (editingRecord?.fieldEstimate
                                                ?.picked_to_date ?? 0)) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                        100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                                <td className="bg-white w-64 text-right">
                                    {(
                                        ((editingRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (editingRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100 -
                                        ((preseasonRecord.fieldEstimate
                                            ?.total_bins ?? 0) *
                                            cartonsPerBin *
                                            (preseasonRecord.fieldEstimate
                                                ?.utilization ?? 0)) /
                                            100
                                    ).toLocaleString(undefined, {maximumFractionDigits:0})}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(EstimateTable);

import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DynamicIcon } from '../dynamic-icon/dynamic-icon';
import './dropdown-menu.module.scss';

/* eslint-disable-next-line */
export interface DropdownMenuProps {
    label?: string;
    items?: Array<any>;
    icon?: string;
    showArrow?: boolean;
}

export function DropdownMenu(props: DropdownMenuProps) {
    var buttonClasses =
        'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500';
    if (props.icon && !props.label) {
        buttonClasses = buttonClasses + ' px-2';
    } else {
        buttonClasses = buttonClasses + ' px-4';
    }
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className={buttonClasses}>
                            {props.icon && (
                                <DynamicIcon
                                    className={
                                        props.label ? 'h-5 w-5 mr-2' : 'h-5 w-5'
                                    }
                                    icon={props.icon}
                                />
                            )}
                            {props.label}
                            {props.showArrow && (
                                <DynamicIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    icon="ChevronDownIcon"
                                />
                            )}
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 pt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50 focus:outline-none"
                        >
                            {props.items?.map((item, key) => (
                                <Menu.Item key={key}>{item}</Menu.Item>
                            ))}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}

export default DropdownMenu;

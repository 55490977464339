import React, { Component, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { Api } from '@citrus-tracker/api';
import { Block, BlockOptions, Grower, User } from '@citrus-tracker/types';
import BlockForm from './form';

import './edit.module.scss';

interface PathParams {
    indexInFilteredList: string;
}

/* eslint-disable-next-line */
export interface NewProps extends RouteComponentProps<PathParams> {
    growerId: string;
    blockOptions: BlockOptions;
    user?: User | null;
}

export class NewBlock extends Component<NewProps> {
    props: Readonly<NewProps>;

    state: {
        error: string;
        block: Block;
    } = {
        error: '',
        block: { trees: 0 } as Block,
    };

    saveChanges = async (): Promise<void> => {
        const { block } = this.state;

        if(this.props.user?.isPackingHouseUser()){
            block.packingHouseId = this.props.user?.packingHouse?.id;
            block.packingHouseUserId = this.props.user?.id;
        }

        await Api.blocks.save(block);
        this.props.history.goBack();
    };

    setUpdates = (block: Block): void => {
        this.setState({ block });
    };

    render(): ReactElement {
        const { block } = this.state;
        const { blockOptions } = this.props;

        return (
            <div className="block">
                <div className="block-header block-header-default">
                    <h3 className="block-title">New Block</h3>
                </div>

                <BlockForm
                    block={block}
                    blockOptions={blockOptions}
                    saveChanges={this.saveChanges}
                    setUpdates={this.setUpdates}
                    user={this.props.user ?? undefined}
                />
            </div>
        );
    }
}

export default withRouter(NewBlock);

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Api } from '@citrus-tracker/api';
import { Grower, Ranch, User } from '@citrus-tracker/types';

import { Button } from '@citrus-tracker/ui';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

interface PathParams {
    userId: string;
    id: string;
}

/* eslint-disable-next-line */
export interface RanchEditProps extends RouteComponentProps<PathParams> {
    user: User;
}

export class RanchEditComponent extends Component<RanchEditProps> {
    props: Readonly<RanchEditProps>;

    state: {
        userId: string;
        ranchId: string;
        ranch: Ranch;
        growers: Grower[];
        counties: string[];
        growerOptions: [];
        selectedGrower: any;
        showEntity: boolean;
    } = {
        userId: '',
        ranchId: '',
        ranch: {
            wellWater: false,
            districtWater: false,
            windMachine: false,
        } as Ranch,
        growers: [],
        counties: [
            'Alameda',
            'Alpine',
            'Amador',
            'Apache',
            'Butte',
            'Calaveras',
            'Cochise',
            'Coconino',
            'Colusa',
            'Contra Costa',
            'Del Norte',
            'El Dorado',
            'Fresno',
            'Gila',
            'Glenn',
            'Graham',
            'Greenlee',
            'Humboldt',
            'Imperial',
            'Inyo',
            'Kern',
            'Kings',
            'La Paz',
            'Lake',
            'Lassen',
            'Los Angeles',
            'Madera',
            'Maricopa',
            'Marin',
            'Mariposa',
            'Mendocino',
            'Merced',
            'Modoc',
            'Mohave',
            'Mono',
            'Monterey',
            'Napa',
            'Navajo',
            'Nevada',
            'Orange',
            'Pima',
            'Pinal',
            'Placer',
            'Plumas',
            'Riverside',
            'Sacramento',
            'San Benito',
            'San Bernardino',
            'San Diego',
            'San Francisco',
            'San Joaquin',
            'San Luis Obispo',
            'San Mateo',
            'Santa Barbara',
            'Santa Clara',
            'Santa Cruz',
            'Shasta',
            'Sierra',
            'Siskiyou',
            'Solano',
            'Sonoma',
            'Stanislaus',
            'Sutter',
            'Tehama',
            'Trinity',
            'Tulare',
            'Tuolumne',
            'Ventura',
            'Yavapai',
            'Yolo',
            'Yuba',
            'Yuma',
        ],
        growerOptions: [],
        selectedGrower: null,
        showEntity: false,
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;
        const userId = this.props.match.params.userId;

        this.loadGrowersByUser(userId);

        if (id !== 'new') {
            this.setState({ ranchId: id });
            setTimeout( () => this.loadRanch(id.toString()), 100);
        }
    }

    loadGrowersByUser = async (userId: string): Promise<void> => {
        const growers = await Api.growers.getAllByUserId(userId);
        const growerOptions = [];

        for (const grower of growers) {
            growerOptions.push({id: grower.id, label: grower.name});
        }

        this.setState({ userId, growers, growerOptions });
    };

    loadRanch = async (ranchId: string): Promise<void> => {
        const ranch = await Api.ranches.get(ranchId);

        // @ts-ignore
        const selectedOption = this.state.growerOptions.find((grower) => grower.id === ranch.grower?.id);

        this.setState({
            ranch: ranch,
            selectedGrower: selectedOption ? [selectedOption] : null,
        });
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    setValue = (property: keyof Ranch, value: unknown): void => {
        const ranch = {
            ...this.state.ranch,
        };

        if (property === 'grower') {
            this.setState({ selectedGrower: value });
            // @ts-ignore
            if(value[0] && value[0].id){
                // @ts-ignore
                value = this.state.growers.find((grower) => grower.id === value[0].id)?.id ?? null;
            }
        }

        const newRanch = {
            ...ranch,
            [property]: value,
        };

        this.setRanch(newRanch);
    };

    setRanch = (ranch: Ranch): void => {
        this.setState({
            ranch,
        });
    };

    delete = async (): Promise<void> => {
        if (
            window.confirm(
                `Are you sure you want to delete ${this.state.ranch.ranchName}`,
            ) === true
        ) {
            await Api.ranches.delete(this.state.ranch.id);
            this.props.history.goBack();
        }
    };

    saveChanges = async (): Promise<void> => {
        const ranch = this.state.ranch;

        if(this.props.user?.isPackingHouseUser()){
            ranch.packingHouseId = this.props.user?.packingHouse?.id;
            ranch.packingHouseUserId = this.props.user?.id;
        }

        await Api.ranches.save(ranch);
        this.props.history.goBack();
    };

    render(): ReactElement {
        const { ranch, ranchId, counties, showEntity, selectedGrower } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <div className="text-4xl font-bold text-black">Ranch</div>
                </div>
                <div>
                    <form
                        className="w-full max-w-xlg rounded bg-white p-8"
                        id="form"
                    >
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="ranchName"
                                >
                                    Ranch Name
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="ranchName"
                                    type="text"
                                    placeholder="Ranch Name"
                                    value={ranch.ranchName}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'ranchName',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                { this.props.user.isPackingHouseUser() &&
                                    <div className="float-right inline-flex">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                className="form-checkbox"
                                                name="showEntity"
                                                checked={showEntity === true}
                                                onClick={(): void => {
                                                    this.setState({
                                                        showEntity: !this.state.showEntity,
                                                    });
                                                }}
                                            />
                                            <span className="ml-2">Select a current Grower Entity</span>
                                        </label>
                                    </div>
                                }
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grower"
                                >
                                    Grower Entity
                                </label>
                                {(this.props.user.isAdmin() || showEntity) && 
                                    <Typeahead
                                        selected={selectedGrower}
                                        onChange={(selected): void => {
                                            this.setValue(
                                                'grower',
                                                selected,
                                            )
                                        }}
                                        inputProps={{
                                            className: "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " + (ranchId && this.props.user.isAdmin() && !selectedGrower ? 'border-red-500 focus:border-red-500' : ''),
                                        }}
                                        id='user'
                                        options={this.state.growerOptions}
                                        highlightOnlyResult={true}
                                        placeholder="Select a Grower Entity"
                                    />
                                }
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="county"
                                >
                                    County
                                </label>
                                <select
                                    required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="county"
                                    value={ranch.county}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'county',
                                            e.currentTarget.value,
                                        )
                                    }
                                >
                                    <option>Select a County</option>
                                    {counties.map((county) => (
                                        <option key={county} value={county}>
                                            {county}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="wellWater"
                                >
                                    Well Water
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name="wellWater"
                                        checked={ranch.wellWater == true}
                                        value="1"
                                        onClick={(e): void =>
                                            this.setValue(
                                                'wellWater',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name="wellWater"
                                        checked={ranch.wellWater == false}
                                        value="0"
                                        onClick={(e): void =>
                                            this.setValue(
                                                'wellWater',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="districtWater"
                                >
                                    District Water
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name="districtWater"
                                        value="1"
                                        checked={ranch.districtWater == true}
                                        onClick={(e): void =>
                                            this.setValue(
                                                'districtWater',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name="districtWater"
                                        value="0"
                                        checked={ranch.districtWater == false}
                                        onClick={(e): void =>
                                            this.setValue(
                                                'districtWater',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                        </div>
                        {ranch.districtWater == true && (
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="districtName"
                                    >
                                        Water District Name
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="districtName"
                                        type="text"
                                        placeholder="District Name"
                                        value={ranch.districtName}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'districtName',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="windMachine"
                                >
                                    Wind Machine
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name="windMachine"
                                        value="1"
                                        checked={ranch.windMachine == true}
                                        onClick={(e): void =>
                                            this.setValue(
                                                'windMachine',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input
                                        type="radio"
                                        className="form-radio"
                                        name="windMachine"
                                        value="0"
                                        checked={ranch.windMachine == false}
                                        onClick={(e): void =>
                                            this.setValue(
                                                'windMachine',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                        </div>

                        {ranch.windMachine == true && (
                            <div className="flex flex-wrap -mx-3 mb-2">
                                <div className="w-full px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="districtName"
                                    >
                                        Wind Machine Count
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="windMachineCount"
                                        type="text"
                                        placeholder="# of wind machines"
                                        value={ranch.windMachineCount}
                                        onChange={(e): void =>
                                            this.setValue(
                                                'windMachineCount',
                                                e.currentTarget.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </form>

                    <div className="py-2">
                        <Button
                            className="float-right"
                            onClick={this.saveChanges}
                            type="button"
                        >
                            Save Changes
                        </Button>
                        {ranchId && ranchId !== 'new' && (
                            <Button
                                className="mx-2 float-right"
                                onClick={this.delete}
                                type="button"
                                variant="delete"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RanchEditComponent);

import React, { ReactElement } from 'react';
import { Component, FormEvent } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { User } from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';

import './login.module.scss';

export class Login extends Component {
    props: Readonly<{
        submitLogin(): Promise<boolean>;
        setParentState(passedState: { [key: string]: unknown }): void;
        user?: User | null;
    }>;

    state: {
        message: string;
    } = {
        message: '',
    };

    submitLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        const loggedin = await this.props.submitLogin();
        if (!loggedin) {
            this.setState({ message: 'Invalid username or password' });
        }
    };

    render(): ReactElement {
        const { setParentState, user } = this.props;
        const { message } = this.state;
        if (user) {
            return <Redirect to="/" />;
        }
        return (
            <div className="hero min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 bg-white p-8 bg-opacity-90 rounded">
                    <div className="bg-opacity-100">
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Welcome
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Please Sign In
                        </p>
                    </div>
                    <form
                        className="mt-8 space-y-6"
                        onSubmit={this.submitLogin}
                    >
                        <input
                            type="hidden"
                            name="remember"
                            defaultValue="true"
                        />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label
                                    htmlFor="login-username"
                                    className="sr-only"
                                >
                                    Username
                                </label>
                                <input
                                    id="login-username"
                                    type="email"
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    onChange={(event): void =>
                                        setParentState({
                                            email: event.currentTarget.value,
                                        })
                                    }
                                    placeholder="Username"
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="login-password"
                                    className="sr-only"
                                >
                                    Password
                                </label>
                                <input
                                    id="login-password"
                                    type="password"
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    onChange={(event): void =>
                                        setParentState({
                                            password: event.currentTarget.value,
                                        })
                                    }
                                    placeholder="Password"
                                />
                            </div>
                        </div>

                        <div>
                            <Button icon="LockClosedIcon" type="submit">
                                Sign In
                            </Button>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <Link
                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                    to="/password/reset"
                                >
                                    Reset password
                                </Link>
                            </div>
                        </div>
                        <div>
                            <p className='text-red-600'>{message}</p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;

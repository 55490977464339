import { Crop } from './crop';
import { Variety } from './variety';
import { SubVariety } from './subVariety';
import { Rootstock } from './rootstock';
import { PackingHouse } from './packingHouse';
import { Grower } from './grower';

export interface FilterOption<Type> {
    label: string;
    value: Type;
}

export enum missingDataFilters {
    Acres,
    Age,
    BlockInfo,
    District,
    GrowerInfo,
    Rootstock,
    SubVariety,
    TreesPerAcre,
    Variety,
    Ranch,
}

export interface RecordFilter {
    crop?: Crop;
    district?: string;
    grower_provided?: string;
    missingData?: missingDataFilters;
    packingHouse?: PackingHouse;
    variety?: Variety;
    subVariety?: SubVariety;
    rootstock?: Rootstock;
    searchQuery?: string;
}

export interface RecordFilterOptions {
    crop?: FilterOption<Crop>[];
    district?: FilterOption<string>[];
    grower_provided?: FilterOption<string>[];
    missingData?: FilterOption<missingDataFilters>[];
    packingHouse?: FilterOption<PackingHouse>[];
    searchQuery?: string;
    variety?: FilterOption<Variety>[];
    subVariety?: FilterOption<SubVariety>[];
    rootstock?: FilterOption<Rootstock>[];
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}

export interface AgreementFilter {
    agreementNumber?: string;
    packingHouseName?: PackingHouse;
    status?: string;
    name?: Grower;
    ein?: Grower;
    lastUpdated?: string;
    updatedBy?: string;
    searchQuery?: string;
}

export interface AgreementBlocksFilter {
    blockName?: string;
    block_description?: PackingHouse;
    status?: string;
    searchQuery?: string;
}
export interface BlockFilter {
    crop?: Crop;
    district?: string;
    missingData?: missingDataFilters;
    grower?: Grower;
    variety?: Variety;
    subVariety?: SubVariety;
    rootstock?: Rootstock;
    searchQuery?: string;
}

export interface BlockFilterOptions {
    crop?: FilterOption<Crop>[];
    district?: FilterOption<string>[];
    missingData?: FilterOption<missingDataFilters>[];
    grower?: FilterOption<Grower>[];
    searchQuery?: string;
    variety?: FilterOption<Variety>[];
    subVariety?: FilterOption<SubVariety>[];
    rootstock?: FilterOption<Rootstock>[];
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}
export interface AgreementFilterOptions {
    agreementNumber?: FilterOption<string>[];
    packingHouseName?: FilterOption<PackingHouse>[];
    name?: FilterOption<any>[];
    ein?: FilterOption<any>[];
    status?: FilterOption<string>[];
    lastUpdate?: FilterOption<string>[];
    updatedBy?: FilterOption<string>[];
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}

export interface UserFilter {
    name?: string;
    title?: string;
    email?: string;
    role?: string;
    searchQuery?: string;
}

export interface UserFilterOptions {
    name?: FilterOption<string>[];
    title?: FilterOption<string>[];
    email?: FilterOption<string>[];
    role?: FilterOption<string>[];
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}


export interface PackingHouseFilter {
    id?: string;
    name?: string;
    packing_house_code?: string;
    packing_house_name?: string;
    exchange?: string;
    association?: string;
    searchQuery?: string;
}

export interface PackingHouseFilterOptions {
    id?: FilterOption<string>[];
    name?: FilterOption<string>[];
    packing_house_code?: FilterOption<string>[];
    packing_house_name?: FilterOption<string>[];
    exchange?: FilterOption<string>[];
    association?: FilterOption<string>[];
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}

export interface RanchFilter {
    ranchName?: string;
    county?: string;
    wellWater?: string;
    districtWater?: string;
    districtName?: string;
    windMachine?: string;
    windMachineCount?: string;
    searchQuery?: string;
}

export interface RanchFilterOptions {
    ranchName?: FilterOption<string>[];
    county?: FilterOption<string>[];
    wellWater?: FilterOption<string>[];
    districtWater?: FilterOption<string>[];
    districtName?: FilterOption<string>[];
    windMachine?: FilterOption<string>[];
    windMachineCount?: FilterOption<string>[];
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}


export interface GrowerFilter {
    name?: string;
    ein?: string;
    primaryNumber?: string;
    city?: string;
    state?: string;
    searchQuery?: string;
}

export interface GrowerFilterOptions {
    name?: FilterOption<string>[];
    ein?: FilterOption<string>[];
    primaryNumber?: FilterOption<string>[];
    city?: FilterOption<string>[];
    state?: FilterOption<string>[];
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}

export interface GrowerAccountFilter {
    name?: string;
    primaryNumber?: string;
    email?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    searchQuery?: string;
}

export interface GrowerAccountFilterOptions {
    name?: FilterOption<string>[];
    email?: FilterOption<string>[];
    address?: FilterOption<string>[];
    address2?: FilterOption<string>[];
    zipCode?: FilterOption<string>[];
    primaryNumber?: FilterOption<string>[];
    city?: FilterOption<string>[];
    state?: FilterOption<string>[];
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}


export interface FieldEstimateFilter {
    searchQuery?: string;
}
export interface FieldEstimateFilterOptions {
    searchQuery?: string;
    // Ugly, but I had to do this to get tests to pass
    x?: any;
}

export const missingDataFilterLabels = {
    [missingDataFilters.GrowerInfo]: 'Missing Grower',
    [missingDataFilters.Ranch]: 'Missing Ranch',
    [missingDataFilters.District]: 'Missing District',
    [missingDataFilters.BlockInfo]: 'Missing Block ID or Description',
    [missingDataFilters.Variety]: 'Missing Variety',
    [missingDataFilters.SubVariety]: 'Missing Sub-Variety',
    [missingDataFilters.Rootstock]: 'Missing Root Stock',
    [missingDataFilters.Acres]: 'Missing Planted Acres',
    [missingDataFilters.Age]: 'Missing Plant Year or Age',
    [missingDataFilters.TreesPerAcre]: 'Missing Trees/Acre',
};

import React from 'react';
import * as OutlineIcons from '@heroicons/react/outline';
import * as SolidIcons from '@heroicons/react/solid';

import './dynamic-icon.module.scss';

/* eslint-disable-next-line */
export interface DynamicIconProps {
    className?: string;
    icon: string;
}

export function DynamicIcon(props: DynamicIconProps) {
    const { ...icons } = SolidIcons;
    // @ts-ignore
    const TheIcon: JSX.Element = icons[props.icon];

    return (
        <>
            {/* @ts-ignore */}
            <TheIcon className={props.className} aria-hidden="true" />
        </>
    );
}

export function DynamicOutlineIcon(props: DynamicIconProps) {
    const { ...icons } = OutlineIcons;
    // @ts-ignore
    const TheIcon: JSX.Element = icons[props.icon];

    return (
        <>
            {/* @ts-ignore */}
            <TheIcon className={props.className} aria-hidden="true" />
        </>
    );
}

export default DynamicIcon;

import { AgreementFilter } from './filter';
import { ListRequest } from './listRequest';
import { Record } from './record';
import { PackingHouse } from './packingHouse';
import { AgreementBlocks } from './agreementBlocks';
import { User } from './user';
import { Grower } from './grower';

export interface Agreement {
    id?: number;

    status?: string;

    association?: string;

    details?: string;

    updated_by?: string;

    last_updated?: Date;

    date_created?: Date;

    blocks?: Record[];

    grower?: Grower;

    packingHouseUser?: User;

    packingHouse?: PackingHouse;

    agreementBlocks?: AgreementBlocks[];
}

export function getFlatAgreementValue(
    agreement?: Agreement | null,
    property?: keyof Agreement,
): string | number | Record[] | AgreementBlocks[] | undefined {
    if (agreement && property) {
        const value = agreement[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        } else if (Array.isArray(value)) {
            return value;
        } else if (value) {
            return value.id;
        }
    }

    return '';
}

export interface AgreementListRequest extends ListRequest {
    filter?: AgreementFilter;
    sort?: string;
    sortDirection?: string;
    packingHouseId?: number;
}

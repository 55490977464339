import React, { Component, ReactElement } from 'react';
import {
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from 'react-router-dom';

import { Api } from '@citrus-tracker/api';
import {
    FieldEstimate,
    Record,
    RecordFilter,
    RecordFilterOptions,
    RecordOptions,
    User,
} from '@citrus-tracker/types';
import Edit from './edit/edit';
import Table from './table/table';

import './field-estimate.module.scss';

/* eslint-disable-next-line */
export interface RecordProps extends RouteComponentProps<{}> {
    activeRecord?: Record;
    user?: User | null;
}

export class EstimateComponent extends Component<RecordProps> {
    props: Readonly<RecordProps>;

    state: {
        currentRecordIndex: number;
        errorAlert: string;
        filter: RecordFilter;
        filterOptions: RecordFilterOptions;
        records: Record[];
        recordOptions: RecordOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalRecords: number;
        recordsLoaded: boolean;
    } = {
        currentRecordIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        records: [],
        recordOptions: {
            crops: [],
            districts: [],
            organic: [],
            plantAges: [],
            rootstocks: [],
            getSelectOptions: () => [],
        },
        sort: 'record.id',
        sortDirection: 'ASC',
        successAlert: '',
        totalRecords: 0,
        recordsLoaded: false,
    };

    componentDidMount(): void {
        const savedFilters = window.localStorage.getItem('fieldEstimateFilters');
        if(savedFilters && savedFilters !== ''){
            this.updateFilter(JSON.parse(savedFilters));
        }else{
            this.updateFilter({});
        }

        this.loadRecords();
    }

    componentDidUpdate(oldProps: RecordProps): void {
        const { user } = this.props;

        if (user !== oldProps.user) {
            if (user) {
                this.loadRecords();
            } else {
                this.setState({ records: [],
                    recordsLoaded: false });
            }
        }
    }

    getRecordIdFromUrl(): string {
        const urlPath = this.props.location.pathname.split('/');
        let id = '';
        if (urlPath.length > 3 && !isNaN(parseInt(urlPath[3]))) {
            id = urlPath[3];
        }

        return id;
    }

    loadRecords = async (page: number = 1, recordsPerPage: number = 15): Promise<void> => {
        if (this.props.location.pathname.includes('recordId')) {
            this.loadRecord();
        } else {
            this.loadRecordsValues(page, recordsPerPage);
        }
    };

    loadRecordsValues = async (
        page = 1,
        recordsPerPage = 15,
    ): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const records = await Api.records.listForFieldEstimates(
            page,
            recordsPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalRecords: records.total,
            records: records.results,
            recordsLoaded: true,
        });
    };

    loadRecord = async (): Promise<void> => {
        const record = await Api.records.get(
            parseInt(this.getRecordIdFromUrl()),
        );

        if(!record.fieldEstimate) record.fieldEstimate = {} as FieldEstimate;

        this.setState({
            records: [record],
            recordsLoaded: true,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadRecords();
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadRecords();
            });
        }
    };
    setCurrentRecordIndex = (currentRecordIndex: number): void => {
        this.setState({ currentRecordIndex });
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadRecords();
            });
        }
    };

    setSuccess = (successAlert: string): void => {
        this.setState({ successAlert });

        setTimeout(() => {
            this.setState({ successAlert: '' });
        }, 2000);
    };

    updateFilter = (filter: RecordFilter): void => {
        window.localStorage.setItem('fieldEstimateFilters', JSON.stringify(filter));
        this.setState({ filter }, () => {
            this.updateFilterOptions();
        });
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter } = this.state;
        const filterOptions = await Api.records.getFilterOptions(filter);
        this.setState({ filterOptions }, () => {
            this.loadRecords();
        });
    };

    render(): ReactElement {
        const { user } = this.props;
        const { url } = this.props.match;
        const {
            currentRecordIndex,
            errorAlert,
            filter,
            filterOptions,
            records,
            recordOptions,
            sort,
            sortDirection,
            successAlert,
            totalRecords,
            recordsLoaded
        } = this.state;

        if (records.length === 0 && !recordsLoaded ) {
            return <div></div>;
        }

        return (
            <div>
                {errorAlert && (
                    <div className="alert alert-error text-center">
                        <strong>{errorAlert}</strong>
                    </div>
                )}
                {successAlert && (
                    <div className="alert alert-success text-center">
                        <strong>
                            {successAlert} <i className="si si-check"></i>
                        </strong>
                    </div>
                )}
                <main>
                    <div className="mx-auto sm:px-6 lg:px-4">
                        <Switch>
                            <Route
                                path={`${url}/recordId/:recordId/new`}
                                exact
                                render={(): ReactElement => (
                                    <Edit
                                        setError={this.setError}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadRecords={this.loadRecords}
                                        records={records}
                                        recordOptions={recordOptions}
                                        setCurrentRecordIndex={
                                            this.setCurrentRecordIndex
                                        }
                                        setSuccess={this.setSuccess}
                                        totalRecords={totalRecords}
                                        updateFilter={this.updateFilter}
                                        user={user}
                                    />
                                )}
                            />
                            <Route
                                path={`${url}/recordId/:recordId/edit`}
                                exact
                                render={(): ReactElement => (
                                    <Edit
                                        setError={this.setError}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadRecords={this.loadRecords}
                                        records={records}
                                        recordOptions={recordOptions}
                                        setCurrentRecordIndex={
                                            this.setCurrentRecordIndex
                                        }
                                        setSuccess={this.setSuccess}
                                        totalRecords={totalRecords}
                                        updateFilter={this.updateFilter}
                                        user={user}
                                    />
                                )}
                            />
                            <Route
                                path={`${url}/:indexInFilteredList/edit`}
                                exact
                                render={(): ReactElement => (
                                    <Edit
                                        setError={this.setError}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadRecords={this.loadRecords}
                                        records={records}
                                        recordOptions={recordOptions}
                                        setCurrentRecordIndex={
                                            this.setCurrentRecordIndex
                                        }
                                        setSuccess={this.setSuccess}
                                        totalRecords={totalRecords}
                                        updateFilter={this.updateFilter}
                                        user={user}
                                    />
                                )}
                            />
                            <Route
                                path={'/estimates'}
                                exact
                                render={(): ReactElement => (
                                    <Table
                                        currentRecordIndex={currentRecordIndex}
                                        filter={filter}
                                        filterOptions={filterOptions}
                                        loadRecords={this.loadRecords}
                                        records={records}
                                        recordOptions={recordOptions}
                                        reverseSort={this.reverseSort}
                                        setSort={this.setSort}
                                        sort={sort}
                                        sortDirection={sortDirection}
                                        totalRecords={totalRecords}
                                        updateFilter={this.updateFilter}
                                        // @ts-ignore
                                        user={user}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(EstimateComponent);

import React, { Component, Fragment, ReactElement } from 'react';

import './bulk-edit.module.scss';

import { Api } from '@citrus-tracker/api';
import { getFlatBlockValue, Block, BlockOptions } from '@citrus-tracker/types';
import BlockForm from './form';

/* eslint-disable-next-line */
export interface BulkEditProps {
    onFinished(): void;
    blocks: Block[];
    blockOptions: BlockOptions;
}

export class BulkEditBlock extends Component<BulkEditProps> {
    props: Readonly<BulkEditProps>;

    state: {
        error: string;
        updates: Block;
    } = {
        error: '',
        updates: {} as Block,
    };

    componentDidMount(): void {
        this.setState({
            updates: {},
        });
    }

    saveChanges = async (): Promise<Block[] | null> => {
        const { onFinished, blocks } = this.props;
        const { updates } = this.state;

        this.setState({ error: '' });

        const promises: Promise<Block>[] = blocks.map((block) =>
            Api.blocks.save({
                ...updates,
                id: block.id,
            }),
        );

        try {
            const block = await Promise.all(promises);
            onFinished();
            return block;
        } catch (error) {
            this.setState({ error });
            return null;
        }
    };

    setUpdates = (updates: Block): void => {
        this.setState({
            updates,
        });
    };

    render(): ReactElement {
        const { onFinished, blocks, blockOptions } = this.props;
        const { error, updates } = this.state;

        const combinedBlock: Block = {} as Block;

        // All free-form fields are disabled in bulk edit
        const disabledFields: (keyof Block)[] = [
            'total_acres',
            'block',
            'block_description',
            'grower',
            'plant_year',
            'trees',
            'trees_per_acre',
        ];

        const populatedProperties: (keyof Block)[] = [];
        for (const block of blocks) {
            let property: keyof Block;
            for (property in block) {
                if (
                    block[property] &&
                    populatedProperties.indexOf(property) < 0
                ) {
                    populatedProperties.push(property);
                }
            }
        }

        for (const property of populatedProperties) {
            if (Object.keys(updates).indexOf(property) >= 0) {
                // If we've already updated, or a field has been reset by changing a parent field, we don't want to disable it
                continue;
            }

            const values: unknown[] = [];
            const flatValues: (string | number)[] = [];
            let allEqual = true;
            for (const block of blocks) {
                if (block[property]) {
                    const flatValue = getFlatBlockValue(block, property);
                    if (
                        flatValues.length > 0 &&
                        flatValues.indexOf(flatValue) < 0
                    ) {
                        // If two blocks have different values for a field, it cannot be changed
                        disabledFields.push(property);
                        allEqual = false;
                        break;
                    }
                    values.push(block[property]);
                    flatValues.push(flatValue);
                }
            }
            if (allEqual && values.length > 0) {
                Object.assign(combinedBlock, { [property]: values[0] });
            }
        }

        return (
            <Fragment>
                {error && (
                    <div className="alert alert-error text-center">
                        <strong>{error}</strong>
                    </div>
                )}
                <BlockForm
                    disabledFields={disabledFields}
                    onCancel={onFinished}
                    block={combinedBlock}
                    blockOptions={blockOptions}
                    saveChanges={this.saveChanges}
                    setUpdates={this.setUpdates}
                />
            </Fragment>
        );
    }
}

export default BulkEditBlock;

import React, { Fragment } from 'react';
import { Button } from '../button/button';

import './table.module.scss';

/* eslint-disable-next-line */
export interface TableProps {
    columns: any;
    rows: any;
    renderForDisplay?: any;
    sort?: any;
    sortDirection?: string;
    setSort?: any;
    reverseSort?: any;
    openEdit?: any;
    indexBase?: number;
    selectedForBulkEdit?: any;
    toggleSelectedForBulkEdit?: any;
    toggleAllSelectedForBulkEdit?: any;
    editingRow?: any;
    inlineEdit?: any;
    renderForEdit?: any;
    currentUser?: any;
}

export function Table(props: TableProps) {
    const {
        columns,
        rows,
        renderForDisplay,
        sort,
        sortDirection,
        setSort,
        reverseSort,
        openEdit,
        indexBase,
        selectedForBulkEdit,
        toggleSelectedForBulkEdit,
        toggleAllSelectedForBulkEdit,
        editingRow,
        inlineEdit,
        renderForEdit,
    } = props;

    const shouldHighlightRow = (row: any): boolean => {
        if(window.location.pathname.includes('blocks') && !row.ranch) {
            return true;
        }
        if(window.location.pathname.includes('ranches') && !row.grower) {
            return true;
        }
        if(window.location.pathname.includes('growers') && !row.user) {
            return true;
        }

        return false;
    };

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-scroll">
                <div className="py-2 align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg h-full" style={{minHeight: 800}}>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {toggleSelectedForBulkEdit && (
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    selectedForBulkEdit.length ===
                                                    rows.length
                                                }
                                                onChange={(e): unknown =>
                                                    toggleAllSelectedForBulkEdit(
                                                        e.target.checked,
                                                    )
                                                }
                                                title="Select all"
                                            />
                                        </td>
                                    )}
                                    {inlineEdit && (
                                        <td className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider position-relative">
                                            Edit
                                        </td>
                                    )}
                                    {columns.map((column: any, key: number) => (
                                        <td
                                            key={key}
                                            scope="col"
                                            className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider position-relative whitespace-nowrap max-w-lg"
                                        >
                                            <span
                                                onClick={(e): void => {
                                                    e.stopPropagation();
                                                    setSort(column.sort);
                                                }}
                                                className={
                                                    'inline-block ' +
                                                        column.sort &&
                                                    'sortable'
                                                }
                                            >
                                                {column.title}
                                            </span>
                                            {column.sort &&
                                                sort === column.sort && (
                                                    <i
                                                        onClick={(e): void => {
                                                            e.stopPropagation();
                                                            reverseSort();
                                                        }}
                                                        className={
                                                            sortDirection ===
                                                            'DESC'
                                                                ? 'sort fa fa-sort-amount-desc'
                                                                : 'sort fa fa-sort-amount-asc'
                                                        }
                                                    ></i>
                                                )}
                                            {column.filter}
                                        </td>
                                    ))}
                                    {openEdit && <td></td>}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row: any, rowIdx: number) => {
                                    const isEditing =
                                        editingRow && editingRow.id === row.id;
                                    return (
                                        <tr
                                            key={rowIdx}
                                            className={
                                                shouldHighlightRow(row) ? 'bg-red-300' :
                                                rowIdx % 2 === 0
                                                    ? isEditing
                                                        ? 'editing bg-white'
                                                        : 'bg-white'
                                                    : isEditing
                                                    ? 'editing bg-gray-50'
                                                    : 'bg-gray-50'
                                            }
                                        >
                                            {!isEditing && (
                                                <Fragment>
                                                    {toggleSelectedForBulkEdit && (
                                                        <td
                                                            onClick={(
                                                                e,
                                                            ): void => {
                                                                e.stopPropagation();
                                                            }}
                                                            style={{
                                                                cursor:
                                                                    'inherit',
                                                            }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    !!row.id &&
                                                                    selectedForBulkEdit.indexOf(
                                                                        row.id,
                                                                    ) >= 0
                                                                }
                                                                onChange={(): unknown =>
                                                                    toggleSelectedForBulkEdit(
                                                                        row.id,
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    )}
                                                    {inlineEdit && (
                                                        <td className="text-center">
                                                            <div className="btn-group">
                                                                <Button
                                                                    onClick={(
                                                                        e: any,
                                                                    ): void => {
                                                                        e.stopPropagation();
                                                                        inlineEdit(
                                                                            row.id,
                                                                        );
                                                                    }}
                                                                    data-toggle="tooltip"
                                                                    title="Edit inline"
                                                                    icon="PencilIcon"
                                                                    variant="secondary"
                                                                ></Button>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {columns.map(
                                                        (
                                                            column: any,
                                                            colIdx: number,
                                                        ) => (
                                                            <td
                                                                key={colIdx}
                                                                className={
                                                                    column.tdClass
                                                                        ? column.tdClass
                                                                        : 'px-6 py-4 whitespace-nowrap text-gray-500'
                                                                }
                                                                onClick={
                                                                    !isEditing && openEdit
                                                                        ? (): unknown =>
                                                                              openEdit(
                                                                                  window.location.pathname.includes(
                                                                                      'estimates',
                                                                                  )
                                                                                    ? (indexBase
                                                                                            ? indexBase
                                                                                            : 0) +
                                                                                            rowIdx +
                                                                                            1
                                                                                      : row[
                                                                                            'id'
                                                                                        ],
                                                                              )
                                                                        : undefined
                                                                }
                                                            >
                                                                {renderForDisplay(
                                                                    row,
                                                                    column.property,
                                                                )}
                                                            </td>
                                                        ),
                                                    )}
                                                    {openEdit && (
                                                        <td className="pr-8">
                                                            <a
                                                                className="text-indigo-600 cursor-pointer"
                                                                onClick={
                                                                    !isEditing
                                                                        ? (): unknown =>
                                                                              openEdit(
                                                                                  window.location.pathname.includes(
                                                                                      'records',
                                                                                  )
                                                                                      ? (indexBase
                                                                                            ? indexBase
                                                                                            : 0) +
                                                                                            rowIdx +
                                                                                            1
                                                                                      : row[
                                                                                            'id'
                                                                                        ],
                                                                              )
                                                                        : undefined
                                                                }
                                                            >
                                                                Edit
                                                            </a>
                                                        </td>
                                                    )}
                                                </Fragment>
                                            )}
                                            {isEditing && editingRow && (
                                                <Fragment>
                                                    {toggleSelectedForBulkEdit && (
                                                        <td></td>
                                                    )}
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <Button
                                                                onClick={(): unknown =>
                                                                    inlineEdit(
                                                                        null,
                                                                    )
                                                                }
                                                                data-toggle="tooltip"
                                                                title="Close Edit"
                                                                icon="SaveIcon"
                                                                variant="secondary"
                                                            ></Button>
                                                        </div>
                                                    </td>
                                                    {columns.map(
                                                        (
                                                            column: any,
                                                            colIdx: number,
                                                        ) => (
                                                            <td
                                                                key={colIdx}
                                                                className={
                                                                    column.tdClass
                                                                        ? column.tdClass
                                                                        : 'px-6 py-4 whitespace-nowrap text-gray-500'
                                                                }
                                                            >
                                                                {renderForEdit(
                                                                    editingRow,
                                                                    column.property,
                                                                )}
                                                            </td>
                                                        ),
                                                    )}
                                                </Fragment>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Table;

import React, { Component, Fragment, ReactElement } from 'react';
import { DebounceInput } from 'react-debounce-input';

import './input.module.scss';

/* eslint-disable-next-line */
export interface InputProps {
    id: string;
    label?: string;
    onChange(newValue: string): unknown;
    value: string | number;

    [key: string]: unknown;
}

export class Input extends Component<InputProps> {
    render(): ReactElement {
        const { id, label, onChange, value, ...additionalProps } = this.props;

        return (
            <Fragment>
                {label && (
                    <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor={id}
                    >
                        {label}:{' '}
                    </label>
                )}
                <DebounceInput
                    id={id}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    debounceTimeout={300}
                    type="text"
                    onChange={(e): unknown => onChange(e.target.value)}
                    value={value}
                    {...additionalProps}
                />
            </Fragment>
        );
    }
}

export default Input;

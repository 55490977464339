import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Api } from '@citrus-tracker/api';
import { GrowerAccount, User, GrowerAccountOptions, GrowerAccountFilter, GrowerAccountFilterOptions } from '@citrus-tracker/types';

import Table from './table';

import './growerAccounts.module.scss';
const growerAccountsPerPage = 15;

interface PathParams {
    id: string;
}

/* eslint-disable-next-line */
export interface GrowerAccountProps extends RouteComponentProps<PathParams> {
    user?: User | null;
    userId?: string | null;
}

export class GrowerAccountComponent extends Component<GrowerAccountProps> {
    props: Readonly<GrowerAccountProps>;

    state: {
        growerAccountId: string;
        currentGrowerAccountIndex: number;
        errorAlert: string;
        filter: GrowerAccountFilter;
        filterOptions: GrowerAccountFilterOptions;
        growerAccounts: GrowerAccount[];
        growerAccountOptions: GrowerAccountOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalGrowerAccounts: number;
        page: number;
    } = {
        growerAccountId: '',
        currentGrowerAccountIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        growerAccounts: [],
        growerAccountOptions: {},
        sort: 'growerAccount.id',
        sortDirection: 'ASC',
        successAlert: '',
        totalGrowerAccounts: 0,
        page: 1,
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;

        if (id) {
            this.setState({ growerAccountId: id });
            this.loadGrowerAccounts(id.toString());
        } else {
            this.loadGrowerAccounts();
        }

        
        this.updateFilter({});
    }

    loadGrowerAccounts = async (
        growerAccountId = '',
        page = 1,
        growerAccountsPerPage = 15,
    ): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const growerAccounts = await Api.growerAccounts.list(
            growerAccountId,
            page,
            growerAccountsPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalGrowerAccounts: growerAccounts.total,
            growerAccounts: growerAccounts.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadGrowerAccounts(this.state.growerAccountId);
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadGrowerAccounts(this.state.growerAccountId);
            });
        }
    };
    setCurrentGrowerAccountIndex = (currentGrowerAccountIndex: number): void => {
        this.setState({ currentGrowerAccountIndex });
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadGrowerAccounts(this.state.growerAccountId);
            });
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.loadGrowerAccounts(
            this.state.growerAccountId,
            data.selected + 1,
            growerAccountsPerPage,
        );
        this.setState({ page: data.selected + 1 });
    };

    updateFilter = (filter: GrowerAccountFilter): void => {
        if(filter){
            window.localStorage.setItem('growerAccountFilters', JSON.stringify(filter));

            this.setState({ filter }, () => {
                this.updateFilterOptions();
            });   
        }
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter, growerAccountId } = this.state;
        const filterOptions = await Api.growerAccounts.getFilterOptions(filter, growerAccountId);
        this.setState({ filterOptions }, () => {
            this.loadGrowerAccounts(this.state.growerAccountId);
        });
    };

    render(): ReactElement {
        const { growerAccounts, totalGrowerAccounts, page, growerAccountId, currentGrowerAccountIndex, 
            filter,
            filterOptions,
            growerAccountOptions,
            sort,
            sortDirection,
        } = this.state;
        const { user } = this.props;
        return (
            <div className="px-8">
                <div className="flex justify-between items-center flex-col mb-2 sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Grower Accounts
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            <Link
                                to={`/growerAccounts/new`}
                                className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right ml-4"
                            >
                                <div className="mr-3">+</div>
                                <div>New</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <Table
                        currentGrowerAccountIndex={currentGrowerAccountIndex}
                        filter={filter}
                        filterOptions={filterOptions}
                        loadGrowerAccounts={this.loadGrowerAccounts}
                        growerAccounts={growerAccounts}
                        growerAccountOptions={growerAccountOptions}
                        reverseSort={this.reverseSort}
                        setSort={this.setSort}
                        sort={sort}
                        sortDirection={sortDirection}
                        totalGrowerAccounts={totalGrowerAccounts}
                        updateFilter={this.updateFilter}
                        user={user ?? undefined}
                        growerAccountId={growerAccountId}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(GrowerAccountComponent);

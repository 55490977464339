import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Api } from '@citrus-tracker/api';
import { Grower, User, GrowerOptions, GrowerFilter, GrowerFilterOptions } from '@citrus-tracker/types';

import Table from './table';

import './growers.module.scss';
const growersPerPage = 15;

interface PathParams {
    id: string;
}

/* eslint-disable-next-line */
export interface GrowerProps extends RouteComponentProps<PathParams> {
    user?: User | null;
    userId?: string | null;
}

export class GrowerComponent extends Component<GrowerProps> {
    props: Readonly<GrowerProps>;

    state: {
        growerId: string;
        currentGrowerIndex: number;
        errorAlert: string;
        filter: GrowerFilter;
        filterOptions: GrowerFilterOptions;
        growers: Grower[];
        growerOptions: GrowerOptions;
        sort: string;
        sortDirection: string;
        successAlert: string;
        totalGrowers: number;
        page: number;
    } = {
        growerId: '',
        currentGrowerIndex: 1,
        errorAlert: '',
        filter: {},
        filterOptions: {},
        growers: [],
        growerOptions: {},
        sort: 'grower.id',
        sortDirection: 'DESC',
        successAlert: '',
        totalGrowers: 0,
        page: 1,
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;

        if (id) {
            this.setState({ growerId: id });
            this.loadGrowers(id.toString());
        } else {
            this.loadGrowers();
        }

        
        this.updateFilter({});
    }

    loadGrowers = async (
        growerId = '',
        page = 1,
        growersPerPage = 15,
    ): Promise<void> => {
        const { filter, sort, sortDirection } = this.state;

        const growers = await Api.growers.list(
            growerId,
            page,
            growersPerPage,
            filter,
            sort,
            sortDirection,
        );

        this.setState({
            totalGrowers: growers.total,
            growers: growers.results,
        });
    };

    reverseSort = async (): Promise<void> => {
        if (this.state.sortDirection === 'ASC') {
            this.setState({ sortDirection: 'DESC' }, () => {
                this.loadGrowers(this.state.growerId);
            });
        } else {
            this.setState({ sort: null }, () => {
                this.loadGrowers(this.state.growerId);
            });
        }
    };
    setCurrentGrowerIndex = (currentGrowerIndex: number): void => {
        this.setState({ currentGrowerIndex });
    };

    setSort = async (sort: string): Promise<void> => {
        if (this.state.sort === sort) {
            this.reverseSort();
        } else {
            this.setState({ sort, sortDirection: 'ASC' }, () => {
                this.loadGrowers(this.state.growerId);
            });
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.loadGrowers(
            this.state.growerId,
            data.selected + 1,
            growersPerPage,
        );
        this.setState({ page: data.selected + 1 });
    };

    updateFilter = (filter: GrowerFilter): void => {
        if(filter){
            window.localStorage.setItem('growerFilters', JSON.stringify(filter));

            this.setState({ filter }, () => {
                this.updateFilterOptions();
            });   
        }
    };

    updateFilterOptions = async (): Promise<void> => {
        const { filter, growerId } = this.state;
        const filterOptions = await Api.growers.getFilterOptions(filter, growerId);
        this.setState({ filterOptions }, () => {
            this.loadGrowers(this.state.growerId);
        });
    };

    render(): ReactElement {
        const { growers, totalGrowers, page, growerId, currentGrowerIndex, 
            filter,
            filterOptions,
            growerOptions,
            sort,
            sortDirection,
        } = this.state;
        const { user } = this.props;
        return (
            <div className="px-8">
                <div className="flex justify-between items-center flex-col mb-2 sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Grower Entities
                            </h1>
                        </div>
                    </header>
                    <div className="w-full sm:w-auto">
                        <div className="flex justify-between items-center">
                            <div>
                                {growerId &&
                                    growers.length > 0 &&
                                    growers[0].ranches &&
                                    growers[0].ranches.length > 0 &&
                                    growers[0].ranches[0].blocks &&
                                    growers[0].ranches[0].blocks.length > 0 && (
                                        <Link
                                            to={`/agreements/add/${growerId}`}
                                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                        >
                                            New Agreement
                                        </Link>
                                    )}
                                {growerId &&
                                    growers.length > 0 &&
                                    growers[0].ranches &&
                                    growers[0].ranches.length > 0 && (
                                        <Link
                                            to={`/blocks/${growerId}`}
                                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                        >
                                            Blocks
                                        </Link>
                                    )}
                                {growerId && growers.length > 0 && (
                                    <Link
                                        to={`/ranches/${growerId}`}
                                        className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right ml-4"
                                    >
                                        Ranches
                                    </Link>
                                )}
                            </div>
                            <Link
                                to={`/growers/${
                                    growerId ? growerId : user?.id
                                }/edit/new`}
                                className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800 border-transparent text-white bg-green-800 hover:bg-green-900 px-4 float-right ml-4"
                            >
                                <div className="mr-3">+</div>
                                <div>New</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="block-content">
                    <Table
                        currentGrowerIndex={currentGrowerIndex}
                        filter={filter}
                        filterOptions={filterOptions}
                        loadGrowers={this.loadGrowers}
                        growers={growers}
                        growerOptions={growerOptions}
                        reverseSort={this.reverseSort}
                        setSort={this.setSort}
                        sort={sort}
                        sortDirection={sortDirection}
                        totalGrowers={totalGrowers}
                        updateFilter={this.updateFilter}
                        user={user ?? undefined}
                        growerId={growerId}
                    />
                </div>
                {growerId && (
                    <div className="my-2 float-left">
                        <Link
                            to={`/users/${growerId}`}
                            className="cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right"
                        >
                            Back to User
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(GrowerComponent);

import { PackingHouse } from './packingHouse';
import { ListRequest } from './listRequest';
import { Grower } from './grower';
import { UserFilter } from './filter';

export class User {
    id?: number;

    email = '';

    username = '';

    name = '';

    firstName = '';

    lastName = '';

    title = '';

    agreementNum? = '';

    password?: string;

    enabled = false;

    packingHouse?: PackingHouse;

    growers?: Grower[];

    image?: ImageBuffer;

    roles: {
        id?: number;
        role: string;
    }[];

    role: '';

    isAdmin(): boolean {
        if (this.roles) {
            for (const { role } of this.roles) {
                if (role === 'ROLE_ADMIN') {
                    return true;
                }
            }
        }

        return false;
    }

    isGrower(): boolean {
        if (this.roles) {
            for (const { role } of this.roles) {
                if (role === 'ROLE_GROWER') {
                    return true;
                }
            }
        }

        return false;
    }

    isPackingHouseUser(): boolean {
        if (this.roles) {
            for (const { role } of this.roles) {
                if (role === 'ROLE_PACKINGHOUSE') {
                    return true;
                }
            }
        }

        return false;
    }

    isUser(): boolean {
        if (this.roles) {
            for (const { role } of this.roles) {
                if (role === 'ROLE_USER') {
                    return true;
                }
            }
        }

        return false;
    }
}

export interface UserListRequest extends ListRequest {
    filter?: UserFilter;
    sort?: string;
    sortDirection?: string;
    packingHouseId?: number;
}

export interface ImageBuffer {
    type: string;
    data: ArrayBuffer;
}

export function getFlatUserValue(
    user?: User | null,
    property?: keyof User,
): string | number {
    if (user && property) {
        const value = user[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        }
    }

    return '';
}

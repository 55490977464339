import React, {
    Component,
    ReactElement,
    RefObject,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { Api } from '@citrus-tracker/api';
import {
    GrowerAccount,
    GrowerAccountFilter,
    GrowerAccountFilterOptions,
    GrowerAccountOptions,
    User,
} from '@citrus-tracker/types';
import Filter, { FilterColumn } from './filter';
import { Table as UITable } from '@citrus-tracker/ui';

export interface TableProps extends RouteComponentProps {
    currentGrowerAccountIndex: number;
    filter: GrowerAccountFilter;
    filterOptions: GrowerAccountFilterOptions;
    growerAccounts: GrowerAccount[];
    growerAccountOptions: GrowerAccountOptions;
    reverseSort(): Promise<void>;
    setSort(sort: string): Promise<void>;
    sort: string;
    sortDirection: string;
    totalGrowerAccounts: number;
    loadGrowerAccounts(growerAccountId: string, page: number, growerAccountsPerPage: number): Promise<void>;
    updateFilter(filter: GrowerAccountFilter): void;
    growerAccount?: GrowerAccount;
    growerAccountId?: string;
    user?: User;
}

export class Table extends Component<TableProps> {
    downloadDropdownRef: RefObject<HTMLDivElement>;
    downloadDropdownTriggerRef: RefObject<HTMLButtonElement>;

    props: Readonly<TableProps>;

    state: {
        page: number;
        growerAccountsPerPage: number;
        growerAccountVisibleColumns: {
            [key: string]: boolean;
        };
    } = {
        page: 1,
        growerAccountsPerPage: 15,
        growerAccountVisibleColumns: {
            name: true,
            primaryNumber: true,
            secondaryNumber: true,
            email: true,
            address: true,
            address2: true,
            city: true,
            state: true,
            zipCode: true,
            secondaryContactName: true,
            secondaryContactNumber: true,
            secondaryContactEmail: true,
            secondaryContactAddress: true,
            secondaryContactAddress2: true,
            secondaryContactCity: true,
            secondaryContactState: true,
            secondaryContactZipCode: true,
            growerMemberSince: true,
        },
    };

    constructor(props: TableProps) {
        super(props);

        this.downloadDropdownRef = React.createRef();
        this.downloadDropdownTriggerRef = React.createRef();
    }

    componentDidMount(): void {
        this.goToCurrentGrowerAccount();

        const columnString = localStorage.getItem('growerAccountVisibleColumns');
        if (columnString) {
            this.setState({ growerAccountVisibleColumns: JSON.parse(columnString) });
        }

        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );

        const page = localStorage.getItem('growerAccountsPage');
        if (page) {
            this.goToPage(parseInt(page));
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropDowns,
        );
    }

    handleClickOutsideDropDowns = (e: MouseEvent): void => {
        if (
            this.downloadDropdownRef &&
            !this.downloadDropdownRef.current?.contains(e.target as Node) &&
            this.downloadDropdownTriggerRef &&
            !this.downloadDropdownTriggerRef.current?.contains(e.target as Node)
        ) {
            this.setState({ downloadDropdown: false });
        }
    };

    componentDidUpdate(oldProps: TableProps): void {
        const { currentGrowerAccountIndex } = this.props;

        if (
            currentGrowerAccountIndex &&
            (!oldProps.growerAccounts ||
                currentGrowerAccountIndex !== oldProps.currentGrowerAccountIndex)
        ) {
            this.goToCurrentGrowerAccount();
        }
    }

    goToCurrentGrowerAccount = (): void => {
        const { currentGrowerAccountIndex, growerAccounts } = this.props;

        if (growerAccounts) {
            if (currentGrowerAccountIndex) {
                this.goToGrowerAccount(currentGrowerAccountIndex);
            } else {
                this.goToGrowerAccount(1);
            }
        }
    };

    goToPage = (newPage: number): void => {
        const { loadGrowerAccounts, growerAccountId } = this.props;
        const { growerAccountsPerPage } = this.state;
        this.setState({ page: newPage, selectedForBulkEdit: [] });
        loadGrowerAccounts(growerAccountId ?? '', newPage, growerAccountsPerPage);

        //Store page in session so we can persist across page reloads
        localStorage.setItem('growerAccountsPage', JSON.stringify(newPage));
    };

    goToGrowerAccount = (growerAccountIndex: number): void => {
        const { growerAccountsPerPage } = this.state;

        if (growerAccountsPerPage) {
            const page = localStorage.getItem('growerAccountsPage') ?? Math.ceil(growerAccountIndex / growerAccountsPerPage);
            this.goToPage(parseInt(page.toString()));
        }
    };

    openEdit = (growerAccountIndex?: number): void => {
        if (growerAccountIndex) {
            this.props.history.push( `/growerAccounts/${growerAccountIndex}`);
        }
    };

    paginationClicked = (data: { selected: number }): void => {
        this.goToPage(data.selected + 1);
    };

    toggleColumnVisibility = (property: keyof GrowerAccount): void => {
        const growerAccountVisibleColumns = {
            ...this.state.growerAccountVisibleColumns,
            [property]: !this.state.growerAccountVisibleColumns[property],
        };

        this.setState({
            growerAccountVisibleColumns,
        });

        // Store in session so we can persist across page reloads
        localStorage.setItem('growerAccountVisibleColumns', JSON.stringify(growerAccountVisibleColumns));
    };

    renderForDisplay = (
        growerAccount: GrowerAccount,
        property: keyof GrowerAccount,
    ): ReactElement => {
        switch (property) {
            default:
                return <span>{growerAccount[property]}</span>;
        }
    };

    render(): ReactElement {
        const {
            growerAccounts: GrowerAccounts,
            totalGrowerAccounts,
            filter,
            filterOptions,
            reverseSort,
            setSort,
            sort,
            sortDirection,
            updateFilter,
            growerAccount,
        } = this.props;
        const {
            page,
            growerAccountsPerPage,
            growerAccountVisibleColumns,
        } = this.state;

        const totalPages = Math.ceil(totalGrowerAccounts / growerAccountsPerPage);

        const indexBase = (page - 1) * growerAccountsPerPage;

        const allColumns: {
            property: keyof GrowerAccount;
            title: string;
            filter?: boolean | ReactElement;
            sort: string;
            tdClass?: string;
        }[] = [
            {
                property: 'name' as keyof GrowerAccount,
                title: 'Name',
                filter: true,
                sort: 'growerAccount.name',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'primaryNumber' as keyof GrowerAccount,
                title: 'Phone #',
                filter: true,
                sort: 'growerAccount.primaryNumber',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryNumber' as keyof GrowerAccount,
                title: '2nd Phone #',
                filter: true,
                sort: 'growerAccount.secondaryNumber',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'email' as keyof GrowerAccount,
                title: 'Email',
                filter: true,
                sort: 'growerAccount.email',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'address' as keyof GrowerAccount,
                title: 'Address',
                filter: true,
                sort: 'growerAccount.address',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'address2' as keyof GrowerAccount,
                title: 'Address Cont.',
                filter: true,
                sort: 'growerAccount.address2',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'city' as keyof GrowerAccount,
                title: 'City',
                filter: true,
                sort: 'growerAccount.city',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'state' as keyof GrowerAccount,
                title: 'State',
                filter: true,
                sort: 'growerAccount.state',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'zipCode' as keyof GrowerAccount,
                title: 'Zip Code',
                filter: true,
                sort: 'growerAccount.zipCode',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactName' as keyof GrowerAccount,
                title: '2nd Contact',
                filter: true,
                sort: 'growerAccount.secondaryContactName',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactNumber' as keyof GrowerAccount,
                title: '2nd Phone #',
                filter: true,
                sort: 'growerAccount.secondaryContactNumber',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactEmail' as keyof GrowerAccount,
                title: '2nd Email',
                filter: true,
                sort: 'growerAccount.secondaryContactEmail',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactAddress' as keyof GrowerAccount,
                title: '2nd Address',
                filter: true,
                sort: 'growerAccount.secondaryContactAddress',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactAddress2' as keyof GrowerAccount,
                title: '2nd Address Cont.',
                filter: true,
                sort: 'growerAccount.secondaryContactAddress2',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactCity' as keyof GrowerAccount,
                title: '2nd City',
                filter: true,
                sort: 'growerAccount.secondaryContactCity',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactState' as keyof GrowerAccount,
                title: '2nd State',
                filter: true,
                sort: 'growerAccount.secondaryContactState',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'secondaryContactZipCode' as keyof GrowerAccount,
                title: '2nd Zip Code',
                filter: true,
                sort: 'growerAccount.secondaryContactZipCode',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
            {
                property: 'growerMemberSince' as keyof GrowerAccount,
                title: 'Grower Since',
                filter: true,
                sort: 'growerAccount.growerMemberSince',
                tdClass:
                    'px-6 py-4 whitespace-nowrap font-medium text-gray-900 max-w-xs overflow-ellipsis overflow-hidden',
            },
        ];

        const columns = allColumns.filter(
            (column) => growerAccountVisibleColumns[column.property],
        );

        columns.map((column, key) => {
            if (column.filter) {
                columns[key].filter = (
                    <FilterColumn
                        column={column}
                        filter={filter}
                        filterOptions={filterOptions}
                        updateFilter={updateFilter}
                    />
                );
            }
        });

        const start = (page * growerAccountsPerPage) - (growerAccountsPerPage - 1);
        const end = Math.min(start + growerAccountsPerPage - 1, totalGrowerAccounts);

        return (
            <div className="block">
                <div className="block-content">
                    <Filter
                        filter={filter}
                        filterOptions={filterOptions}
                        nextPage={
                            page < totalPages
                                ? (): void => this.goToPage(page + 1)
                                : undefined
                        }
                        prevPage={
                            page > 1
                                ? (): void => this.goToPage(page - 1)
                                : undefined
                        }
                        updateFilter={updateFilter}
                        columns={allColumns}
                        toggleColumnVisibility={this.toggleColumnVisibility}
                        // @ts-ignore
                        isAdmin={growerAccount?.isAdmin()}
                        visibleColumns={growerAccountVisibleColumns}
                        Api={Api}
                    />
                    <UITable
                        columns={columns}
                        rows={GrowerAccounts}
                        renderForDisplay={this.renderForDisplay}
                        //sort
                        sort={sort}
                        setSort={setSort}
                        reverseSort={reverseSort}
                        sortDirection={sortDirection}
                        //edit link
                        openEdit={this.openEdit}
                        indexBase={indexBase}
                    />

                    <div className="row">
                        <div className="w-full flex space-x-4 m-4">
                            <div className="flex-1">
                            Showing {start} to {end} of {totalGrowerAccounts} results
                            </div>
                            <div className="flex-1">
                                <div className="float-right">
                                    <ReactPaginate
                                        previousLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        nextLabel={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                        breakLabel={'...'}
                                        breakClassName={'tw-break-me'}
                                        forcePage={page - 1}
                                        pageCount={totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.paginationClicked}
                                        containerClassName={'tw-pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'tw-page-item'}
                                        pageLinkClassName={'tw-page-link'}
                                        previousClassName={'tw-page-item'}
                                        nextClassName={'tw-page-item'}
                                        previousLinkClassName={
                                            'previous tw-page-link'
                                        }
                                        nextLinkClassName={'next tw-page-link'}
                                        disabledClassName={'disabled'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Table);

import { Crop } from './crop';
import { FieldEstimate } from './fieldEstimate';
import { RecordFilter } from './filter';
import { ListRequest } from './listRequest';
import { PackingHouse } from './packingHouse';
import { PlantAge } from './plantAge';
import { Rootstock } from './rootstock';
import { SubVariety } from './subVariety';
import { Variety } from './variety';

export interface Record {
    id?: number;

    grower_provided?: string;

    block_description_provided?: string;

    grower_id?: string;

    grower_name?: string;

    district?: string;

    block?: string;

    block_name?: string;

    organic?: string;

    acres?: number;

    trees?: number;

    trees_per_acre?: number;

    trees_per_bin?: number;

    plant_year?: string;

    google_maps_url?: string;

    status?: string;

    updated_by?: string;

    last_updated?: Date;

    date_created?: Date;

    packingHouse?: PackingHouse;

    crop?: Crop;

    variety?: Variety;

    subVariety?: SubVariety;

    plantAge?: PlantAge;

    rootstock?: Rootstock;

    fieldEstimate?: FieldEstimate;
}

export function getFlatRecordValue(
    record?: Record | null,
    property?: keyof Record,
): string | number {
    if (record && property) {
        const value = record[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        } else if (value instanceof Date) {
            return value.toDateString();
        } else if (value) {
            return value.id;
        }
    }

    return '';
}

export interface RecordListRequest extends ListRequest {
    filter?: RecordFilter;
    sort?: string;
    sortDirection?: string;
    packingHouseId?: number;
    fieldEstimates?: boolean;
}

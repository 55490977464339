import React, { Component, ReactElement } from 'react';

import './form.module.scss';

import { Form, Input, Select } from '@citrus-tracker/layout/form';
import {
    getFlatRecordValue,
    Record,
    RecordOptions,
} from '@citrus-tracker/types';
import { Button } from '@citrus-tracker/ui';

/* eslint-disable-next-line */
export interface FormProps {
    disabledFields?: (keyof Record)[];
    onCancel?(): unknown;
    openDelete?(): void;
    record: Record;
    recordOptions: RecordOptions;
    saveChanges(): Promise<unknown>;
    setUpdates?(updates: Record): void;
}

export class RecordForm extends Component<FormProps> {
    props: Readonly<FormProps>;

    state: {
        updates: Record;
    } = {
        updates: {},
    };

    componentDidUpdate(oldProps: FormProps): void {
        if (this.props.record.id !== oldProps.record.id) {
            this.setUpdates({});
        }
    }

    setUpdates = (updates: Record): void => {
        const { setUpdates } = this.props;

        this.setState({
            updates,
        });

        if (setUpdates) {
            setUpdates(updates);
        }
    };

    setValue = (property: keyof Record, value: unknown): void => {
        const { record } = this.props;

        const updates = {
            ...this.state.updates,
        };

        // These properties are related objects
        if (
            property === 'crop' ||
            property === 'plantAge' ||
            property === 'rootstock' ||
            property === 'subVariety' ||
            property === 'variety'
        ) {
            if (typeof value === 'string') {
                value = parseInt(value);
            }

            // For dependent fields, a change to a parent should unset children
            if (
                property === 'crop' &&
                value !== record.crop?.id &&
                value !== updates.crop?.id
            ) {
                updates.variety = undefined;
                updates.subVariety = undefined;
            }
            if (
                property === 'variety' &&
                value !== record.variety?.id &&
                value !== updates.variety?.id
            ) {
                updates.subVariety = undefined;
            }

            value = {
                id: value,
            };
        }

        const newUpdates = {
            ...updates,
            [property]: value,
        };

        this.setUpdates(newUpdates);
    };

    getValue = (property: keyof Record): string | number => {
        const { record } = this.props;
        const { updates } = this.state;

        return updates[property]
            ? getFlatRecordValue(updates, property)
            : getFlatRecordValue(record, property);
    };

    renderInput = (
        property: keyof Record,
        label: string,
        additionalProps: { [key: string]: unknown } = {},
    ): ReactElement => {
        const disabledFields = this.props.disabledFields || [];

        let value = this.getValue(property);

        if (typeof value === 'number') {
            value = value.toString();
        }

        if (typeof value !== 'string') {
            value = '';
        }

        if (property == 'trees') {
            value = new Intl.NumberFormat()
                .format(Math.round(parseInt(value)))
                .toString();
        }

        return (
            <div className="form-group w-1/3">
                <Input
                    id={property}
                    label={label}
                    onChange={(newValue: string): void =>
                        this.setValue(property, newValue)
                    }
                    value={value}
                    {...additionalProps}
                    disabled={
                        disabledFields.indexOf(property) >= 0 ||
                        additionalProps.disabled
                    }
                />
            </div>
        );
    };

    renderSelect = (
        property: keyof Record,
        label: string,
        options: {
            label: string;
            value: string;
        }[],
        additionalProps: { [key: string]: unknown } = {},
    ): ReactElement => {
        const disabledFields = this.props.disabledFields || [];

        const value = this.getValue(property);

        return (
            <div className="form-group w-1/3">
                <Select
                    id={property}
                    label={label}
                    onChange={(newValue: string | number): void =>
                        this.setValue(property, newValue)
                    }
                    options={options}
                    value={value.toString()}
                    {...additionalProps}
                    disabled={
                        disabledFields.indexOf(property) >= 0 ||
                        additionalProps.disabled
                    }
                />
            </div>
        );
    };

    saveChanges = async (e?: { preventDefault(): void }): Promise<unknown> => {
        const { saveChanges } = this.props;

        if (e) {
            e.preventDefault();
        }

        return saveChanges();
    };

    render(): ReactElement {
        const { onCancel, openDelete, record, recordOptions } = this.props;
        const disabledFields = this.props.disabledFields || [];

        const unknownYear = !record.plant_year && !!record.plantAge;

        const selectedCropId = this.getValue('crop');
        const selectedVarietyId = this.getValue('variety');
        if (selectedCropId) {
            for (const crop of recordOptions.crops) {
                if (crop.id === selectedCropId) {
                    recordOptions.selectedCrop = crop;

                    if (
                        selectedVarietyId &&
                        recordOptions.selectedCrop.varieties
                    ) {
                        for (const variety of recordOptions.selectedCrop
                            .varieties) {
                            if (variety.id === selectedVarietyId) {
                                recordOptions.selectedVariety = variety;
                            }
                        }
                    }
                }
            }
        }

        return (
            <Form id="wizardForm" onSubmit={this.saveChanges}>
                <div className="flex flex-wrap bg-white p-2 mb-1 rounded">
                    {this.renderInput('grower_id', 'Grower ID', {
                        placeholder: 'Enter Grower ID',
                    })}

                    {this.renderInput('grower_name', 'Grower', {
                        placeholder: 'Enter Grower Name',
                    })}

                    {this.renderInput('acres', 'Planted Acres', {
                        placeholder: 'Enter Acres',
                        type: 'number',
                        step: '0.01',
                    })}

                    {this.renderInput('block', 'Block ID', {
                        placeholder: 'Enter Block ID',
                    })}

                    {this.renderInput('block_name', 'Block Description', {
                        placeholder: 'Enter Block Description',
                    })}

                    {this.renderSelect(
                        'district',
                        'District',
                        recordOptions.getSelectOptions('district'),
                    )}

                    {this.renderSelect(
                        'crop',
                        'Crop',
                        recordOptions.getSelectOptions('crop'),
                    )}

                    {this.renderSelect(
                        'variety',
                        'Sunkist Marketing Variety',
                        recordOptions.getSelectOptions('variety'),
                    )}

                    {recordOptions.getSelectOptions('subVariety').length > 0 ? (
                        this.renderSelect(
                            'subVariety',
                            'Sub-Variety',
                            recordOptions.getSelectOptions('subVariety'),
                        )
                    ) : (
                        <div className="form-group w-1/3">
                            <label>No Sub-Varieties Available</label>
                        </div>
                    )}

                    {this.renderSelect(
                        'organic',
                        'Organic',
                        recordOptions.getSelectOptions('organic'),
                    )}

                    <div className="form-group w-1/3">
                        <div className="form-check form-check-inline  ml-5 float-right">
                            <label
                                className="form-check-label"
                                htmlFor="unkownPlantYear"
                            >
                                <small>Check here if unknown:</small>
                            </label>{' '}
                            <input
                                className="form-check-input  ml-5"
                                disabled={
                                    disabledFields.indexOf('plant_year') >= 0
                                }
                                type="checkbox"
                                name="unkownPlantYear"
                                id="unkownPlantYear"
                                checked={unknownYear}
                                onChange={(): void =>
                                    this.setState({
                                        unknownYear: !unknownYear,
                                    })
                                }
                            />
                        </div>

                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="plantYear"
                        >
                            Plant Year:
                        </label>
                        <input
                            id="plant_year"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            disabled={
                                unknownYear ||
                                disabledFields.indexOf('plant_year') >= 0
                            }
                            type="number"
                            min="1900"
                            max={new Date().getFullYear()}
                            onChange={(e): void =>
                                this.setValue('plant_year', e.target.value)
                            }
                            value={this.getValue('plant_year')}
                            placeholder="Enter Plant Year (YYYY)"
                        />
                        {record.plant_year &&
                            (parseInt(record.plant_year) < 1900 ||
                                parseInt(record.plant_year) >
                                    new Date().getFullYear()) && (
                                <div
                                    id="plantYearValidation"
                                    className="invalid-feedback"
                                >
                                    Plant Year must be between 1900 to{' '}
                                    {new Date().getFullYear()}
                                </div>
                            )}
                    </div>

                    {this.renderSelect(
                        'plantAge',
                        'Age Range',
                        recordOptions.getSelectOptions('plantAge'),
                    )}

                    {this.renderSelect(
                        'rootstock',
                        'Rootstock',
                        recordOptions.getSelectOptions('rootstock'),
                    )}

                    {this.renderInput('trees_per_acre', 'Trees/Acre', {
                        placeholder: 'Enter Trees/Acre',
                        type: 'number',
                        step: '0.01',
                    })}

                    {this.renderInput('trees', 'Trees', {
                        placeholder: 'Enter Trees',
                        disabled: !!record.trees_per_acre,
                        className: !record.trees ? 'border border-red-300 appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' 
                        : 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    })}
                </div>

                <div className="col-md-12 text-right mt-1.5 w-full">
                    {onCancel && (
                        <Button
                            type="button"
                            onClick={onCancel}
                            data-dismiss="modal"
                            style={{ marginRight: 10 }}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                    )}
                    <Button className="ml-2 float-right" type="submit">
                        Save Changes
                    </Button>

                    {openDelete && (
                        <Button
                            className="ml-2 float-right"
                            onClick={openDelete}
                            type="button"
                            variant="delete"
                        >
                            Delete
                        </Button>
                    )}
                </div>
            </Form>
        );
    }
}

export default RecordForm;

import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import { Api } from '@citrus-tracker/api';
import { PackingHouse, User } from '@citrus-tracker/types';

import { Button } from '@citrus-tracker/ui';

interface PathParams {
    id: string;
}

/* eslint-disable-next-line */
export interface PackinghouseEditProps extends RouteComponentProps<PathParams> {
    packinghouseId?: string;
    user?: User | null;
}

export class PackinghouseEditComponent extends Component<PackinghouseEditProps> {
    props: Readonly<PackinghouseEditProps>;

    state: {
        packinghouseId: string;
        packinghouse: PackingHouse;
        packingHouses: PackingHouse[];
    } = {
        packinghouseId: '',
        packinghouse: {} as PackingHouse,
        packingHouses: [],
    };

    componentDidMount(): void {
        const id = this.props.match.params.id;

        if (id && id !== 'new') {
            this.setState({ packinghouseId: id });
            this.loadPackinghouse(id);
        }
    }

    loadPackinghouse = async (packinghouseId: string): Promise<void> => {
        const packinghouse = await Api.packinghouses.get(packinghouseId);

        this.setState({
            packinghouse: packinghouse,
        });
    };

    setError = (errorAlert: string): void => {
        this.setState({ errorAlert });

        setTimeout(() => {
            this.setState({ errorAlert: '' });
        }, 2000);
    };

    setValue = (property: keyof PackingHouse, value: unknown): void => {
        const packinghouse = {
            ...this.state.packinghouse,
        };

        const newPackinghouse = {
            ...packinghouse,
            [property]: value,
            isAdmin: function (): boolean {
                throw new Error('Function not implemented.');
            },
            isGrower: function (): boolean {
                throw new Error('Function not implemented.');
            },
            isPackingHousePackinghouse: function (): boolean {
                throw new Error('Function not implemented.');
            },
            isPackinghouse: function (): boolean {
                throw new Error('Function not implemented.');
            },
        };

        this.setPackinghouse(newPackinghouse);
    };

    setPackinghouse = (packinghouse: PackingHouse): void => {
        this.setState({
            packinghouse,
        });
    };

    delete = async (): Promise<void> => {
        if (
            this.state.packinghouse.id &&
            window.confirm(
                `Are you sure you want to delete ${this.state.packinghouse.packing_house_name}`,
            ) === true
        ) {
            await Api.packinghouses.delete(this.state.packinghouse.id);
            this.props.history.push(`/packinghouses`);
        }
    };

    saveChanges = async (): Promise<void> => {
        await Api.packinghouses.save(this.state.packinghouse);

        this.props.history.push(`/packinghouses`);
    };

    render(): ReactElement {
        const { packinghouse, packinghouseId } = this.state;

        return (
            <div className="px-8">
                <div className="flex justify-between sm:py-4 items-center flex-col sm:flex-row">
                    <header>
                        <div className="max-w-7xl mx-auto">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">
                                Packing House
                            </h1>
                        </div>
                    </header>
                </div>
                <div>
                    <form
                        className="w-full max-w-xlg rounded bg-white p-8"
                        id="form"
                    >
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="id"
                                >
                                    Id
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="id"
                                    type="text"
                                    value={packinghouse.id}
                                    disabled={true}
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="code"
                                >
                                    Code
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="code"
                                    type="text"
                                    placeholder="Packing House Code"
                                    value={packinghouse.packing_house_code}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'packing_house_code',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="name"
                                >
                                    Name
                                </label>
                                <input
                                    required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="name"
                                    value={packinghouse.packing_house_name}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'packing_house_name',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="exchange"
                                >
                                    Exchange
                                </label>
                                <input
                                    required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="exchange"
                                    value={packinghouse.exchange}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'exchange',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label
                                    className="font-bold text-black"
                                    htmlFor="association"
                                >
                                    Association #
                                </label>
                                <input
                                    required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="association"
                                    value={packinghouse.association}
                                    onChange={(e): void =>
                                        this.setValue(
                                            'association',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </form>

                    <div className="py-2">
                        <Link
                            to={'/packinghouses/'}
                            className="float-left cursor-pointer inline-flex items-center py-1.5 border text-base shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 float-right"
                        >
                            Back
                        </Link>
                        <Button
                            className="float-right"
                            onClick={this.saveChanges}
                            type="button"
                        >
                            Save Changes
                        </Button>
                        {packinghouseId && packinghouseId !== 'new' && (
                            <Button
                                className="mx-2 float-right"
                                onClick={this.delete}
                                type="button"
                                variant="delete"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PackinghouseEditComponent);
